import React from 'react'
import cookie from 'react-cookies'
import ReactDOM from 'react-dom'
import Pagination from '../layout/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class LiquidacionDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getFormattedDate = (createdDate) => {
    // in order to not include time zone
    const d = new Date(createdDate.split('.')[0])
    return d.toLocaleString()
  }

  render() {
    return (
      <div>
        <div className="show-product-header show-header">
          <h2>Detalles de Liquidación:</h2>
        </div>
        <hr className="mt-70" />
        <div className="show-data">
          <div>
            <b>Código de empleado:</b>
          </div>
          <div>{this.props.detailsData['user_uid']}</div>
        </div>
        <div className="show-data">
          <div>
            <b>Nombre de empleado:</b>
          </div>
          <div>{this.props.detailsData['user_name']}</div>
        </div>
        <div className="show-data">
          <div>
            <b>Fecha de creación:</b>
          </div>
          <div>{this.getFormattedDate(this.props.detailsData['closed_at'])}</div>
        </div>
        <hr />
        <div className="display-inline-flex">
          <div className="left-section">
            <div className="show-data mb-20">
              <div>
                <b>Resumen de venta total:</b>
              </div>
            </div>
            <div className="show-data label-1">
              <div>
                <b>EFECTIVO:</b>
              </div>
              <div>
                <b>${parseFloat(this.props.detailsData.total_cash_amount).toFixed(2)}</b>
              </div>
            </div>
            <div className="show-data label-1">
              <div>
                <b>CHEQUE:</b>
              </div>
              <div>
                <b>${parseFloat(this.props.detailsData.total_check_amount).toFixed(2)}</b>
              </div>
            </div>
            <div className="show-data label-1">
              <div>
                <b>CREDITO:</b>
              </div>
              <div>
                <b>${parseFloat(this.props.detailsData.total_credit_amount).toFixed(2)}</b>
              </div>
            </div>
            <div className="show-data label-3">
              <div>
                <b>TOTAL DE VENTA:</b>
              </div>
              <div>
                <b>${parseFloat(this.props.detailsData.total_invoiced).toFixed(2)}</b>
              </div>
            </div>
          </div>
          <div style={{ borderLeft: '1px solid #eee', height: '500px' }}></div>
          <div className="right-section">
            <div className="show-data mb-20">
              <div>
                <b>Informe de caja:</b>
              </div>
            </div>
            <div className="show-data label-1">
              <div>
                <b>EFECTIVO FACTURADO:</b>
              </div>
              <div>
                <b>${parseFloat(this.props.detailsData.total_cash_amount).toFixed(2)}</b>
              </div>
            </div>
            <div className="show-data label-1">
              <div>
                <b>EFECTIVO ENTREGADO:</b>
              </div>
              <div>
                <b>${parseFloat(this.props.detailsData.cash_at_closure).toFixed(2)}</b>
              </div>
            </div>
            <div className="show-data label-2">
              <div>
                <b>DIFERENCIA:</b>
              </div>
              <div>
                <b>${parseFloat(this.props.detailsData.cash_difference).toFixed(2)}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

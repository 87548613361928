import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import ShowUserReportMapModalContent from '../../charges/map/ShowUserReportMapModalContent'
import { formatDateNoHours } from '../../../helpers/utils'

const GeoCardMapModal = ({
  show,
  handleClose,
  username,
  startDate,
  lastActivityTime,
  processedData
}) => (
  <Modal
    show={show}
    dialogClassName="modal-90w close-button-modal-maps"
    onHide={handleClose}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header closeButton>
      <Modal.Title id="example-modal-sizes-title-lg">
        <p>
          <b>Mapa de Geocercas</b>
        </p>
        <p className="text-title-modal-map">Usuario: {username}</p>

        <div className="title-and-search-containter">
          <p className="text-title-modal-map">Fecha: {formatDateNoHours(startDate)}</p>

          <div className="search-input-containter">
            <p className="text-title-modal-map legend-item-firts-title"><b>Leyenda:</b></p>
          </div>
        </div>
        <div className="title-and-search-containter">
          <p className="text-title-modal-map">Hora de última actividad: {lastActivityTime}</p>

          <div className="search-input-containter ">
            <div className="legend-container">
              <div className="legend-item-firts">
                <div className="legend-color-cube" style={{ backgroundColor: '#50739A' }}></div>
                <div className="text-legend-modal-map">Dentro de Geocerca</div>
              </div>
              <div className="legend-item">
                <div className="legend-color-cube" style={{ backgroundColor: '#D5422D' }}></div>
                <div className="text-legend-modal-map"> Fuera de Geocerca</div>
              </div>
              <div className="legend-item">
                <div className="legend-color-cube" style={{ backgroundColor: '#202020' }}></div>
                <div className="text-legend-modal-map">Cliente sin ubicación registrada</div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div id="map-canvas">
        <ShowUserReportMapModalContent data={processedData} />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <p className="text-footer-modal-map">
        <b>Nota:</b> Los puntos numerados en el mapa representan las ubicaciones registradas durante
        el día actual.
      </p>
      <div className="title-and-search-containter">
        <p className="text-footer-modal-map">
          Los saltos en la numeración indican períodos en los que el GPS del dispositivo se
          encontraba desactivado.
        </p>
        <div className="search-input-containter">
          <Button className="buttom-center-note" variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </div>
      </div>
    </Modal.Footer>
  </Modal>
)

export default GeoCardMapModal

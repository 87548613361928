import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listOpen: false,
      headerTitle: this.props.headerTitle,
      iconType: this.props.icon
    }
    this.close = this.close.bind(this)
  }

  componentDidUpdate() {
    const { listOpen } = this.state
    setTimeout(() => {
      if (listOpen) {
        window.addEventListener('click', this.close)
      } else {
        window.removeEventListener('click', this.close)
      }
    }, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close)
  }

  close(timeOut) {
    this.setState({
      listOpen: false
    })
  }

  selectItem(event, title, id, stateKey) {
    this.setState(
      {
        headerTitle: title,
        listOpen: false
      },
      this.props.resetThenSet(event, id, stateKey)
    )
  }

  toggleList(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    this.setState((prevState) => ({
      listOpen: !prevState.listOpen
    }))
  }

  render() {
    const { list } = this.props
    const { listOpen, headerTitle, iconType } = this.state
    return (
      <div>
        <a
          className={`${
            this.props.activeCursor === headerTitle
              ? 'active-nav-bar-item cursor-pointer'
              : 'cursor-pointer'
          }`}
          onClick={(e) => this.toggleList(e)}
        >
          <FontAwesomeIcon icon={iconType} />
          {this.props.headerTitle}
          {listOpen ? (
            <FontAwesomeIcon className="vertical-dropdown-arrow" icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon className="vertical-dropdown-arrow" icon={faCaretDown} />
          )}
        </a>

        <div className="dropdown-container">
          {listOpen && (
            <span onClick={(e) => e.stopPropagation()}>
              {list.map((item) => (
                <Link
                  to={'/' + item.key}
                  className="sub-nav-bar-item cursor-pointer"
                  key={item.id}
                  onClick={(e) => {
                    this.selectItem(e, item.title, item.id, item.key)
                  }}
                >
                  {item.title} {item.selected && <FontAwesomeIcon icon="check" />}
                </Link>
              ))}
            </span>
          )}
        </div>
      </div>
    )
  }
}

export default Dropdown

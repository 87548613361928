import React, { createContext, useState } from 'react'

import { createUserInfo } from '../services/apiProductService'
import { useHistory } from 'react-router-dom'
import cookie from 'react-cookies'
import history from '../../helpers/history'

const initialState = {}

const SubmitCreateProductContext = createContext(initialState)

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const SubmitCreateProductProvider = (props) => {
  const [failedCreationInfo, setFailedCreationInfo] = useState(null)
  const [showWarningPage2, setShowWarningPage2] = useState(false)

  const history = useHistory()

  const validatePriceListsInputs = (priceListSate) => {
    const incomplete = priceListSate.filter((obj) => obj.price_list_id === '' || obj.price === '')
    return incomplete.length <= 0
  }

  const handleCreateProduct = (product, images, priceListSate) => {
    setShowWarningPage2(false)
    const canCreate = validatePriceListsInputs(priceListSate)
    if (canCreate === true) {
      fetch(BACKEND_URL + '/products', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: 1,
          active: product.active,
          price_unit_id: product.unitWeight,
          db_ref: product.code,
          barcode: product.barcode,
          name: product.name,
          description: product.description,
          tax_id: product.itbms,
          brand_id: product.brand,
          package_id: product.unit,
          quantity_per_package: product.unitQuantity,
          max_weight: product.weight,
          min_weight: product.weight,
          average_weight: product.weight,
          has_package_per_unit: product.splitUnit,
          category_ids: product.category ? [product.category] : [],
          subcategory_ids: product.subCategory ? [product.subCategory] : [],
          price_lists: priceListSate
        })
      })
        .then((r) =>
          r.json().then((data) => {
            if (images.length > 0) {
              handleSubmitImage(data, images)
            }
            return { status: r.status, body: data }
          })
        )
        .then((obj) => evaluateResponse(obj))
        .catch((e) => console.log(e))
    } else {
      setShowWarningPage2(true)
    }
  }

  const handleSubmitImage = (data, images) => {
    fetch(BACKEND_URL + '/product_photos', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        business_id: 1,
        product_id: data.id,
        photo_name: images[0].file.name,
        url: images[0].data_url
      })
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .catch((e) => console.log(e))
  }

  const hideFailedCreationMessage = () => {
    setFailedCreationInfo(null)
  }

  const evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        setFailedCreationInfo(
          'Error al guardar cambios. El producto con el código ingresado ya existe.'
        )
        setTimeout(hideFailedCreationMessage, 5000)
        break
      case 409:
        setFailedCreationInfo(
          'Error al guardar cambios. El codigo ingresado ya existe. Por favor escoger otro codigo.'
        )
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 404:
        setFailedCreationInfo('Servidor fuera de servicio')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 500:
        setFailedCreationInfo('Error interno en el sistema')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      default:
        // eslint-disable-next-line no-case-declarations
        const data = obj.body
        history.push({
          pathname: '/productos/' + data['id'] + '/detalles',
          params: { successCreateForNewProduct: true }
        })
        break
    }
  }

  return (
    <SubmitCreateProductContext.Provider
      value={{
        handleCreateProduct,
        failedCreationInfo,
        showWarningPage2
      }}
    >
      {props.children}
    </SubmitCreateProductContext.Provider>
  )
}
export { SubmitCreateProductContext, SubmitCreateProductProvider }

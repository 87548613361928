import React from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import cookie from 'react-cookies'
import OrderDetailsTab from './tabs/OrderDetailsTab'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ShowSimpleOrderApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchPlaceholder: 'Buscar pedidos por código o nombre de cliente',
      id: window.location.href.split('/')[window.location.href.split('/').length - 1],
      isFetching: true,
      query: '',
      details: null,
      defaultOrderUuid: '',
      defaultOrderDetails: []
    }
  }

  componentDidMount() {
    this.fetchDefaultOrderDetails()
  }

  fetchDefaultOrderDetails = () => {
    fetch(BACKEND_URL + '/orders/' + this.state.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => this.setState({ defaultOrderDetails: data, isFetching: false }))
      .catch((e) => console.log(e))
  }

  render() {
    return (
      <div>
        {this.state.isFetching === false ? (
          <div>
            <div>
              <div>
                <div onKeyDown={(e) => this.handleSearch(e, false)}>
                  <TopBar
                    searchPlaceholder={this.state.searchPlaceholder}
                    userName={this.state.userName}
                  />
                </div>
                <div>
                  <div className="main-view">
                    <div className="main-show-header">
                      <h2>Pedido #{this.state.defaultOrderDetails.order_db_ref}</h2>
                    </div>
                    <div className="index-table">
                      <div className="show-area">
                        <OrderDetailsTab
                          orderDetails={this.state.defaultOrderDetails}
                          transactionUuid={null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <NavBar activeItem="Pedidos" isEditing={false} />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
            />
            <div className="main-view">
              <div className="show-title">
                <p>Obteniendo datos ....</p>
              </div>
            </div>
            <div>
              <NavBar activeItem="Pedidos" isEditing={false} />
            </div>
          </div>
        )}
      </div>
    )
  }
}

import React, { createContext, useEffect, useReducer, useState } from 'react'
import cookie from 'react-cookies'
import * as apiStatusCodes from '../../constants/apiStatusCodes'
import { useHistory } from 'react-router-dom'
import {
  getBusiness,
  getQueryState,
  handleErrors,
  updateSalesmanConfig
} from '../services/apiUserService'
import {
  UserConfigInitialStateForm,
  UserInfoInitialStateForm
} from '../helpers/formControlsVariables'
import { handleTabClick, handleUpdate } from '../helpers/UserConfigHandlers'
import validate from '../../helpers/validation'

const initialState = {}

const UserConfigContext = createContext(initialState)

const UserConfigProvider = (props) => {
  const [isFetching, setFetching] = useState(true)
  const [isEditingUserConfigTab, setEditing] = useState(false)
  const [businessData, setBusinessData] = useState({})
  const [title, setTitle] = useState('')
  const [failedCreationState, setFailedCreationState] = useState(false)
  const [failedCreationInfo, setFailedCreationInfo] = useState(null)
  const [activeTab, setActiveTab] = useState('Detalles')
  const [formIsValid, setFormIsValid] = useState(true)
  const [formControls, setFormControls] = useState(UserConfigInitialStateForm)

  const history = useHistory()

  useEffect(() => {
    getBusinessConfig()
  }, [])

  const getBusinessConfig = () => {
    setFetching(true)
    const queryParams = getQueryState()
    getBusiness()
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        setFetching(false)
        setBusinessData(data)
        setTitle(queryParams ? `Resultados de la búsqueda: ${queryParams}` : `Usuarios`)
      })
      .catch((error) => {
        evaluateResponse(error)
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  const formSubmitUpdate = (event, salesman) => {
    event.preventDefault()
    if (formIsValid) {
      updateSalesmanConfig(salesman.id, formControls)
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => evaluateResponse(obj, false))
        .catch((e) => console.log(e))
    } else {
      setFailedCreationInfo('Por favor introduzca los campos obligatorios')
      setTimeout(hideFailedCreationMessage, 9000)
    }
  }

  const changeHandler = (event) => {
    const name = event.target.name
    const value = event.target.value

    const updatedControls = {
      ...formControls
    }
    const updatedFormElement = {
      ...updatedControls[name]
    }

    updatedFormElement.value = value
    updatedFormElement.touched = true
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules)

    updatedControls[name] = updatedFormElement

    let formIsValid = true
    for (const inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid
    }

    setFormControls(updatedControls)
    setFormIsValid(formIsValid)
  }

  const handleUpdateConfigFlags = (event, userInfo) => {
    setFormControls({
      ...formControls,
      can_access_refunds_app: {
        ...formControls.can_access_refunds_app,
        value: userInfo.can_access_refunds_app
      },
      can_change_prices: {
        ...formControls.can_change_prices,
        value: userInfo.can_change_prices
      },
      can_set_prices_on_quote_items: {
        ...formControls.can_set_prices_on_quote_items,
        value: userInfo.can_set_prices_on_quote_items
      },
      has_fixed_route: {
        ...formControls.has_fixed_route,
        value: userInfo.has_fixed_route
      },
      can_consolidate_orders: {
        ...formControls.can_consolidate_orders,
        value: userInfo.can_consolidate_orders
      },
      can_see_customer_segment: {
        ...formControls.can_see_customer_segment,
        value: userInfo.can_see_customer_segment
      },
      enable_fiscal_printer: {
        ...formControls.enable_fiscal_printer,
        value: userInfo.enable_fiscal_printer
      },
      can_select_discount: {
        ...formControls.can_select_discount,
        value: userInfo.can_select_discount
      },
      route: {
        ...formControls.route,
        value: userInfo.route
      },
      active: {
        ...formControls.active,
        value: userInfo.active
      },
      mobile: {
        ...formControls.mobile,
        value: userInfo.mobile ? userInfo.mobile : ''
      },
      email: {
        ...formControls.email,
        value: userInfo.salesman_email ? userInfo.salesman_email : ''
      },
      code: {
        ...formControls.code,
        value: userInfo.db_ref
      },
      name: {
        ...formControls.name,
        value: userInfo.name
      }
    })
    const value = handleUpdate(event)
    setEditing(value)
  }

  const handleCancelConfigFlags = (event) => {
    const value = handleUpdate(event)
    setEditing(value)
  }

  const handleTabClickConfig = (event) => {
    const value = handleTabClick(event, isEditingUserConfigTab)
    setActiveTab(value.activeTab)
    setEditing(value.editing)
  }

  const hideFailedCreationMessage = () => {
    setFailedCreationInfo(null)
  }

  const evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        setFailedCreationInfo('Verifique la informacion ingresada.')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 409:
        setFailedCreationInfo(
          'Error al guardar cambios. El codigo ingresado ya existe. Por favor escoger otro codigo.'
        )
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 404:
        setFailedCreationInfo('Servidor fuera de servicio')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 500:
        setFailedCreationInfo('Error interno en el sistema')

        setTimeout(hideFailedCreationMessage, 9000)
        break
      default:
        // eslint-disable-next-line no-case-declarations
        const data = obj.body
        setEditing(false)
        history.go('/usuarios/' + data['id'])
        break
    }
  }

  return (
    <UserConfigContext.Provider
      value={{
        isFetching,
        businessData,
        title,
        isEditingUserConfigTab,
        activeTab,
        formControl: formControls,
        failedCreationInfo,
        handleUpdateConfigFlags,
        handleTabClickConfig,
        changeHandler,
        formSubmitUpdate,
        handleCancelConfigFlags
      }}
    >
      {props.children}
    </UserConfigContext.Provider>
  )
}
export { UserConfigContext, UserConfigProvider }

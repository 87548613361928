import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import AttributeView from './attributes/AttributeView'
import SegmentContext from './context/SegmentContext'
import DetailsView from './details/DetailsView'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function SegmentIndexView(props) {
  const tabs = ['Atributos', 'Detalles']
  const getSelectedTab = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let selected_tab = searchParams.get('selected_tab')
    if (selected_tab) {
      return selected_tab
    }
    return tabs[0] // Atributos
  }

  const initPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let page = searchParams.get('page')
    let currentPage = 1
    if (page) {
      currentPage = Number(page)
    } else {
      searchParams.set('page', '1')
      let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      window.history.pushState(null, '', newRelativePathQuery)
    }
    return {
      per_page: 50,
      current_page: currentPage,
      total_pages: 1,
      query: ''
    }
  }

  const [selectedTab, setSelectedTab] = useState(getSelectedTab())
  const [qualifiersData, setQualifiersData] = useState(null)
  const [clientsData, setClientsData] = useState(null)
  const [promoQualifiersData, setPromoQualifiersData] = useState(null)
  const [fetchDataStatus, setFetchDataStatus] = useState({})
  const [qualifierQuery, setQualifierQuery] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [pagination, setPagination] = useState(initPaginationData())
  const [collapsed, setCollapsed] = useState(props.collapsed);

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort();
  };
  window.addEventListener("beforeunload", abortCurrentFetchs);
  //////

  useEffect(() => {
    initPaginationData()
    async function fetchAll() {
      await fetchQualifiersFromServer()
      await fetchPromoQualifiers()
      // await fetchClientsFromServer()
    }
    fetchAll()
  }, [])

  useEffect(() => {
    if (selectedTab == 'Detalles') {
      updateURLPaginationData()
      async function fetchAll() {
        await fetchClientsFromServer()
      }
      fetchAll()
    }
  }, [pagination.current_page, qualifierQuery, searchQuery])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('selected_tab', selectedTab)
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    window.history.pushState(null, '', newRelativePathQuery)
  }, [selectedTab])

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  const getListTabStyles = (tab) => {
    return selectedTab == tab ? 'active' : ''
  }

  const getAnchorTabStyles = (tab) => {
    return selectedTab == tab ? 'cursor-pointer' : 'inactive-tab cursor-pointer'
  }

  const fetchQualifiersFromServer = async (loadingState = true) => {
    if (loadingState) {
      setFetchDataStatus({ ...fetchDataStatus, qualifiers: true })
    }
    const response = await fetch(
      BACKEND_URL + '/qualifiers' + '?business_tenant_uid=' + cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = await response.json()
    setQualifiersData(data)
    setFetchDataStatus({ ...fetchDataStatus, qualifiers: false })
  }

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    let targetId = target.id
    switch (targetId) {
      case 'first-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: 1 })
        }
        break
      case 'last-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.total_pages })
        }
        break
      case 'previous-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: pagination.current_page - 1 })
        }
        break
      case 'next-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.current_page + 1 })
        }
        break
    }
  }

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', pagination.current_page)
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const fetchClientsFromServer = async () => {
    setFetchDataStatus({ ...fetchDataStatus, clients: true })
    const per_page = pagination.per_page
    const page = pagination.current_page
    const response = await fetch(
      BACKEND_URL +
        '/businesses/1/clients' +
        '?page=' +
        page +
        '&per_page=' +
        per_page +
        (qualifierQuery ? qualifierQuery : '') +
        (searchQuery ? searchQuery : ''),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = await response.json()

    setPagination({ ...pagination, total_pages: Math.ceil(data['count'] / pagination.per_page) })
    setClientsData(data['table'])
    setFetchDataStatus({ ...fetchDataStatus, clients: false })
  }

  const fetchPromoQualifiers = async () => {
    setFetchDataStatus({ ...fetchDataStatus, promoQualifiers: true })
    const response = await fetch(BACKEND_URL + '/promo_qualifiers', {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
    if (response.status === 401) {
      return console.error(response)
    }

    const data = await response.json()
    setPromoQualifiersData(data.result)
    setFetchDataStatus({ ...fetchDataStatus, promoQualifiers: false })
  }

  return (
    <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
    <div className="index-header">
        <h2 className="mb-20 float-unset">Segmentos</h2>
      </div>
      <div className="index-table">
        <div className="show-area">
          <div id="tabs">
            <div className="mb-1">
              <ul className="nav nav-tabs">
                {tabs.map((value, index) => (
                  <li
                    key={index}
                    onClick={() => setSelectedTab(value)}
                    id="details_tab"
                    className={getListTabStyles(value)}
                  >
                    <a className={getAnchorTabStyles(value)}>{value}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <SegmentContext.Provider
            value={{
              qualifiersData,
              fetchDataStatus,
              clientsData,
              promoQualifiersData,
              pagination,
              setQualifierQuery,
              setSearchQuery,
              searchQuery,
              qualifierQuery,
              fetchQualifiersFromServer,
              fetchClientsFromServer,
              handlePaginationClick
            }}
          >
            <div>{selectedTab == 'Atributos' && <AttributeView setIsUpdating={props.setIsUpdating} />}</div>
            <div>{selectedTab == 'Detalles' && <DetailsView />}</div>
          </SegmentContext.Provider>
        </div>
      </div>
    </div>
  )
}

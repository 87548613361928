const STATUS_DIC = {
  active: 'Activa',
  unstarted: 'No iniciada',
  expired: 'Caducada',
  suspended: 'Suspendida'
}

const TASK_TYPE_DIC = {
  images: 'Imágenes',
  inventory: 'Captura de Inventario',
  survey: 'Encuesta',
  data_update: 'Actualización de datos',
  planimetry: 'Verificación de Planimetría',
  price_capture: 'Captura de Precios Propios y/o Competencia'
}

const TASK_TYPES = Object.freeze({
  inventory: {
    key: 'inventory',
    name: 'Captura de inventario',
    shortName: 'CAPTURA DE INVENTARIO',
    description: 'Captura de Inventario',
    downloadName: 'CapturaDeInventario'
  },
  planimetry: {
    key: 'planimetry',
    name: 'Verificación de planimetría',
    shortName: 'PLANIMETRÍA',
    description: 'Verificación de Planimetría',
    downloadName: 'VerificacionDePlanimetria'
  },
  survey: {
    key: 'survey',
    name: 'Encuesta',
    shortName: 'ENCUESTA',
    description: 'Encuesta',
    downloadName: 'Encuesta'
  },
  price_capture: {
    key: 'price_capture',
    name: 'Captura de precios propios y/o competencia',
    shortName: 'CAPTURA DE PRECIOS',
    description: 'Captura de Precios Propios y/o Competencia',
    downloadName: 'CapturaDePreciosPropios_Y_O_Competencia'
  }
})

const TASK_TYPES_KEYS = Object.keys(TASK_TYPES)

const PROD_TYPE_DIC = {
  brand: 'Marca',
  individual_products: 'Producto individual',
  category: 'Categoría'
}

export { STATUS_DIC, TASK_TYPE_DIC, TASK_TYPES, TASK_TYPES_KEYS, PROD_TYPE_DIC }


//Este es un helper que permite redirigir al login si el token esta desactivado.

import cookie from 'react-cookies';

const fetchWithAuth = async (url, options = {}) => {
  const token = cookie.load('token');

  const headers = {
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers,
  };

  try {
    const response = await fetch(url, { ...options, headers });

    if (response.status === 401) {
      console.error('Token revocado o no autorizado');
      cookie.remove('token'); 
      window.location.href = '/logout?deactivated=true'; 
      return;
    }

    if (!response.ok) {
      console.error('HTTP error', response);
      return;
    }

    return await response.json(); 
  } catch (error) {
    console.error('Fetch error:', error);
    throw error; 
  }
};

export default fetchWithAuth;

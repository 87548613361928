import React from 'react'
import cookie from 'react-cookies'
import ReactDOM from 'react-dom'
import Pagination from '../layout/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class LiquidacionInvoicesTab extends React.Component {
  constructor(props) {
    super(props)
    this.searchInput = React.createRef()
    this.state = {
      data: [],
      isFetching: true
    }
  }

  componentDidMount() {
    this.fetchDetailsFromServer()
  }

  fetchDetailsFromServer = () => {
    this.setState({ isFetching: true })
    fetch(BACKEND_URL + '/closure_summary_invoices/' + this.props.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => this.setState({ data: data, isFetching: false }))
      .catch((e) => console.log(e))
  }

  getFormattedDate = (createdDate) => {
    // in order to not include time zone
    const d = new Date(createdDate.split('.')[0])
    return d.toLocaleString()
  }

  render() {
    return (
      <div>
        <div className="show-product-header show-header">
          <h2 className="mt-8">Detalles de facturas asociados a esta liquidación:</h2>
        </div>
        <hr className="mt-70" />
        <div className="index-table">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>NUMERO DE FACTURA</th>
                <th>FECHA</th>
                <th>METODO DE PAGO</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {this.state.isFetching === true && (
                <tr>
                  <td colSpan="4">
                    <b>Obteniendo datos...</b>
                  </td>
                </tr>
              )}
              {this.state.isFetching === false &&
                this.state.data.length > 0 &&
                this.state.data.map((model, index) => (
                  <tr className="cursor-pointer" key={index}>
                    <td id={model['id']}>{model['db_ref']}</td>
                    <td id={model['id']}>{this.getFormattedDate(model['created_date'])}</td>
                    <td id={model['id']}>{model['payment_types']}</td>
                    <td id={model['id']}>${model['total']}</td>
                  </tr>
                ))}
              {this.state.isFetching === false && this.state.data.length === 0 && (
                <tr>
                  <td colSpan="4">No hubo resultados</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

import React from 'react'
import BaseCard from './BaseCard'

const SimpleCard = ({ title, subtitle, selectedTab, displayTab }) => {
  const replaceWithBr = () => {
    return title.replace(/\n/g, '<br />')
  }

  if (displayTab.includes(selectedTab)) {
    return (
      <BaseCard addClass="base-card-container-overview">
        <p className="p-card-title" dangerouslySetInnerHTML={{ __html: replaceWithBr() }} />
        <div className="mt-20">
          <p className="p-card-number">{subtitle}</p>
        </div>
      </BaseCard>
    )
  }
  return null
}

export default SimpleCard

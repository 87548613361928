import React, { useState, useContext, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import PosDownloadView from './PosDownloadView'
import PosDownloadContext from './context/PosDownloadContext'
import fetchWithAuth from '../helpers/fetchWithAuth'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const PosDownloadApp = () => {
  const initPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let page = searchParams.get('page')
    let currentPage = 1
    if (page) {
      currentPage = Number(page)
    } else {
      searchParams.set('page', '1')
      let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      window.history.pushState(null, '', newRelativePathQuery)
    }
    return {
      per_page: 25,
      current_page: currentPage,
      total_pages: 1,
      query: ''
    }
  }

  const [collapsed, setCollapsed] = useState(false)
  const [clientsData, setClientsData] = useState([])
  const [usersData, setUsersData] = useState([])
  const [surveysData, setSurveysData] = useState([])
  const [productTypeDic, setProductTypeDic] = useState([
    { key: 'Marcas', value: [] },
    { key: 'Categoría', value: [] },
    { key: 'Producto individual', value: [] }
  ])
  const [filterData, setFilterData] = useState([])
  const [reportsData, setReportsData] = useState([])
  const [fetchDataStatus, setFetchDataStatus] = useState({})
  const [taskType, setTaskType] = useState(null)
  const [pagination, setPagination] = useState(initPaginationData())
  const [title, setTitle] = useState('Descargar análisis en punto de venta')
  const [isFetching, setIsFetching] = useState(true)
  const [failedCreation, setFailedCreation] = useState(false)
  const [failedCreationMessage, setFailedCreationMessage] = useState('')

  const filtersDic = {
    Cliente: 'clients',
    Usuario: 'users',
    'Producto individual': 'products',
    Marcas: 'brands',
    Categoría: 'categories'
  }

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  useEffect(() => {
    initPaginationData()
    async function fetchAll() {
      await fetchFilterInfoFromServer()
    }
    fetchAll()
  }, [])

  useEffect(() => {
    updateURLPaginationData()
    async function fetchAll() {
      await fetchReportsFromServer()
    }
    fetchAll()
  }, [pagination.current_page])

  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchAll() {
        await fetchReportsFromServer()
      }
      fetchAll()
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    let targetId = target.id
    switch (targetId) {
      case 'first-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: 1 })
        }
        break
      case 'last-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.total_pages })
        }
        break
      case 'previous-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: pagination.current_page - 1 })
        }
        break
      case 'next-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.current_page + 1 })
        }
        break
    }
  }

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', pagination.current_page)
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const handleDeleteReport = (model, event) => {
    event.preventDefault()
    fetch(`${BACKEND_URL}/pos_downloads/${model['id']}`, {
      method: 'DELETE',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((r) => evaluateDeleteResponse(r))
      .catch((e) => console.log(e))
  }

  const evaluateDeleteResponse = (response) => {
    if (response.ok) {
      fetchReportsFromServer()
    } else {
      console.log('error deleting report: ', response)
    }
  }

  const fetchFilterInfoFromServer = async (loadingState = true) => {
    if (loadingState) {
      setFetchDataStatus({ ...fetchDataStatus, filters: true })
    }
    try {
      const response = await fetchWithAuth(
        `${BACKEND_URL}/pos_downloads/?download_filter_info=true&businesses=${cookie.load(
          'business_id'
        )}`,
        {
          method: 'GET',
          signal: abortController.signal
        }
      )
      if (response) {
        const data = response
        setClientsData(data['clients'])
        setUsersData(data['users'])
        setProductTypeDic([
          { key: 'Marcas', value: data['brands'].map((b) => b.db_ref + ' - ' + b.name) },
          {
            key: 'Categoría',
            value: data['categories'].map((c) => c.db_ref + ' - ' + c.description)
          },
          {
            key: 'Producto individual',
            value: data['products'].map((p) => p.db_ref + ' - ' + p.name)
          }
        ])
        setFilterData(data)
      }
    } catch (error) {
      console.error('Error fetching filter info: ', error)
    } finally {
      setFetchDataStatus({ ...fetchDataStatus, filters: false })
    }
  }

  const gatherParams = (key, value) => {
    let queryparams = `&${filtersDic[key]}=`
    let queryArray = []
    value.forEach((v) => {
      queryArray.push(filterData[filtersDic[key]].find((f) => v.includes(f.uid)).uid)
    })
    return (queryparams += queryArray.join(','))
  }

  const fetchSurveysFromServer = async (allSelectedOptions, dateQuery) => {
    setFetchDataStatus({ ...fetchDataStatus, filters: true })
    let queryParam = ''

    if (allSelectedOptions) {
      for (const [key, value] of allSelectedOptions) {
        if (value.length != 0) {
          queryParam += gatherParams(key, value)
        }
      }
    }

    const response = await fetch(
      `${BACKEND_URL}/pos_downloads/?survey_filter_info=true&businesses=${cookie.load(
        'business_id'
      )}${dateQuery}${queryParam}`,
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return (window.location = '/')
    }
    const data = await response.json()
    if (data.length == 0) {
      setFailedCreation(true)
      setFailedCreationMessage(
        'Error: No es posible generar el reporte. No se encontraron resultados de encuestas con los filtros seleccionados. Por favor, ajusta los filtros y vuelve a intentarlo para poder generar el reporte.'
      )
      return setTimeout(() => setFailedCreation(false), 15000)
    }
    setFailedCreation(false)
    setSurveysData(data)
    setFetchDataStatus({ ...fetchDataStatus, filters: false })
  }

  const fetchReportsFromServer = async () => {
    setIsFetching(true)
    const per_page = pagination.per_page
    const page = pagination.current_page
    try {
      const response = await fetchWithAuth(
        `${BACKEND_URL}/pos_downloads?page=${page}&per_page=${per_page}&businesses=${cookie.load(
          'business_id'
        )}`,
        {
          method: 'GET',
          signal: abortController.signal
        }
      )
      if (response) {
        setPagination({
          ...pagination,
          total_pages: Math.ceil(response.count / pagination.per_page)
        })
        setReportsData(response.result)
      }
    } catch (error) {
      console.error('Error fetching reports:', error)
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <div>
      <div>
        <div>
          <TopBar onToggleCollapse={handleToggleCollapse} collapsed={collapsed} />
        </div>
        <div>
          <PosDownloadContext.Provider
            value={{
              fetchDataStatus,
              clientsData,
              usersData,
              surveysData,
              productTypeDic,
              filterData,
              reportsData,
              pagination,
              fetchReportsFromServer,
              handlePaginationClick,
              handleDeleteReport,
              fetchSurveysFromServer,
              taskType,
              setTaskType,
              title,
              isFetching,
              failedCreation,
              setFailedCreation,
              failedCreationMessage,
              setFailedCreationMessage,
              filtersDic
            }}
          >
            <PosDownloadView collapsed={collapsed} />
          </PosDownloadContext.Provider>
        </div>
      </div>
      <div>
        <NavBar activeItem="Ejecución punto de venta" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}
export default PosDownloadApp

import React, { useState, useContext } from 'react';
import { UserConfigCreateContext } from '../context/UserConfigCreateProvider'
import Select from 'react-select'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//Formulario de creacion de Usuario, parte 1 detalles

const CreateUserInfoDetailStep = () => {
  const { errors, register, Controller, control, featureRoles } =
    useContext(UserConfigCreateContext)
    const [showPassword, setShowPassword] = useState(false); 
    const [showPasswordRep, setShowPasswordRep] = useState(false); 

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const toggleShowPasswordRep = () => {
      setShowPasswordRep(!showPasswordRep);
    };
  
  
  return (
    <>
      <hr />
      <div className="inline-block full-width mb-40">
        <div className="float-left">
          <span>
            <b>Paso 1 de 2: </b>
          </span>
          Por favor, complete los campos obligatorios marcados con{' '}
          <span style={{ color: 'red' }}>*</span> para proceder
        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Activo:</label>
        <div className="col-sm-8">
          <select
            name="active"
            {...register('active')}
            className={`form-control ${errors.active ? 'is-invalid' : ''}`}
          >
            <option value="true">Si</option>
            <option value="false">No</option>
          </select>
          <div className="invalid-feedback">{errors.active?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputName" className="col-sm-3 col-form-label">
          Nombre de usuario<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="name"
            type="text"
            {...register('name')}
            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
            placeholder="Nombre de usuario"
          />
          <div className="invalid-feedback">{errors.name?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Rol de usuario<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <Controller
            control={control}
            defaultValue={[]}
            name="role"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                placeholder={'Buscar o asignar multiples roles'}
                noOptionsMessage={() => 'Buscar'}
                loadingMessage={() => 'Cargando...'}
                isDisabled={false}
                isClearable={true}
                inputRef={ref}
                value={featureRoles?.filter((c) => value.includes(c.value))}
                onChange={(val) => onChange(val.map((c) => c.value))}
                options={featureRoles}
                isMulti
              />
            )}
          />
          <div className="invalid-feedback">{errors.role?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Número de rutero/Código<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="code"
            type="text"
            {...register('code')}
            className={`form-control ${errors.code ? 'is-invalid' : ''}`}
            placeholder="123"
          />
          <div className="invalid-feedback">{errors.code?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Correo electrónico<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="email"
            type="email"
            {...register('email')}
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            placeholder="ejemplo@email.com"
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Contraseña<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="password"
            type={showPassword ? 'text' : 'password'}
            {...register('password')}
            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
            placeholder="Asegúrese de que la contraseña tenga al menos 6 caracteres"
          />
            <span onClick={toggleShowPassword} className="icon-eye">
            <FontAwesomeIcon className="icon-eye" icon={showPassword ? faEyeSlash : faEye} />
          </span>
          <div className="invalid-feedback">{errors.password?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Reescribir Contraseña<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="confirmPassword"
            type={showPasswordRep ? 'text' : 'password'}
            {...register('confirmPassword')}
            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
            placeholder="Repite la contraseña"
          />
                      <span onClick={toggleShowPasswordRep} className="icon-eye">
            <FontAwesomeIcon className="icon-eye" icon={showPasswordRep ? faEyeSlash : faEye} />
          </span>
          <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Código de bodega<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="warehouse"
            type="text"
            {...register('warehouse')}
            className={`form-control ${errors.warehouse ? 'is-invalid' : ''}`}
            placeholder="123"
          />
          <div className="invalid-feedback">{errors.warehouse?.message}</div>
        </div>
      </div>

      <div className="form-group row ">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Número de teléfono:
        </label>
        <div className="col-sm-8">
          <input
            name="mobile"
            type="text"
            {...register('mobile')}
            className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
            placeholder="0000-0000"
          />
          <div className="invalid-feedback">{errors.mobile?.message}</div>
        </div>
      </div>
    </>
  )
}

export default CreateUserInfoDetailStep

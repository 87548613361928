import React from 'react'
import ReactDOM from 'react-dom'
import Pagination from '../layout/Pagination'
import Modal from 'react-awesome-modal'
import CreateProductModal from './CreateProductModal'
import cookie from 'react-cookies'
import { validateAccessRole } from '../helpers/userRole'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faDownload, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilterList'
export default class ProductIndexView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false
    }
  }

  showCreateProductPage() {
    window.location = '/crear_producto'
  }

  handleChange = (event) => {
    const { value } = event.target
    this.props.onSearch(value)
  }

  handleInputChange = (event) => {
    const newSearchInput = event.target.value
    this.props.onSearchInput(newSearchInput)
  }

  closeModal() {
    this.setState({
      isModalVisible: false
    })
  }

  showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open('/productos/' + id + '/detalles')
  }

  handlePackageUnit = (packages, unitNumber) => {
    const orderedPackages = _.sortBy(packages, ['quantity']).reverse()

    if (!packages) {
      return '-'
    }
    if (unitNumber == 1) {
      return orderedPackages[0]?.unit || '-'
    }
    if (unitNumber == 2) {
      return orderedPackages[1]?.unit || '-'
    }
  }

  handleFilterChange = (identifier, selectedOptions) => {
    const selectedLabels = Array.from(selectedOptions)
    this.props.updateAllSelectedOptions(identifier, selectedLabels)
  }

  render() {
    const data = this.props.productData
    const { filters, fetchDataStatus, isFetching } = this.props;
    const filtersArray = Array.isArray(filters[0]) ? filters[0] : Array.from(filters[0] || []);
    const anyFilterEmpty = filtersArray.some(filter => filter.value.length === 0);
    
    if (this.props.isFetching) {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header">
            <br />
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header detailed-container">
            <h2>{this.props.title}</h2>
            <div className="index-buttons">
              {validateAccessRole(cookie.load('user_role')?.product_module)?.can_write && (
                <button
                  onClick={() => {
                    this.showCreateProductPage()
                  }}
                >
                  Crear producto nuevo
                </button>
              )}
            </div>
          </div>

          <div className="index-table">
            <div className="show-area">
              <h4 className="">Filtrar productos por:</h4>
              <div className="filters-search-container mb-10">
                <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
                  <div className="filters-container">
                    {filters &&
                      Array.from(filters).map((filter, indx) => (
                        <div key={`flr-${indx}`} className="mr-10">
                          <ReactFilter
                            className="mr-10"
                            title={filter.key}
                            identifier={filter.key}
                            options={filter.value}
                            resetWatcher={this.props.filterVersion}
                            onSelectionChange={this.handleFilterChange}
                            disabled={anyFilterEmpty}
                          />
                        </div>
                      ))}
                  </div>
                <div className="search-input-containter">
                  <input
                    value={this.props.searchInput}
                    onChange={this.props.handleSearch}
                    onKeyDown={this.props.handleEnterSearch}
                    className="round-search-input"
                    style={{ fontSize: 12 }}
                    placeholder="Buscar producto por codigo o nombre"
                  />
                  {this.props.searchInput === '' && (
                    <span className="glyphicon glyphicon-search form-control-feedback" />
                  )}
                  {this.props.searchInput && (
                    <div
                      title="Limpiar texto"
                      onClick={() => {
                        this.props.resetReactFilters()
                      }}
                      className="round-search-input-x"
                    >
                      X
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-20">
                {this.props.isAnyFilterSelected && (
                  <a
                    onClick={this.props.resetReactFilters}
                    className="reset-filter cursor-pointer mb-20"
                  >
                    Restablecer filtros aplicados
                  </a>
                )}
              </div>
              <div></div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ verticalAlign: 'middle', padding: '10px', width: '100px' }}>
                      <span className="th-header-text">CÓDIGO</span>
                    </th>

                    <th style={{ verticalAlign: 'middle', padding: '10px', width: '300px' }}>
                      <span className="th-header-text">NOMBRE DEL PRODUCTO</span>
                    </th>

                    {(this.props.productFilter?.byBrand ||
                      this.props.productFilter?.byCategory) && (
                      <>
                        <th style={{ verticalAlign: 'middle', padding: '10px', width: '200px' }}>
                          <span className="th-header-text">UNIDAD DE VENTA 1</span>
                        </th>
                        <th style={{ verticalAlign: 'middle', padding: '10px', width: '200px' }}>
                          <span className="th-header-text">UNIDAD DE VENTA 2</span>
                        </th>
                      </>
                    )}

                    {!this.props.productFilter?.byCategory && (
                      <th style={{ verticalAlign: 'middle', padding: '10px', width: '200px' }}>
                        <span className="th-header-text">MARCA</span>
                      </th>
                    )}

                    {!this.props.productFilter?.byBrand && (
                      <th style={{ verticalAlign: 'middle', padding: '10px', width: '200px' }}>
                        <span className="th-header-text">CATEGORÍA</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((model, index) => (
                      <tr key={index}>
                        <td className="td-text-limited" id={model['id']} onClick={this.showModel}>
                          {model['db_ref']}
                        </td>
                        <td className="td-text-limited" id={model['id']} onClick={this.showModel}>
                          {model['name']}
                        </td>
                        {(this.props.productFilter?.byBrand ||
                          this.props.productFilter?.byCategory) && (
                          <>
                            <td
                              className="td-text-limited"
                              id={model['id']}
                              onClick={this.showModel}
                            >
                              {model.packages[0]?.unit || '-'}
                            </td>
                            <td
                              className="td-text-limited"
                              id={model['id']}
                              onClick={this.showModel}
                            >
                              {model.packages[1]?.unit || '-'}
                            </td>
                          </>
                        )}
                        {!this.props.productFilter?.byCategory && (
                          <td className="td-text-limited" id={model['id']} onClick={this.showModel}>
                            {model['brand'] && model['brand']['description']}
                            {this.props.productFilter?.brandName &&
                              this.props.productFilter.brandName}
                            {this.props.productFilter?.byCategory &&
                              this.props.productFilter.categoryName}
                          </td>
                        )}
                        {!this.props.productFilter?.byBrand && (
                          <td className="td-text-limited" id={model['id']} onClick={this.showModel}>
                            {this.props.productFilter?.byCategory? this.props.productFilter.categoryName : model['product_type_id'] ?(
                                  <div key={model['category']['id']}>
                                    {model['category']['description'] || 'SIN ASIGNAR'}
                                  </div>
                                )
                              : 'SIN ASIGNAR'}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="td-text-limited"
                        colSpan="1"
                      >
                        No hay resultados
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <nav className="index-table">
            {this.props.pagination.total_pages > 1 && (
              <div onClick={this.props.handlePaginationClick}>
                <Pagination
                  current_page={this.props.pagination.current_page}
                  total_pages={this.props.pagination.total_pages}
                />
              </div>
            )}
          </nav>
        </div>
      )
    }
  }
}

import _ from 'lodash'

export const BonusPromoReducer = (state, action) => {
  const bonusProducts = state
  const product = action.payload

  switch (action.type) {
    case 'ADD_TO_BONUS_PRODUCT': {
      const orderPackages = _.sortBy(product?.packages, ['quantity']).reverse()
      product.bonus_product_ids = [product.id]
      product.bonus_product_uid = product.db_ref
      product.bonus_product_db_ref = product.db_ref
      product.bonus_product_name = product.name
      product.bonus_package_uid = null
      product.bonus_quantity = 0
      product.bonus_limit = 0
      product.bonus_package = null
      product.packages = orderPackages
      return [product, ...bonusProducts]
    }

    case 'REMOVE_BONUS_PRODUCT':{
      return bonusProducts.filter((item) => item.id !== product.id)
    }
      

    case 'CHANGE_BONUS_QUANTITY_PRODUCT': {
      bonusProducts.forEach((pr, index) => {
        bonusProducts[index].bonus_quantity = product.bonusQuantity
      })
      return [...bonusProducts]
    }

    case 'CHANGE_BONUS_PACKAGE_PRODUCT': {
      const findBonusPackageProduct = bonusProducts.find((item) => item.id === product.id)

      if (findBonusPackageProduct) {
        const productIndex = bonusProducts.findIndex((item) => item.id === product.id)
        bonusProducts[productIndex].bonus_package = product.bonusPackage
        bonusProducts[productIndex].bonus_package_uid = product.bonusPackage_uid
        return [...bonusProducts]
      }
      return state
    }
      
    case 'CLEAR_BONUS_PRODUCT': {
      return []
    }

    case 'CHANGE_LIMIT_TO_BONUS': {
      const findBonusProduct = bonusProducts.find((item) => item.id === product.id)
  
      if (findBonusProduct) {
        const productIndex = bonusProducts.findIndex((item) => item.id === product.id)
        bonusProducts[productIndex].bonus_limit = product.maxLimit
        return [...bonusProducts]
      }
      return state
    }

    default: {
      return state
    }
      
  }
}

import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Pagination from '../layout/Pagination'
import { mapDayOfTheWeekFromEnglishToSpanish } from '../helpers/salemanHelpers'
import { UNNASSIGN_CLIENT_CONFIRMATION } from '../constants/appMessages'
import {
  addClientToDelivererRoute,
  getDelivererClientList,
  unassignClientFromDeliverer,
  getUnassignedDelivererClientList,
  handleErrors
} from './service/deliveryUserApi'

export default class DelivererRoutesTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      isAddingClient: false,
      per_page: 25,
      total_pages: 1,
      current_page: 1,
      title: 'Rutas',
      delivererClients: [],
      unassignedClientsSearchResults: [],
      selectedClientToAdd: undefined,
      isErrorShowing: false,
      clientSearchFieldValue: '',
      selectedDayOfTheWeek: 'everyday'
    }
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  fetchDataFromServer = () => {
    getDelivererClientList(
      this.props.deliverer.id,
      '',
      this.state.current_page,
      this.state.per_page
    )
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          isFetching: false,
          isAddingClient: false,
          clientSearchFieldValue: '',
          selectedDayOfTheWeek: 'everyday',
          delivererClients: data.table,
          total_pages: Math.ceil(data['count'] / this.state.per_page)
        })
      })
      .catch((error) => console.log(error))
  }

  handleAddClientShowForm = () => {
    this.setState(
      {
        isAddingClient: true
      },
      this.props.changeEditMode(!this.state.isAddingClient)
    )
  }

  handleCancelAddClient = () => {
    this.setState(
      {
        isAddingClient: false,
        isErrorShowing: false,
        unassignedClientsSearchResults: []
      },
      this.props.changeEditMode(false)
    )
  }

  handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name
    this.setState({ [name]: value })
  }

  handleSearchForUnassignedClients = (event) => {
    event.preventDefault()

    if (event.target.value.length < 2) return

    const selectedClient = event.target.value
    this.setState({ clientSearchFieldValue: selectedClient })
    let searchResults = this.state.unassignedClientsSearchResults
    searchResults = searchResults.map(
      (client) => `${client.db_ref} - ${client.customer_name_db_ref}`
    )

    const isSelectedClientInSearchResults = searchResults.includes(selectedClient)

    if (isSelectedClientInSearchResults) return

    getUnassignedDelivererClientList(this.props.deliverer.id, event.target.value)
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        this.setState({ unassignedClientsSearchResults: data })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleNewClientSubmit = (event) => {
    event.preventDefault()
    this.setState({ isErrorShowing: false })

    const selectedClient = this.state.clientSearchFieldValue
    let searchResults = this.state.unassignedClientsSearchResults
    searchResults = searchResults.map(
      (client) => `${client.db_ref} - ${client.customer_name_db_ref}`
    )
    const isSelectedClientInSearchResults = searchResults.includes(selectedClient)

    if (!isSelectedClientInSearchResults) {
      this.setState({ isErrorShowing: true })
    } else {
      const selectedClientId =
        this.state.unassignedClientsSearchResults[searchResults.indexOf(selectedClient)].id
      const params = {
        deliverer_id: this.props.deliverer.id,
        client_id: selectedClientId,
        visit_day: this.state.selectedDayOfTheWeek
      }

      addClientToDelivererRoute(params)
        .then((response) => handleErrors(response))
        .then((response) => response.json())
        .then(() => {
          this.props.changeEditMode(false)
          this.fetchDataFromServer()
        })
        .catch((error) => {
          console.log(error)
        })
      this.setState({ unassignedClientsSearchResults: [] })
    }
  }

  handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      this.handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      this.handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      this.handlePagination(event.target.parentNode.parentNode)
    }
  }

  handlePagination = (target) => {
    if (target.id === 'first-page') {
      if (this.state.current_page !== 1) {
        this.setState({ current_page: 1 }, this.fetchDataFromServer)
      }
    } else if (target.id === 'last-page') {
      if (this.state.current_page !== this.state.total_pages) {
        this.setState({ current_page: this.state.total_pages }, this.fetchDataFromServer)
      }
    } else if (target.id === 'previous-page') {
      if (this.state.current_page !== 1) {
        this.setState(
          { current_page: parseInt(this.state.current_page) - 1 },
          this.fetchDataFromServer
        )
      }
    } else if (target.id === 'next-page') {
      if (this.state.current_page !== this.state.total_pages) {
        this.setState(
          { current_page: parseInt(this.state.current_page) + 1 },
          this.fetchDataFromServer
        )
      }
    }
  }

  handleUnassignClient = (event) => {
    if (window.confirm(UNNASSIGN_CLIENT_CONFIRMATION)) {
      event.preventDefault()

      const clientId = event.currentTarget.id
      const params = { active: false }

      unassignClientFromDeliverer(clientId, params)
        .then((response) => handleErrors(response))
        .then((response) => response.json())
        .then((data) => this.fetchDataFromServer())
        .catch((error) => console.log(error))
    }
  }

  renderUnassignedClients = () =>
    this.state.unassignedClientsSearchResults.map((clientObj) => (
      <option
        key={clientObj.db_ref}
        id={clientObj.db_ref}
        value={`${clientObj.db_ref} - ${clientObj.customer_name_db_ref}`}
      >
        {`${clientObj.db_ref} - ${clientObj.customer_name_db_ref}`}
      </option>
    ))

  render() {
    const { delivererClients } = this.state

    if (this.state.isFetching) {
      return (
        <div className="salesman-details">
          <div className="show-header salesman">
            <br />
            <p style={{ textAlign: 'center' }}>Obteniendo datos...</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="salesman-details">
          <div className="show-header salesman">
            <h2>Clientes asignados a entregador:</h2>
            <div className="show-buttons">
              {!this.state.isAddingClient && (
                <div className="show-buttons">
                  <input
                    type="submit"
                    onClick={this.handleAddClientShowForm}
                    value="Asignar cliente"
                  />
                </div>
              )}
              {this.state.isAddingClient && (
                <div>
                  <button className="cancel-button" onClick={this.handleCancelAddClient}>
                    Cancelar
                  </button>
                  <input
                    className="save-button"
                    value="Guardar"
                    type="submit"
                    onClick={this.handleNewClientSubmit}
                  />
                </div>
              )}
            </div>
          </div>
          <hr />

          {this.state.isAddingClient && (
            <Fragment>
              <div className="salemsman-routes__show-assign-wrapper show-assign-wrapper">
                <div className="assign-client__header-text">
                  <h2>Escoja el cliente que desea asignarle al entregador:</h2>
                </div>
                <div className="show-assign-fields">
                  <div className="salesman-routes__find-client__fields-container">
                    <div>Cliente</div>
                    <input
                      type="text"
                      onChange={this.handleSearchForUnassignedClients}
                      className="find-client__input"
                      placeholder="Escribir nombre o código"
                      list="unassigned-clients"
                    />
                    <span className="icon-search">
                      <FontAwesomeIcon className="icon-search" icon={faSearch} />
                    </span>
                    <datalist id="unassigned-clients">{this.renderUnassignedClients()}</datalist>
                    {this.state.isErrorShowing && (
                      <div className="red">
                        Por favor selecione un cliente dentro de las opciones.
                      </div>
                    )}
                  </div>
                  <div className="salesman-routes__find-client__fields-container">
                    <div>Día de visita</div>
                    <select
                      style={{ marginTop: '8px', height: '40px' }}
                      value={this.state.selectedDayOfTheWeek}
                      name="selectedDayOfTheWeek"
                      onChange={this.handleChange}
                      className="find-client__input"
                    >
                      <option value="everyday">Todos los dias</option>
                    </select>
                  </div>
                </div>
                <hr />
              </div>
            </Fragment>
          )}
          <table className="table table-hover">
            <thead>
              <tr>
                <th>CÓDIGO</th>
                <th>NOMBRE</th>
                <th>DÍA DE VÍSITA</th>
                <th>ACCIÓN</th>
              </tr>
            </thead>
            <tbody>
              {delivererClients.length > 0 &&
                delivererClients.map((client, index) => (
                  <tr key={index}>
                    <td id={client['client_db_ref']}>{client['client_db_ref']}</td>
                    <td id={client['client_db_ref']}>{client['client_name']}</td>
                    <td id={client['client_db_ref']}>
                      {mapDayOfTheWeekFromEnglishToSpanish(client['visit_day'])}
                    </td>
                    <td id={client['client_db_ref']}>
                      <button
                        className="unassign-price-list-button"
                        id={client.id}
                        onClick={this.handleUnassignClient}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              {delivererClients.length === 0 && (
                <tr>
                  <td>No hubo resultados</td>
                </tr>
              )}
            </tbody>
          </table>
          <nav onClick={this.handlePaginationClick}>
            {this.state.total_pages > 1 && (
              <Pagination
                current_page={this.state.current_page}
                total_pages={this.state.total_pages}
              />
            )}
          </nav>
        </div>
      )
    }
  }
}

import React, { useContext, useState } from 'react'
import styles from '../../css/styles-step-bar.module.css'
import { ProductCreateContext } from '../context/ProductCreateProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { SubmitCreateProductContext } from '../context/SubmitCreateProductProvider'
import { PriceListCreateContext } from '../context/PriceListCreateProvider'

const StepStates = {
  NOT_STARTED: 'not_started',
  CURRENT: 'current',
  ERROR: 'error',
  COMPLETED: 'completed'
}

function stepsReducer(steps, action) {
  return steps.map(function (step, i) {
    if (i < action.payload.index) {
      step.state = StepStates.COMPLETED
    } else if (i === action.payload.index) {
      step.state = action.payload.state
    } else {
      step.state = StepStates.NOT_STARTED
    }
    return step
  })
}

function ProductStepProgressBar(props) {
  const {
    steps,
    startingStep,
    wrapperClass,
    progressClass,
    stepClass,
    labelClass,
    subtitleClass,
    contentClass,
    buttonWrapperClass,
    primaryBtnClass,
    secondaryBtnClass,
    submitBtnName,
    previousBtnName,
    nextBtnName
  } = props

  const { isValid, handleCancelStep, images, handleSubmit } = useContext(ProductCreateContext)
  const { priceListState } = useContext(PriceListCreateContext)
  const { handleCreateProduct } = useContext(SubmitCreateProductContext)
  const [state, dispatch] = React.useReducer(stepsReducer, steps)
  const [currentIndex, setCurrentIndex] = React.useState(startingStep)
  const [productInfo, setProductInfo] = useState({})

  React.useEffect(function () {
    dispatch({
      type: 'init',
      payload: { index: currentIndex, state: StepStates.CURRENT }
    })
  }, [])

  function stepConfigValidator() {
    return isValid
  }

  function nextHandler() {
    if (currentIndex === steps.length - 1) {
      return
    }
    let isStateValid = true
    const stepValidator = stepConfigValidator
    if (stepValidator) {
      isStateValid = stepValidator()
    }
    dispatch({
      type: 'next',
      payload: {
        index: isStateValid ? currentIndex + 1 : currentIndex,
        state: isStateValid ? StepStates.CURRENT : StepStates.ERROR
        //state: StepStates.CURRENT
      }
    })
  }

  function prevHandler() {
    if (currentIndex === 0) {
      return
    }
    dispatch({
      type: 'previous',
      payload: {
        index: currentIndex - 1,
        state: StepStates.CURRENT
      }
    })
    setCurrentIndex(currentIndex - 1)
  }

  function onNextSubmit(data) {
    if (currentIndex === 0) setProductInfo(data)

    if (currentIndex === steps.length - 1) {
      return
    }
    dispatch({
      type: 'next',
      payload: {
        index: currentIndex + 1,
        state: StepStates.CURRENT
      }
    })
    setCurrentIndex(currentIndex + 1)
  }

  const [executing, setExecuting] = useState(false)

  const formSubmit = async () => {
    setExecuting(true)
    try {
      await handleCreateProduct(productInfo, images, priceListState)
    } finally {
      setExecuting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onNextSubmit)}>
      <div className={`${styles['progress-bar-wrapper']} ${wrapperClass || ''}`}>
        <ul className={`${styles['step-progress-bar']} ${progressClass || ''}`}>
          {state.map(function (step, i) {
            return (
              <li
                key={i}
                className={`${styles['progress-step']}${
                  step.state === StepStates.COMPLETED ? ` ${styles.completed}` : ''
                }${step.state === StepStates.CURRENT ? ` ${styles.current}` : ''}${
                  step.state === StepStates.ERROR ? ` ${styles['has-error']}` : ''
                } ${stepClass || ''}`}
              >
                {step.state === StepStates.COMPLETED && (
                  <span className={styles['step-icon']}>
                    <svg
                      width="1.5rem"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 3.5L4.5 7.5L12 1" stroke="white" strokeWidth="1.5" />
                    </svg>
                  </span>
                )}
                {step.state === StepStates.ERROR && <span className={styles['step-icon']}>!</span>}
                {step.state !== StepStates.COMPLETED && step.state !== StepStates.ERROR && (
                  <span className={styles['step-index']}>{i + 1}</span>
                )}
                <div className={`${styles['step-label']} ${labelClass || ''}`}>
                  {step.label}
                  {step.subtitle && (
                    <div className={`${styles['step-label-subtitle']} ${subtitleClass || ''}`}>
                      {step.subtitle}
                    </div>
                  )}
                </div>
              </li>
            )
          })}
        </ul>

        <div className=" flex-container">
          <div className="right-text">
            <h5>Ingrese los siguientes datos para crear un producto nuevo</h5>
          </div>

          <div className={`${styles['step-buttons']} ${buttonWrapperClass || ''}`}>
            {currentIndex === 0 ? (
              <a></a>
            ) : (
              <a
                className={`${styles['step-back-btn']} ${
                  currentIndex === 0 ? styles.disabled : ''
                } ${secondaryBtnClass || ''}`}
                onClick={prevHandler}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="xs" />
                {previousBtnName ? previousBtnName : 'Regresar a Paso 1'}
              </a>
            )}

            <a
              className={`${styles['step-action-btn']} ${styles['action-btn-secondary']} ${
                currentIndex === 0 ? '' : ''
              } ${secondaryBtnClass || ''}`}
              onClick={handleCancelStep}
            >
              {previousBtnName ? previousBtnName : 'Cancelar'}
            </a>

            {currentIndex === state.length - 1 ? (
              <button
                type="button"
                disabled={executing}
                className={`${styles['step-action-btn']} ${styles['action-btn-primary']} ${
                  primaryBtnClass || ''
                }`}
                onClick={formSubmit}
              >
                {submitBtnName || 'Guardar'}
              </button>
            ) : (
              <button
                type="submit"
                className={`${styles['step-action-btn']} ${styles['action-btn-primary']} ${
                  primaryBtnClass || ''
                }`}
                onClick={nextHandler}
              >
                {nextBtnName ? nextBtnName : 'Siguiente'}
              </button>
            )}
          </div>
        </div>

        <div
          className={`${styles['step-content']} ${contentClass || ''}`}
          key={props.steps[currentIndex].content}
        >
          {props.steps[currentIndex].content}
        </div>
      </div>
    </form>
  )
}

export default ProductStepProgressBar

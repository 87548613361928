import React from 'react'

export default class NoEffectiveVisitDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activity: this.props.activity || []
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ activity: nextProps.activity })
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          {this.props.transactionUuid && (
            <>
              <div className="row show-product-header">
                <div className="col-sm-6">
                  <h3 className="fs-18">Detalles de visita:</h3>
                </div>
              </div>
              <hr />
            </>
          )}
          <div>
            <div className="show-product-header show-header">
              <div className="order-client-name">{this.state.activity['client_name']}</div>
              <div className="order-client-db_ref">
                Código: {this.state.activity['client_db_ref']}
              </div>
            </div>
            <hr />
            <div>
              <table cellSpacing="10">
                <tbody>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Fecha de creación:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.getFormattedDate(this.state.activity['created_date'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Número de la visita:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.state.activity['no_effective_visit_db_ref']}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Vendedor:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['salesman_name']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Tipo de reporte:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['report_type'] || '-'}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Motivo / descripción:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['motive'] || 'N/A'}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

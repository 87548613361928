import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ClientIndexView from './ClientIndexView'
import fetchWithAuth from '../helpers/fetchWithAuth'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const ClientIndexApp = () => {
  const initPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let page = searchParams.get('page')
    let currentPage = 1
    if (page) {
      currentPage = Number(page)
    } else {
      searchParams.set('page', '1')
      let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      window.history.pushState(null, '', newRelativePathQuery)
    }
    return {
      per_page: 25,
      current_page: currentPage,
      total_pages: 1,
      searchInput: ''
    }
  }

  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState(null)
  const [title, setTitle] = useState('Clientes')
  const [collapsed, setCollapsed] = useState(false)
  const [fetchDataStatus, setFetchDataStatus] = useState({ filters: false })
  const [filterQuery, setFilterQuery] = useState('')
  const [pagination, setPagination] = useState(initPaginationData())
  const [allSelectedOptions, setAllSelectedOptions] = useState(new Map())
  const [resetReactFilters, setResetReactFilters] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [filters, setFilters] = useState(new Map())
  const [clientsData, setClientsData] = useState([])
  const [priceListsData, setPriceListsData] = useState([])
  const [triggerSearch, setTriggerSearch] = useState(false)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort()
  }
  window.addEventListener('beforeunload', abortCurrentFetchs)
  //////

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    let targetId = target.id
    switch (targetId) {
      case 'first-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: 1 })
        }
        break
      case 'last-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.total_pages })
        }
        break
      case 'previous-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: pagination.current_page - 1 })
        }
        break
      case 'next-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.current_page + 1 })
        }
        break
    }
  }

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', pagination.current_page)
    let newRelativePathQuery = `${window.location.pathname}?${searchParams}`
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const updateAllSelectedOptions = (identifier, selectedOptions) => {
    const selectedOptionsArray = Array.from(selectedOptions)
    setAllSelectedOptions((prevOptions) => {
      const newOptions = new Map(prevOptions)
      newOptions.set(identifier, selectedOptionsArray)
      return newOptions
    })
  }

  const buildFiltersQuery = () => {
    let clientArray = []
    let pricelistArray = []
    let queryParam = ''
    for (const [key, value] of allSelectedOptions) {
      if (value.length !== 0) {
        if (key === 'CLIENTE') {
          value.map((v) => {
            let client = clientsData.find((c) => v.includes(c.name));
            clientArray.push(client.id)
          })
          queryParam += `&clientes=${clientArray.join(',')}`
        } else if (key === 'LISTA DE PRECIO ASIGNADA') {
          value.map((v) => {
            let pricelist = priceListsData.find((p) => v.includes(p.name));
            pricelistArray.push(pricelist.id)
          })
          queryParam += `&pricelist_id=${pricelistArray.join(',')}`
        }
      }
    }
    setFilterQuery(queryParam)
  }

  const resetFilters = () => {
    setAllSelectedOptions(new Map())
    setFilterQuery('')
    setSearchInput('')
    setTriggerSearch((prev) => !prev)
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1,
      per_page: prevPagination.per_page
    }))
    setResetReactFilters(!resetReactFilters)
  }

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  useEffect(() => {
    initPaginationData()
    async function fetchAll() {
      await fetchFilterInfoFromServer()
    }
    fetchAll()
  }, [])

  const isAnyFilterSelected = () => {
    return Array.from(allSelectedOptions.values()).some((options) => options.length > 0)
  }

  const fetchDataFromServer = async () => {
    const per_page = pagination.per_page
    const page = pagination.current_page
    const response = await fetch(
      BACKEND_URL +
        '/businesses/1/clients' +
        '?page=' +
        page +
        '&per_page=' +
        per_page +
        (filterQuery ? '&filter_search=true' : '') +
        (filterQuery ? filterQuery : '') +
        (searchInput ? '&q=' + searchInput : ''),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (!response.ok) {
      return console.error(`Error in ClientIndexApp.js: ${response.status}`)
    }
    const data = await response.json()

    setPagination({ ...pagination, total_pages: Math.ceil(data['count'] / pagination.per_page) })
    setData(data['table'])
    setIsFetching(false)
  }

  useEffect(() => {
    updateURLPaginationData()
    async function fetchAll() {
      await fetchDataFromServer()
    }
    fetchAll()
  }, [pagination.current_page, triggerSearch, filterQuery])

  useEffect(() => {
    if (allSelectedOptions.size > 0) {
      buildFiltersQuery()
    }
  }, [allSelectedOptions])

  const fetchFilterInfoFromServer = async (loadingState = true) => {
    if (loadingState) {
      setFetchDataStatus({ ...fetchDataStatus, filters: true });
    }
    const response = await fetchWithAuth(`${BACKEND_URL}/businesses/1/clients?client_names_only=true`, {
      method: 'GET',
      signal: abortController.signal,
    });
  
    if (!response || response.error) {
      return console.error(`Error in fetchFilterInfoFromServer: ${response ? response.status : 'Fetch error'}`);
    }
  
    const data = response;
    setClientsData(data.clients);
    setPriceListsData(data.price_lists);
    setFilters([
      {
        key: 'CLIENTE',
        value: data.clients?.map((c) => `${c.id} - ${c.name}`)
      },
      {
        key: 'LISTA DE PRECIO ASIGNADA',
        value: data.price_lists
          ?.filter((c) => c.name !== null && c.id !== null)
          .map((c) => `${c.id} - ${c.name}`)
      }
    ]);
  
    setFetchDataStatus({ ...fetchDataStatus, filters: false });
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      setTriggerSearch((prev) => !prev)
    }
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    pagination.current_page = 1
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      pagination.current_page = 1
      fetchDataFromServer()
    }
  }

  return (
    <div>
      <div>
        <div>
          <TopBar
            searchPlaceholder={''}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <ClientIndexView
            collapsed={collapsed}
            data={data}
            pagination={pagination}
            handlePaginationClick={handlePaginationClick}
            filters={filters}
            updateAllSelectedOptions={updateAllSelectedOptions}
            resetReactFilters={resetReactFilters}
            resetFilters={resetFilters}
            onSearch={handleSearch}
            handleSearchKeyPress={handleSearchKeyPress}
            isAnyFilterSelected={isAnyFilterSelected()}
            isFetching={isFetching}
            title={title}
            searchInput={searchInput}
            handleSearch={handleSearch}
            handleEnterSearch={handleEnterSearch}
          />
        </div>
      </div>
      <div>
        <NavBar activeItem="Clientes" isEditing={false} collapsed={collapsed} />{' '}
      </div>
    </div>
  )
}

export default ClientIndexApp

import React from 'react'
import cookie from 'react-cookies'
import ProductDetailsTab from './ProductDetailsTab'
import ProductPriceListsTab from './ProductPriceListsTab'
import ProductHistoryTab from './ProductHistoryTab'
import ProductPhotoTab from './ProductPhotoTab'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ShowProductView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: this.getActiveTab(),
      isFetching: true,
      productDetailsData: null,
      productPriceListsData: null,
      title: '',
      id: window.location.href.split('/')[window.location.href.split('/').length - 2],
      editingPrices: false,
      addingPrices: false,
      alertState: 'alert alert-success'
    }
  }

  getActiveTab = () => {
    if (window.location.href.indexOf('listas_de_precios') >= 0) {
      return 'Listas de Precios'
    } else if (window.location.href.indexOf('imagenes') >= 0) {
      return 'Imagenes'
    } else {
      return 'Detalles'
    }
  }

  // in order to change active state for tabs
  handleTabClick = (event) => {
    // if tab was clicked (tab is an <a> element)
    if (event.target.tagName === 'A') {
      if (
        this.state.editingPrices === false &&
        this.state.addingPrices === false &&
        this.props.isEditing === false
      ) {
        this.setState({ activeTab: event.target.innerText }, this.render)
      } else {
        const val = window.confirm(
          'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
        )
        if (val === true) {
          this.setState({ activeTab: event.target.innerText }, this.render)
          this.setState({ editingPrices: false, addingPrices: false })
          this.passEditState(false)
        }
      }
    }
  }

  passEditState = (val) => {
    this.props.editStateChanged(val)
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  fetchDataFromServer = () => {
    this.setState({ isFetching: true })
    fetch(BACKEND_URL + '/products/' + this.state.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        this.setState({
          productDetailsData: data,
          isFetching: false,
          title: 'Producto: ' + data['db_ref'] + '-' + data['name']
        })
      })
      .catch((e) => console.log(e))
  }

  editInDetailsTab = (event) => {
    if (event.target.value === 'Actualizar detalles') {
      let newTitle =
        'Actualizar detalles de producto: ' +
        this.state.productDetailsData['db_ref'] +
        '-' +
        this.state.productDetailsData['name']
        //Control de caracteres
      newTitle = newTitle.length > 80 ? newTitle.substring(0, 77) + '...' : newTitle

      this.setState({
        title: newTitle
      })
      this.passEditState(true)
    } else if (event.target.value === 'Cancelar') {
      this.setState({
        title:
          'Producto: ' +
          this.state.productDetailsData['db_ref'] +
          '-' +
          this.state.productDetailsData['name']
      })
      this.passEditState(false)
    } else if (event.target.value === 'Guardar') {
      this.setState({
        title:
          'Producto: ' +
          this.state.productDetailsData['db_ref'] +
          '-' +
          this.state.productDetailsData['name']
      })
    }
  }

  editInPriceListTab = (event) => {
    if (event.target.value === 'Actualizar') {
      this.setState({ editingPrices: true })
      this.passEditState(true)
    } else if (event.target.value === '+  Agregar lista de precio') {
      this.setState({ addingPrices: true })
      this.passEditState(true)
    } else if (event.target.value === 'Cancelar') {
      if (event.target.id === 'cancel-add') {
        this.setState({ addingPrices: false }, this.checkForEditMode)
      } else if (event.target.id === 'cancel-edit') {
        this.setState({ editingPrices: false }, this.checkForEditMode)
      }
    }
  }

  checkForEditMode = () => {
    if (this.state.editingPrices === false && this.state.addingPrices === false) {
      this.passEditState(false)
    }
  }

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  }

  render() {
    if (this.state.isFetching === false) {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          {this.props.successCreateForNewProduct && (
            <div className={this.state.alertState} role="alert">
              <button type="button" className="close" onClick={this.hiddenAlert} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <strong className="success-message">El producto nuevo fue creado exitosamente.</strong>
            </div>
          )}
          <div className="main-show-header">
            <h2>{this.state.title}</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div>
                <div onClick={this.handleTabClick}>
                  <ul className="nav nav-tabs">
                    <li
                      id="details_tab"
                      className={`${this.state.activeTab === 'Detalles' ? 'active' : ''}`}
                    >
                      <a
                        className={
                          this.state.activeTab !== 'Detalles'
                            ? 'inactive-tab cursor-pointer'
                            : 'cursor-pointer'
                        }
                      >
                        Detalles
                      </a>
                    </li>
                    <li
                      id="price_lists_tab"
                      className={this.state.activeTab === 'Listas de Precios' ? 'active' : ''}
                    >
                      <a
                        className={
                          this.state.activeTab !== 'Listas de Precios'
                            ? 'inactive-tab cursor-pointer'
                            : 'cursor-pointer'
                        }
                      >
                        Listas de Precios
                      </a>
                    </li>
                    <li
                      id="price_lists_tab"
                      className={this.state.activeTab === 'Historial' ? 'active' : ''}
                    >
                      <a
                        className={
                          this.state.activeTab !== 'Historial'
                            ? 'inactive-tab cursor-pointer'
                            : 'cursor-pointer'
                        }
                      >
                        Historial
                      </a>
                    </li>
                    <li
                      id="price_lists_tab"
                      className={this.state.activeTab === 'Imagenes' ? 'active' : ''}
                    >
                      <a
                        className={
                          this.state.activeTab !== 'Imagenes'
                            ? 'inactive-tab cursor-pointer'
                            : 'cursor-pointer'
                        }
                      >
                        Imagenes
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  {this.state.activeTab === 'Detalles' && (
                    <div onClick={this.editInDetailsTab}>
                      <ProductDetailsTab data={this.state.productDetailsData} id={this.state.id} />
                    </div>
                  )}
                  {this.state.activeTab === 'Listas de Precios' && (
                    <div onClick={this.editInPriceListTab}>
                      <ProductPriceListsTab
                        data={this.state.productDetailsData}
                        id={this.state.id}
                      />
                    </div>
                  )}
                  {this.state.activeTab === 'Historial' && (
                    <div>
                      <ProductHistoryTab data={this.state.productDetailsData} id={this.state.id} />
                    </div>
                  )}
                  {this.state.activeTab === 'Imagenes' && (
                    <div>
                      <ProductPhotoTab data={this.state.productDetailsData} id={this.state.id} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="main-show-header">
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    }
  }
}

import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import SegmentContext from '../segments/context/SegmentContext'

function SearchInput(props) {
  const [query, setQuery] = useState(props.query || '');

  useEffect(() => {
    setQuery(props.query || '');
  }, [props.query]);

  const handleInputChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    if (props.onSearchUpdate) {
      props.onSearchUpdate(newQuery);
    } else if (props.SearchInput) {
      props.SearchInput(newQuery);
    }
  }

  const handleSearch = (event) => {
    if (event.keyCode === 13) { // Enter key
      event.preventDefault();
      if (props.onSearch) {
        props.onSearch(query);
      }
    }
  }

  const clearSearch = () => {
    if (props.onClearSearch) {
      props.onClearSearch();
    } else {
      const clearedQuery = '';
      setQuery(clearedQuery);
      if (props.onSearchUpdate) {
        props.onSearchUpdate(clearedQuery);
      } else if (props.SearchInput) {
        props.SearchInput(clearedQuery);
      }
      if (props.onSearch) {
        props.onSearch(clearedQuery);
      }
    }
  }

  const handleSearchUpdate = (newQuery) => {
    this.setState({ query: newQuery });
    this.filterData(); 
  }

  return (
    <div className="search-container">
      <input
        type="text"
        placeholder={props.placeholder || "Buscar..."}
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleSearch}
        className="round-search-input"
      />
      {query === '' ? (
        <FontAwesomeIcon icon={faSearch} className="form-control-feedback" />
      ) : (
        <div
          title="Limpiar texto"
          onClick={clearSearch}
          className="round-search-input-x"
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
    </div>
  );
}

export default SearchInput;
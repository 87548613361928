import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import cookie from 'react-cookies'
import ReactFilter from '../../components/inputs/ReactFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import SegmentContext from '../context/SegmentContext'
import Pagination from '../../layout/Pagination'
import SearchInput from '../../layout/SearchInput'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function DetailsView(props) {
  const [searchInput, setSearchInput] = useState('')
  const [qualifiers, setQualifiers] = useState(new Map())
  const [qualifiersDic, setQualifiersDic] = useState(new Map())
  const {
    qualifiersData,
    clientsData,
    pagination,
    fetchDataStatus,
    promoQualifiersData,
    setQualifierQuery,
    setSearchQuery,
    qualifierQuery,
    searchQuery,
    handlePaginationClick
  } = useContext(SegmentContext)
  const [resetReactFilters, setResetReactFilters] = useState(false)
  const [allSelectedOptions, setAllSelectedOptions] = useState(new Map())

  useEffect(() => {
    buildQualifiersQuery()
  }, [allSelectedOptions])

  useEffect(() => {
    handleQualifierData()
  }, [qualifiersData, promoQualifiersData])

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      buildSearchQuery()
    }
  }

  const isFilterSelected = () => {
    // Verifica si searchQuery no es null y tiene una longitud mayor a 0
    if (searchQuery && searchQuery.length > 0) return true;
  
    // Verifica si hay opciones seleccionadas en los filtros
    for (const [key, value] of allSelectedOptions) {
      if (value && value.length > 0) return true;
    }
  
    // Retorna false si no hay filtros seleccionados
    return false;
  }
  const buildSearchQuery = () => {
    let queryParam = ''
    if (searchInput) {
      queryParam = `&q=${searchInput}`
    }
    setSearchQuery(queryParam)
  }

  const buildQualifiersQuery = () => {
    let queryParam = '&search_qualifier=true'
    if (allSelectedOptions)
      for (const [key, value] of allSelectedOptions) {
        queryParam += `&${qualifiersDic.get(key)}=${value.join(',')}`
      }
    setQualifierQuery(queryParam)
  }

  const handleQualifierData = async () => {
    let data = { ...qualifiersData }
    let availableQualifiers = data['result']?.available_qualifiers || []
    let savedQualifiers = data['result']?.saved_qualifiers || []
    let mapAvailableQualifiers = new Map()
    let mapSavedQualifiers = new Map()
    let mapQualifiersDic = new Map()

    for (const availableQualifier of availableQualifiers) {
      mapAvailableQualifiers.set(availableQualifier.value, availableQualifier.label)
    }

    if (savedQualifiers && savedQualifiers?.qualifier_2) {
      mapSavedQualifiers.set(
        mapAvailableQualifiers.get(savedQualifiers.qualifier_2),
        promoQualifiersData?.qualifier_2
      )
      mapQualifiersDic.set(mapAvailableQualifiers.get(savedQualifiers.qualifier_2), 'qualifier_2')
    }

    if (savedQualifiers && savedQualifiers?.qualifier_3) {
      mapSavedQualifiers.set(
        mapAvailableQualifiers.get(savedQualifiers.qualifier_3),
        promoQualifiersData?.qualifier_3
      )
      mapQualifiersDic.set(mapAvailableQualifiers.get(savedQualifiers.qualifier_3), 'qualifier_3')
    }
    if (savedQualifiers && savedQualifiers?.qualifier_4) {
      mapSavedQualifiers.set(
        mapAvailableQualifiers.get(savedQualifiers.qualifier_4),
        promoQualifiersData?.qualifier_4
      )
      mapQualifiersDic.set(mapAvailableQualifiers.get(savedQualifiers.qualifier_4), 'qualifier_4')
    }
    if (savedQualifiers && savedQualifiers?.qualifier_5) {
      mapSavedQualifiers.set(
        mapAvailableQualifiers.get(savedQualifiers.qualifier_5),
        promoQualifiersData?.qualifier_5
      )
      mapQualifiersDic.set(mapAvailableQualifiers.get(savedQualifiers.qualifier_5), 'qualifier_5')
    }
    setQualifiers(mapSavedQualifiers)
    setQualifiersDic(mapQualifiersDic)
  }

  const resetFilters = () => {
    setSearchInput('')
    setResetReactFilters(!resetReactFilters)
  }
  return (
    <>
      <h4 className="mt-50">Filtrar clientes por atributos:</h4>
      <div className="filters-search-container mb-10">
        <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
        {fetchDataStatus?.qualifiers? <span>Obteniendo filtros...</span> : <div className="filters-container">
        {Array.from(qualifiers?.entries()).map((qualifier, indx) => (
          <div key={`qlf-${indx}`} className="mr-10">
            <ReactFilter
              className="mr-10"
              title={qualifier[0]}
              identifier={qualifier[0]}
              options={qualifier[1]}
              resetWatcher={resetReactFilters}
              onChange={(e) => {
                let so = new Map(allSelectedOptions)
                so.set(e.identifier, e.selectedOptions)
                setAllSelectedOptions(so)
              }}
            />
          </div>
        ))}
        </div>}
        <div className="search-input-containter">
        <SearchInput
        query={searchInput}
        setSearchInput={setSearchInput}
        onSearch={buildSearchQuery}
        placeholder="Buscar listas por código o nombre" 
        onClearSearch={() => {
          setSearchInput('');
          setSearchQuery('');
        }}/>
        </div>
      </div>
      <div className="mb-20">
      {isFilterSelected() && (
          <a onClick={resetFilters} className="reset-filter cursor-pointer mb-20">
            Restablecer filtros aplicados
          </a>
        )}
      </div>
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ verticalAlign: 'middle' }}>
                <span className="th-header-text">CÓDIGO</span>
              </th>
              <th style={{ verticalAlign: 'middle' }}>
                <span className="th-header-text">CLIENTE</span>
              </th>
              {Array.from(qualifiers?.entries()).map((qualifier, indx) => (
                <th key={indx} style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">{qualifier[0].toUpperCase()}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {(clientsData || []).length === 0 && searchInput !== '' ? (
            <tr>
              <td colSpan={qualifiers.size + 2} className="td-text-limited">No hay resultados</td>
            </tr>
          ) : (
            clientsData?.map((client, indx) => (
              <tr key={indx}>
                <td className="td-text-limited">{client.db_ref}</td>
                <td className="td-text-limited">{client.name}</td>
                {qualifiersData?.result?.saved_qualifiers?.qualifier_2 && (
                  <td className="td-text-limited">{client.qualifier_2}</td>
                )}
                {qualifiersData?.result?.saved_qualifiers?.qualifier_3 && (
                  <td className="td-text-limited">{client.qualifier_3}</td>
                )}
                {qualifiersData?.result?.saved_qualifiers?.qualifier_4 && (
                  <td className="td-text-limited">{client.qualifier_4}</td>
                )}
                {qualifiersData?.result?.saved_qualifiers?.qualifier_5 && (
                  <td className="td-text-limited">{client.qualifier_5}</td>
                )}
              </tr>
              ))
              )}
          </tbody>
        </Table>
        {fetchDataStatus?.clients && <span>Obteniendo datos...</span>}
        {pagination.total_pages > 1 && (
          <div onClick={handlePaginationClick}>
            <Pagination
              current_page={pagination.current_page}
              total_pages={pagination.total_pages}
            />
          </div>
        )}
      </div>
    </>
  )
}
import React from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ConfirmNewPasswordPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newPassword: '',
      confirmNewPassword: '',
      token: this.getToken(),
      showNewPassword: false,
      showConfirmNewPassword: false,
      notMatchingPasswords: false,
      failedRequest: false,
      invalidToken: false
    }
  }

  getToken = () => window.location.href.split('nueva-contrasena/').reverse()[0]

  handleChange(event) {
    const obj = {}
    obj[event.target.name] = event.target.value
    this.setState(obj)
  }

  hideMessage = () => {
    this.setState({ notMatchingPasswords: false, failedRequest: false, invalidToken: false })
  }

  sendResetRequest = (event) => {
    event.preventDefault()
    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({ failedRequest: false, notMatchingPasswords: true, invalidToken: false })
      setTimeout(this.hideMessage, 10000)
    } else {
      fetch(BACKEND_URL + '/users/password', {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: {
            email: this.state.email,
            password: this.state.newPassword,
            reset_password_token: this.state.token
          }
        })
      })
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            window.location = '/reset_done'
          } else if (response.status === 422) {
            this.setState({ failedRequest: false, notMatchingPasswords: false, invalidToken: true })
            setTimeout(this.hideMessage, 10000)
          } else {
            this.setState({ failedRequest: true, notMatchingPasswords: false, invalidToken: false })
            setTimeout(this.hideMessage, 10000)
          }
          return response.json()
        })
        .then((json) => this.evaluateResponse(json))
        .catch((e) => console.log(e))
    }
  }

  evaluateResponse = (json) => {
    console.log(json)
  }

  toggleEyeForNewPassword = (event) => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  }

  toggleEyeForConfirmNewPassword = (event) => {
    this.setState({ showConfirmNewPassword: !this.state.showConfirmNewPassword })
  }

  render() {
    return (
      <div>
        {this.state.notMatchingPasswords === true && (
          <div className="alert alert-danger" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>ERROR: Contraseñas no conciden. Verificar contraseñas ingresadas.</strong>
          </div>
        )}
        {this.state.invalidToken === true && (
          <div className="alert alert-danger" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>ERROR: Token invalido o expirado.</strong>
          </div>
        )}
        {this.state.failedRequest === true && (
          <div className="alert alert-danger" role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>ERROR: Hubo un error en la solicitud. Vuelva a intentarlo.</strong>
          </div>
        )}
        <div className="login-container">
          <div className="login-section">
            <img alt="UBIQUA" src="/ubiqua-logo-top.png" />
            <h2 className="login-header">Ingresar contraseña nueva:</h2>
            <h2 className="login-header">Mínimo 8 caracteres:</h2>
            <form onSubmit={this.sendResetRequest.bind(this)}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="login-input">
                  <input
                    onChange={this.handleChange.bind(this)}
                    className="form-control"
                    type={this.state.showNewPassword ? 'text' : 'password'}
                    minLength="8"
                    name="newPassword"
                    placeholder="Contraseña"
                    required
                  />
                  {this.state.showNewPassword === false && (
                    <span onClick={this.toggleEyeForNewPassword.bind(this)} className="icon-eye">
                      <FontAwesomeIcon className="icon-eye" icon={faEye} />
                    </span>
                  )}
                  {this.state.showNewPassword === true && (
                    <span onClick={this.toggleEyeForNewPassword.bind(this)} className="icon-eye">
                      <FontAwesomeIcon className="icon-eye" icon={faEyeSlash} />
                    </span>
                  )}
                </div>
                <div className="login-input">
                  <input
                    onChange={this.handleChange.bind(this)}
                    className="form-control"
                    type={this.state.showConfirmNewPassword ? 'text' : 'password'}
                    minLength="8"
                    name="confirmNewPassword"
                    placeholder="Confirmar Contraseña"
                    required
                  />
                  {this.state.showConfirmNewPassword === false && (
                    <span
                      onClick={this.toggleEyeForConfirmNewPassword.bind(this)}
                      className="icon-eye"
                    >
                      <FontAwesomeIcon className="icon-eye" icon={faEye} />
                    </span>
                  )}
                  {this.state.showConfirmNewPassword === true && (
                    <span
                      onClick={this.toggleEyeForConfirmNewPassword.bind(this)}
                      className="icon-eye"
                    >
                      <FontAwesomeIcon className="icon-eye" icon={faEyeSlash} />
                    </span>
                  )}
                </div>
                <div className="form-check">
                  <input className="login-button" value="Cambiar contraseña" type="submit" />
                </div>
                <br />
                <br />
                <a className="change-password-link" href="/">
                  Iniciar Sesión
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

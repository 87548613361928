import React from 'react'
import ClientFormTextQuestion from './components/ClientFormTextQuestion'
import ClientFormListQuestion from './components/ClientFormListQuestion'

const ClientApplicationDetails = ({ data }) => {
  const updateForm = () => {
    window.location = '/crear_solicitud_cliente_nuevo?update=true'
  }

  const renderFormComponent = (type, data, index) => {
    switch (type) {
      case 'text':
      case 'number':
      case 'image':
      case 'geolocation':
        return (
          <div>
            <ClientFormTextQuestion
              data={data}
              isShowingFormDetails={true}
              questionNumber={index + 2}
              questionType={type}
            />
          </div>
        )
      case 'multiplechk':
      case 'multiplerb':
        return (
          <div>
            <ClientFormListQuestion
              data={data}
              isShowingFormDetails={true}
              questionNumber={index + 2}
              questionType={type}
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="container-fluid">
      <div className="d-flex align-flex-center justify-flex-between">
        <p className="details-title">Detalles de solicitud de cliente nuevo:</p>
        <div>
          <button
            type="submit"
            onClick={updateForm}
            className="ubiqua-primary-btn"
            style={{ width: 208.15 }}
          >
            Editar solicitud
          </button>
        </div>
      </div>
      <div>
        <hr />
        <div>
          <div className={`survey-text-task-container-details`}>
            <div className={`form-group-details blue-border-input-details`}>
              <div className="question-container">
                <div className="question-number">
                  <b>1.</b>
                </div>
                <textarea
                  className={`question-input-details form-control-details responsive-textarea`}
                  name="question"
                  value={'Nombre del cliente'}
                  disabled
                  style={{
                    height: 'auto',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    resize: 'none'
                  }}
                  rows={1}
                />
              </div>
            </div>
            <div className={`response-details response-details-border response-surveys-details`}>
              <input
                disabled
                type="text"
                className={`response-surveys-details form-control-details`}
                placeholder="Respuesta de texto "
              />
            </div>
          </div>
          {data[0].client_form.map((form, index) => (
            <div key={index}>{renderFormComponent(form.type, form, index)}</div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default ClientApplicationDetails

import React from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowOrderView from '../order/ShowOrderView'
import cookie from 'react-cookies'
import { DispatchConsumer, DispatchProvider } from './context/OrderContext'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ShowOrderApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchPlaceholder: 'Buscar pedidos por código o nombre de cliente',
      id: window.location.href.split('/')[window.location.href.split('/').length - 1],
      isFetching: true,
      query: '',
      details: null,
      defaultOrderUuid: null,
      defaultOrderDetails: null,
      collapsed: false, 

    }
  }

  componentDidMount() {
    this.fetchTransactionDetails()
    const collapsed = localStorage.getItem('collapsedState') === 'true';
    this.setState({ collapsed });
  }

  fetchTransactionDetails = async () => {
    this.setState({ isFetching: true })
    let response = await fetch(BACKEND_URL + '/transactions/' + this.props.match.params.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })

    if (response.status === 401) {
      window.location = '/'
    }
    if (response.status === 404) {
      this.setState({
        details: { deliveries: [], invoices: [], orders: [] },
        defaultOrderUuid: this.props.match.params.id
      })
      this.fetchDefaultOrderDetails()
    } else {
      let data = await response.json()

      this.setState(
        { details: data, defaultOrderUuid: data['orders'][0] },
        this.fetchDefaultOrderDetails
      )
    }
  }

  fetchDefaultOrderDetails = () => {
    fetch(BACKEND_URL + '/orders/' + this.state.defaultOrderUuid, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        if (response.status === 404) {
          return {
            error: true,
            message: 'No se encontró el pedido'
          }
        }
        return response.json()
      })
      .then((data) => {
        if (data.error) {
          this.setState({
            error: data.message,
            details: { ...this.state.details },
            isFetching: false
          })
        } else {
          this.setState({
            details: { ...this.state.details, status: data?.status },
            defaultOrderDetails: data,
            isFetching: false
          })
        }
      })
      .catch((e) => console.log(e))
  }

  handleSearch = (event, isEditing) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        if (isEditing) {
          const val = window.confirm(
            'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
          )
          if (val === true) {
            // redirecting to index accroding to clicked
            window.location = '/pedidos?query=' + query
          }
        } else {
          window.location = '/pedidos?query=' + query
        }
        event.target.value = ''
      }
    }
  }

  handleToggleCollapse = (collapsed) => {
    this.setState({ collapsed }, () => {
      localStorage.setItem('collapsedState', collapsed);
    });
  };

  render() {
    return (
      <div>
        {this.state.isFetching === false ? (
          <div>
            <DispatchProvider
              componentState={this.props.match.params}
              details={this.state.details}
              orderDetails={this.state?.orderDetails}
              defaultOrderDetails={this.state?.defaultOrderDetails}
            >
              <DispatchConsumer>
                {({ isEditing }) => (
                  <div>
                    <div>
                      <div onKeyDown={(e) => this.handleSearch(e, isEditing)}>
                        <TopBar
                          searchPlaceholder={this.state.searchPlaceholder}
                          userName={this.state.userName}
                          onToggleCollapse={this.handleToggleCollapse} 
                          collapsed={this.state.collapsed} 
                        />
                      </div>
                      <div>
                        {this.state.error ? (
        <div className={`main-view ${this.state.collapsed ? 'collapsed' : ''}`}>
        <div className="main-show-header">
                              <p>{this.state.error}</p>
                            </div>
                          </div>
                        ) : (
                          <ShowOrderView
                            details={this.state.details}
                            position={this.props.match.params.id}
                            defaultOrderDetails={this.state.defaultOrderDetails}
                            collapsed={this.state.collapsed}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <NavBar activeItem="Pedidos" isEditing={isEditing} collapsed={this.state.collapsed}/>
                    </div>
                  </div>
                )}
              </DispatchConsumer>
            </DispatchProvider>
          </div>
        ) : (
          <div>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
              onToggleCollapse={this.handleToggleCollapse} 
              collapsed={this.state.collapsed} 
            />
        <div className={`main-view ${this.state.collapsed ? 'collapsed' : ''}`}>
              <div className="show-title">
                <p>Obteniendo datos ....</p>
              </div>
            </div>
            <div>
              <NavBar activeItem="Pedidos" isEditing={false} collapsed={this.state.collapsed}/>
            </div>
          </div>
        )}
      </div>
    )
  }
}

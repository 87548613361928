import React from 'react'
import { STATUS_DIC, TASK_TYPE_DIC, PROD_TYPE_DIC } from '../constants/constants'
import { capitalizeFirstLetterFrom } from '../../helpers/utils'
import cookie from 'react-cookies'
import ImageModal from '../../components/image_modal/ImageModal'
import PosDetailsSurvey from './PosDetailsSurvey'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class PosDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activity: this.props.activity || [],
      suspending: false,
      showImage: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ activity: nextProps.activity })
  }

  getFormattedDateOnly = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleDateString(undefined, { timeZone: 'utc' })
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  getClients = (clientNames) => {
    if (clientNames?.length > 0) return clientNames.join(', ')
    return 'No se encontraron clientes'
  }

  getQualifiers = (qualifiers) => {
    let text = '-'
    if (qualifiers?.length > 0) text = `${qualifiers.join(', ')} `
    return text
  }

  handleShowImage = (value) => {
    this.setState({ showImage: value })
  }

  suspendTask = () => {
    this.setState({ suspending: true })
    let letsGo = confirm('¿Está seguro de que desea continuar con la suspensión de esta tarea?')
    if (letsGo) {
      fetch(BACKEND_URL + '/pos_tasks/' + this.state.activity['uuid'], {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_uid: this.state.activity['business_uid'],
          suspended: true,
          uuid: this.state.activity['uuid']
        })
      })
        .then((response) => {
          if (response.status === 401) {
            window.location = '/'
          }
          if (response.status === 404) {
            return {
              error: true,
              message: 'No se encontró la tarea'
            }
          }
          return response.json()
        })
        .then((data) => {
          //Elimina del url success=true por lo que permite desaparecer el mensaje Exitoso 
          if (data['suspended']) {
            const urlParams = new URLSearchParams(window.location.search)
            const successParam = urlParams.get('success')
            if (successParam === 'true') {
              const newURL = window.location.href.replace('?success=true', '')
              window.history.replaceState({}, document.title, newURL)
              window.location.reload()
            }
            window.location.reload()
          }
        })
        .catch((e) => console.log(e))
    }
    this.setState({ suspending: false })
  }

  cloneTask = () => {
    let letsGo = confirm('¿Está seguro de crear una copia a partir de esta tarea?')
    if (letsGo) window.location = '/crear_tarea_punto_venta?clone=' + this.state.activity['uuid']
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          {this.props.transactionUuid && (
            <>
              <div className="d-flex align-flex-center justify-flex-between">
                <p className="details-title">Detalles de tarea en punto de venta:</p>
                <div>
                  {!this.state.activity['suspended'] && (
                    <button
                      disabled={this.state.suspending}
                      onClick={this.suspendTask}
                      type="submit"
                      className="ubiqua-red-btn-outline mr-10"
                    >
                      Suspender tarea
                    </button>
                  )}
                  <button
                    disabled={this.state.suspending}
                    type="submit"
                    onClick={this.cloneTask}
                    className="ubiqua-primary-btn"
                  >
                    Crear copia de tarea
                  </button>
                </div>
              </div>
            </>
          )}
          <div>
            <hr />
            <div>
              <table cellSpacing="10">
                <tbody>
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Fecha de la tarea:</b>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <div className="mr-50 mb-10">
                          Inicio: <br />
                          {this.getFormattedDateOnly(this.state.activity['start_date'])}
                        </div>
                        <div className="mb-20">
                          Final: <br />
                          {this.state.activity['end_date']
                            ? this.getFormattedDateOnly(this.state.activity['end_date'])
                            : 'Indefinida'}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Código de la tarea:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{this.state.activity['short_uuid']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Tipo de tarea:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {TASK_TYPE_DIC[this.state.activity['task_type']] ||
                          this.state.activity['task_type']}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Descripción de la tarea:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{this.state.activity['description']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Cantidad de subtareas:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">{this.state.activity['task_quantity'] || 0}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Cliente o Atributo(s):</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {this.state.activity['qualifier_1'].length > 0 && (
                          <span>Cliente individual</span>
                        )}
                        {(!this.state.activity['qualifier_1'] ||
                          this.state.activity['qualifier_1'].length === 0) && (
                          <span>Atributo(s)</span>
                        )}
                      </div>
                    </td>
                  </tr>
                  {this.state.activity['qualifier_1'].length > 0 && (
                    <tr>
                      <td>
                        <div className="mr-20 mb-20">
                          <b>Nombre de cliente(s):</b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-20">
                          {this.getClients(this.state.activity['client_names'])}
                        </div>
                      </td>
                    </tr>
                  )}
                  {this.state.activity['qualifier_2'].length > 0 && (
                    <tr>
                      <td>
                        <div className="mr-20 mb-20">
                          <b>Atributo A:</b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-20">
                          {this.getQualifiers(this.state.activity['qualifier_2'])}
                        </div>
                      </td>
                    </tr>
                  )}
                  {this.state.activity['qualifier_3'].length > 0 && (
                    <tr>
                      <td>
                        <div className="mr-20 mb-20">
                          <b>Atributo B:</b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-20">
                          {this.getQualifiers(this.state.activity['qualifier_3'])}
                        </div>
                      </td>
                    </tr>
                  )}
                  {this.state.activity['qualifier_4'].length > 0 && (
                    <tr>
                      <td>
                        <div className="mr-20 mb-20">
                          <b>Atributo C:</b>
                        </div>
                      </td>
                      <td>
                        <div className="mb-20">
                          {this.getQualifiers(this.state.activity['qualifier_4'])}
                        </div>
                      </td>
                    </tr>
                  )}
                  {this.state.activity['task_type'] !== 'survey' &&
                    this.state.activity['task_type'] !== 'price_capture' && (
                      <>
                        <tr>
                          <td>
                            <div className="mr-20 mb-20">
                              <b>Tipo de productos:</b>
                            </div>
                          </td>
                          <td>
                            <div className="mb-20">
                              {PROD_TYPE_DIC[this.state.activity['products_selection_type']] ||
                                (this.state.activity['products_selection_type']
                                  ? this.state.activity['products_selection_type']
                                  : 'No se han agregado productos')}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="mr-20 mb-20">
                              <b>Producto(s) de la tarea:</b>
                            </div>
                          </td>
                          <td>
                            <div className="mb-20">
                              {this.state.activity['products_selection_type'] == 'brand' &&
                                'Marca ' + this.state.activity['brand_name']}
                              {this.state.activity['products_selection_type'] == 'category' &&
                                'Categoría ' + this.state.activity['category_name']}
                              {this.state.activity['products_selection_type'] ==
                                'individual_products' &&
                                this.state.activity['product_names'].join(', ')}
                              {this.state.activity['products_selection_type'] == '' && '-'}
                            </div>
                          </td>
                        </tr>
                      </>
                    )}

                  {this.state.activity['task_type'] == 'price_capture' && (
                    <>
                      <tr>
                        <td>
                          <div className="mr-20 mb-20">
                            <b>Producto(s) propio(s):</b>
                          </div>
                        </td>
                        <td>
                          <div className="mb-20">
                            {this.state.activity.product_names.length > 0
                              ? this.state.activity.product_names
                                  .map((product) => product.replace(/^(\w+)/, '$1 -'))
                                  .join(', ')
                              : 'N/A'}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="mr-20 mb-20">
                            <b>Producto(s) competencia:</b>
                          </div>
                        </td>
                        <td>
                          <div className="mb-20">
                            {this.state.activity.competition_products.length > 0
                              ? this.state.activity.competition_products
                                  .map(
                                    (product) =>
                                      `${product.name.toUpperCase()} - ${product.package.toUpperCase()}`
                                  )
                                  .join(', ')
                              : 'N/A'}
                          </div>
                        </td>
                      </tr>
                    </>
                  )}

                  {this.state.activity['task_type'] == 'planimetry' && (
                    <>
                      <tr>
                        <td>
                          <div className="mr-20 mb-20">
                            <b>Imagen:</b>
                          </div>
                        </td>
                        <td>
                          <div className="mb-20">
                            {this.state.activity['image_url'] ? (
                              <button
                                type="button"
                                className="img-view-buttom"
                                style={{ margin: 0, padding: 0 }}
                                onClick={() => this.handleShowImage(true)}
                              >
                                {' '}
                                Ver imagen
                              </button>
                            ) : (
                              'No se ha agregado ninguna imagen'
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="vertical-align-top">
                          <div className="mr-20 mb-20">
                            <b>Instrucciones:</b>
                          </div>
                        </td>
                        <td>
                          <div className="mb-20" style={{ whiteSpace: 'pre-line' }}>
                            {this.state.activity['instructions']
                              ? this.state.activity['instructions']
                              : 'No se han agregado instrucciones'}
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Recurrencia:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {capitalizeFirstLetterFrom(this.state.activity['recurrence_description'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Estado de la tarea:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {STATUS_DIC[this.state.activity['status']] || this.state.activity['status']}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Fecha de creación:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {this.getFormattedDate(this.state.activity['created_at'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-20">
                        <b>Fecha de actualización:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-20">
                        {this.getFormattedDate(this.state.activity['updated_at'])}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.state.activity['task_type'] == 'survey' && (
                <div>
                  <PosDetailsSurvey data={this.state.activity.surveys} />
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.activity['task_type'] == 'planimetry' && this.state.activity['image_url'] && (
          <div>
            <ImageModal
              imgSrc={this.state.activity['image_url']}
              showImage={this.state.showImage}
              setShowImage={this.handleShowImage}
            />
          </div>
        )}
      </div>
    )
  }
}

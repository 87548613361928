import React, { createContext, useState, useEffect } from 'react'
import cookie from 'react-cookies'

const { Provider, Consumer } = createContext()

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

function DispatchProvider(props) {
  const [isEditing, setIsEditing] = useState(false)

  const [loading, setLoading] = useState(true)
  const [isDispatchState, setIsDispatchState] = useState(false)
  const [isDispatch, setIsDispatch] = useState(true)

  const [dispatchDetailState, setDispatchDetailState] = useState(true)

  const [activeTab, setActiveTab] = useState('Pedido')

  const [componentState] = useState(props.componentState)
  const [transactionDetails] = useState(props.details)
  const [orderDetails, setOrderDetails] = useState(props.defaultOrderDetails)
  const [deliveryDetails, setDeliveryDetails] = useState(null)
  const [invoiceDetails, setInvoiceDetails] = useState(null)
  const [invoiceAppDetails, setInvoiceAppDetails] = useState(null)
  const [client90KOrders, setClient90KOrders] = useState([])
  const [client90KDeliveries, setClient90KDeliveries] = useState([])
  const [client90KInvoices, setClient90KInvoices] = useState([])

  useEffect(() => {
    if (
      componentState.state === 'update' ||
      componentState.state === 'create' ||
      componentState.state === 'cancel'
    ) {
      setActiveTab('Despacho')
    }
    async function fetchData() {
      await fetchDeliveryDetails()
      await fetchInvoiceDetails()
      await fetchInvoiceAppDetails()
      await getClient90KOrders()
    }

    setLoading(false)
    fetchData()
  }, [])

  const fetchDeliveryDetails = () => {
    if (transactionDetails['deliveries'].length > 0) {
      fetch(BACKEND_URL + '/deliveries/' + transactionDetails['deliveries'][0], {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        })
        .then((data) => {
          setDeliveryDetails(data)
          setIsDispatch(true)
          getClient90KDeliveries(data)
        })
        .catch((e) => console.log(e))
    } else {
      setIsDispatch(false)
    }
  }

  const getClient90KDeliveries = (data) => {
    const items = []
    if (transactionDetails['deliveries'].length > 0) {
      items.push(data)
      transactionDetails['deliveries'].slice(1).map((deliveryUuid) =>
        fetch(BACKEND_URL + '/deliveries/' + deliveryUuid, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        })
          .then((response) => {
            if (response.status === 401) {
              window.location = '/'
            }
            return response.json()
          })
          .then((data) => items.push(data))
      )
    }
    setClient90KDeliveries(items)
  }

  const getClient90KOrders = () => {
    const items = []
    if (transactionDetails['orders'].length > 0) {
      items.push(orderDetails)
      transactionDetails['orders'].slice(1).map((orderUUID) =>
        fetch(BACKEND_URL + '/orders/' + orderUUID, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        })
          .then((response) => {
            if (response.status === 401) {
              window.location = '/'
            }
            return response.json()
          })
          .then((data) => items.push(data))
      )
    }
    setClient90KOrders(items)
  }

  const fetchInvoiceDetails = () => {
    if (transactionDetails['invoices'].length > 0) {
      fetch(BACKEND_URL + '/invoices/' + transactionDetails['invoices'][0], {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        })
        .then((data) => {
          setInvoiceDetails(data)
          getClient90KInvoices(data)
        })
        .catch((e) => console.log(e))
    }
  }

  const fetchInvoiceAppDetails = () => {
    if (orderDetails['order_db_ref'].length > 0) {
      fetch(BACKEND_URL + '/invoices_app/?business_id=1&order_id=' + orderDetails['order_db_ref'], {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        })
        .then((data) => {
          setInvoiceAppDetails(data)
        })
        .catch((e) => console.log(e))
    }
  }

  const getClient90KInvoices = (data) => {
    const items = []
    if (transactionDetails['invoices'].length > 0) {
      items.push(data)
      transactionDetails['invoices'].slice(1).map((invoiceUuid) =>
        fetch(BACKEND_URL + '/invoices/' + invoiceUuid, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        })
          .then((response) => {
            if (response.status === 401) {
              window.location = '/'
            }
            return response.json()
          })
          .then((data) => items.push(data))
      )
    }
    setClient90KInvoices(items)
  }

  const cancelCreate = (event) => {
    event.preventDefault()
    const val = window.confirm(
      '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
    )
    if (val) {
      setActiveTab('Pedido')
      setIsDispatchState(false)
      setIsEditing(false)
    }
  }

  const cancelUpdate = (event) => {
    event.preventDefault()
    const val = window.confirm(
      '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
    )
    if (val) {
      setActiveTab('Despacho')
      setDispatchDetailState(true)
      setIsEditing(false)
    }
  }
  // Handle update form state
  const handleUpdateDispatchState = () => {
    setIsEditing(true)
    setDispatchDetailState(false)
  }

  // Handle dispatch form state
  const handleDispatchState = () => {
    setIsEditing(true)
    setIsDispatchState(true)
    setActiveTab('Despacho')
  }
  // in order to change active state for tabs
  const handleTabClick = (event) => {
    // if a dropdown option was clicked from tab (tab is an <a> element)
    if (event.target.tagName === 'A' && event.target.id === 'multiple-tab') {
      const tabName = event.target.innerText.split(' ')[0]
      if (tabName === 'Pedido') {
        setActiveTab(tabName)
        setOrderDetails(client90KOrders[parseInt(event.target.className)])
      } else if (tabName === 'Despacho') {
        setActiveTab(tabName)
        setDeliveryDetails(client90KDeliveries[event.target.className])
      } else if (tabName === 'Factura') {
        setActiveTab(tabName)
        setInvoiceDetails(client90KInvoices[event.target.className])
      }
      // if a tab was clicked (no dropdown) (tab is an <a> element)
    } else if (event.target.tagName === 'A' && event.target.id !== 'multiple-tab') {
      if (event.target.innerText === 'Pedido' && isDispatchState === true) {
        const val = window.confirm(
          '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
        )
        if (val) {
          setIsEditing(false)
          setActiveTab(event.target.innerText)
          setIsDispatchState(false)
        }
      } else if (event.target.innerText === 'Pedido' && dispatchDetailState === false) {
        const val = window.confirm(
          '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
        )
        if (val) {
          setActiveTab(event.target.innerText)
          setIsEditing(false)
          setDispatchDetailState(true)
        }
      } else {
        setActiveTab(event.target.innerText)
      }
    }
  }

  return (
    <Provider
      value={{
        loading,
        isDispatch,
        isDispatchState,
        activeTab,
        transactionDetails,
        orderDetails,
        deliveryDetails,
        invoiceDetails,
        invoiceAppDetails,
        client90KOrders,
        client90KInvoices,
        client90KDeliveries,
        dispatchDetailState,
        isEditing: isEditing,
        componentState: componentState,
        handleDispatchState: handleDispatchState,
        handleUpdateDispatchState: handleUpdateDispatchState,
        handleTabClick: handleTabClick,
        cancelCreate: cancelCreate,
        cancelUpdate: cancelUpdate
      }}
    >
      {props.children}
    </Provider>
  )
}

export { DispatchProvider, Consumer as DispatchConsumer }

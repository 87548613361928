import React, { useContext, useState, useEffect } from 'react'
import { UserInfoContext } from '../context/UserInfoProvider'
import { getFormattedDate } from '../services/apiUserService'
import ResetPasswordModal from '../component/modal/ResetPasswordModal'
import Select from 'react-select'
import { validateAccessRole } from '../../helpers/userRole'
import cookie from 'react-cookies'
import DeactivateUserModal from '../component/modal/DeactivateUserModal'
import ConfirmRoleRemovalModal from '../component/modal/ConfirmRoleRemovalModal'

//Vista de tab "Detalles" de Detalles de Usuario en Usuario


const UserInfoDetailTab = (props) => {
  const {
    userInfoData,
    isEditingUserInfoTab,
    isFetching,
    handleUpdateUser,
    handleSubmit,
    errors,
    register,
    onSubmit,
    Controller,
    control,
    featureRoles,
    getRoleLabel
  } = useContext(UserInfoContext);

  const [showModal, setShowModal] = useState(false);
  const [showModalUsuario, setShowModalUsuario] = useState(false);
  const [showModalRole, setShowModalRole] = useState(false);
  const [activeStatus, setActiveStatus] = useState(userInfoData.active);
  const [confirmedDeactivate, setConfirmedDeactivate] = useState(false);
  const [currentRoles, setCurrentRoles] = useState(userInfoData.roles?.map(role => role.feature_role_key) || []);
  const [confirmedRoleRemoval, setConfirmedRoleRemoval] = useState(false);

  useEffect(() => {
    setActiveStatus(userInfoData.active);
    setCurrentRoles(userInfoData.roles?.map(role => role.feature_role_key) || []);
  }, [userInfoData.active, userInfoData.roles]);

  const showResetPasswordModal = () => {
    setShowModal(true);
  };

  const closeMainModal = () => {
    setShowModal(false);
  };

  const showDeactivateUserModal = () => {
    setShowModalUsuario(true);
  };

  const closeDeactivateUserModal = (confirmed) => {
    if (confirmed) {
      setConfirmedDeactivate(true);
      setActiveStatus(false);
    }
    setShowModalUsuario(false);
  };

  const showRoleRemovalModal = () => {
    setShowModalRole(true);
  };

  const closeRoleRemovalModal = (confirmed) => {
    if (confirmed) {
      setConfirmedRoleRemoval(true);
    }
    setShowModalRole(false);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value === 'true';
    setActiveStatus(value);
  };

  const handleRoleChange = (selectedOptions) => {
    setCurrentRoles(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleSubmitForm = (data) => {
    const rolesRemoved = userInfoData.roles?.some(role => !currentRoles.includes(role.feature_role_key));
    if (userInfoData.active && !activeStatus && !confirmedDeactivate) {
      showDeactivateUserModal();
    } else if (rolesRemoved && !confirmedRoleRemoval && !(userInfoData.active && !activeStatus)) {
      showRoleRemovalModal();
    } else {
      setConfirmedDeactivate(false);
      setConfirmedRoleRemoval(false);
      onSubmit(data);
    }
  };

  return (
    <>
      <div>
        {showModal && (
          <ResetPasswordModal
            user={userInfoData}
            showModal={true}
            closeMainModal={closeMainModal}
          />
        )}
        {showModalUsuario && (
          <DeactivateUserModal
            user={userInfoData}
            showModal={true}
            closeModal={closeDeactivateUserModal}
          />
        )}
        {showModalRole && (
          <ConfirmRoleRemovalModal
            showModal={true}
            closeModal={closeRoleRemovalModal}
          />
        )}
        {isEditingUserInfoTab === false && (
          <div className="show-area-tab">
            <div className="show-header">
              <h2>Detalles de Usuario:</h2>
              <div className="show-buttons-responsive">
                {validateAccessRole(cookie.load('user_role')?.can_config_user)?.can_write && (
                  <>
                    <button onClick={showResetPasswordModal} className="shadow-cancel-button">
                      Cambiar contraseña
                    </button>
                    <input type="submit" onClick={handleUpdateUser} value="Actualizar detalles" />
                  </>
                )}
              </div>
            </div>
            <hr className="mt-70" />
            <div>
              <div className="show-data">
                <div>Activo</div>
                <div>{userInfoData.active ? 'Sí' : 'No'}</div>
              </div>
              <div className="show-data">
                <div>Nombre de usuario:</div>
                <div>{userInfoData.full_name}</div>
              </div>
              <div className="show-data">
                <div>Rol de usuario:</div>
                <div>
                  {userInfoData.roles?.map((e) => getRoleLabel(e.feature_role_key)).join(', ')}
                </div>
              </div>
              <div className="show-data">
                <div>Número de rutero/Código:</div>
                <div>{userInfoData.route_db_ref}</div>
              </div>
              <div className="show-data">
                <div>Correo Electrónico:</div>
                <div>{userInfoData.email}</div>
              </div>
              <div className="show-data">
                <div>Celular (opcional):</div>
                <div>{userInfoData.mobile}</div>
              </div>
              <div className="show-data">
                <div>Creado</div>
                <div>{getFormattedDate(userInfoData.created_at)}</div>
              </div>
              <div className="show-data">
                <div>Actualizado</div>
                <div>{getFormattedDate(userInfoData.updated_at)}</div>
              </div>
            </div>
          </div>
        )}
        {isEditingUserInfoTab === true && (
          <div className="">
            <div className="show-area-tab">
              <div className="">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  <div className="inline-block full-width">
                    <div className="float-left">
                      <h4>Detalles de Usuario:</h4>            
                    </div>
                    <div className="float-right">
                      <button
                        className="shadow-cancel-button"
                        value="Cancelar"
                        onClick={handleUpdateUser}
                      >
                        {' '}
                        Cancelar
                      </button>
                      <input className="save-button" value="Guardar" type="submit" />
                    </div>
                  </div>
                  <hr />
                  <p className='mb-30 mt-30' style={{ color: 'red' }}>Campos obligatorios*</p>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Activo:</label>
                    <div className="col-sm-8">
                      <select
                        name="active"
                        {...register('active')}
                        className={`form-control ${errors.active ? 'is-invalid' : ''}`}
                        value={activeStatus}
                        onChange={handleStatusChange}
                      >
                        <option value="true">Sí</option>
                        <option value="false">No</option>
                      </select>
                      <div className="invalid-feedback">{errors.active?.message}</div>
                    </div>
                  </div>

                  <div className="form-group row show-data-form">
                    <label htmlFor="inputName" className="col-sm-3 col-form-label">
                      Nombre de usuario: <span className="red">*</span>
                    </label>
                    <div className="col-sm-8">
                      <input
                        name="name"
                        type="text"
                        {...register('name')}
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                      />
                      <div className="invalid-feedback">{errors.name?.message}</div>
                    </div>
                  </div>

                  <div className="form-group row show-data-form">
                    <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                      Rol de usuario: <span className="red">*</span>
                    </label>
                    <div className="col-sm-8">
                      <Controller
                        control={control}
                        defaultValue={userInfoData.roles?.map((e) => e.feature_role_key)}
                        name="role"
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            placeholder={'Buscar o asignar multiples roles'}
                            noOptionsMessage={() => 'Buscar'}
                            loadingMessage={() => 'Cargando...'}
                            isDisabled={false}
                            isClearable={true}
                            inputRef={ref}
                            value={featureRoles?.filter((c) => value.includes(c.value))}
                            onChange={(val) => {
                              handleRoleChange(val);
                              onChange(val.map((c) => c.value));
                            }}
                            options={featureRoles}
                            isMulti
                          />
                        )}
                      />
                      <div className="invalid-feedback">{errors.role?.message}</div>
                    </div>
                  </div>

                  <div className="form-group row show-data-form">
                    <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                      Número de rutero/Código: <span className="red">*</span>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        id="inputCode"
                        className="form-control"
                        value={userInfoData.route_db_ref}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group row show-data-form">
                    <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                      Correo electrónico: <span className="red">*</span>
                    </label>
                    <div className="col-sm-8">
                      <input
                        name="email"
                        type="text"
                        {...register('email')}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      />
                      <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="inputCode" className="col-sm-3 col-form-label">
                      Celular (opcional)
                    </label>
                    <div className="col-sm-8">
                      <input
                        name="mobile"
                        type="number"
                        {...register('mobile')}
                        className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                      />
                      <div className="invalid-feedback">{errors.mobile?.message}</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {isFetching && (
          <div className="index-table">
            <div className="show-area-tab">
              <p style={{ textAlign: 'center' }}>Obteniendo datos ...</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserInfoDetailTab;
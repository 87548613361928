import React, { useEffect, useState } from 'react'
import TopBar from '../../../layout/TopBar'
import NavBar from '../../../layout/NavBar'
import ShowUserChargeReportVisitsView from './ShowUserChargeReportVisitsView'
import UserChargeReportPagination from './UserChargeReportPagination'
import cookie from 'react-cookies'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import {
  CHARGES,
  CANCELLED_CHARGES,
  STATUS_CANCELLED,
  STATUS_SEND
} from '../../../constants/chargeConstants'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const PaginationUserChargeReportData = React.createContext()
export const PaginationUserChargeConsumer = PaginationUserChargeReportData.Consumer

const ShowUserChargeReportVisitsApp = () => {
  const splitDbRef = window.location.href.split('db_ref=')
  const getDbRef = () => splitDbRef[splitDbRef.length - 1].split('&')[0]

  const getStartDate = () => {
    if (window.location.href.indexOf('start_date') >= 0) {
      const startDate = window.location.href.split('start_date=')
      return startDate[startDate.length - 1].split('&')[0]
    } else {
      return getCurrentDate()
    }
  }

  const getEndDate = () => {
    if (window.location.href.indexOf('end_date') >= 0) {
      const endDay = window.location.href.split('end_date=')
      return endDay[endDay.length - 1].split('&')[0]
    } else {
      return getCurrentDate()
    }
  }

  const getCurrentDate = () => new Date().getFullYear() + '-' + getMonth() + '-' + getDay()

  const getMonth = () => {
    const m = new Date().getMonth() + 1
    if (m < 10) {
      return '0' + m
    } else {
      return m
    }
  }

  const getDay = () => {
    const d = new Date().getDate()
    if (d < 10) {
      return '0' + d
    } else {
      return d
    }
  }

  const getCurrentPage = (page) => {
    //myRef.current.scrollTo(0, 0);
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    fetchDetailsFromServer(page)
  }

  const [dbRef] = useState(getDbRef())
  const [totalCount, setTotalCount] = useState(0)
  const per_page = 40
  const [isFetching, setIsFetching] = useState(true)
  const [paginationParams] = useState({
    initLoad: [],
    getCurrentPage: getCurrentPage,
    totalCount: 0
  })
  const [data, setData] = useState([])
  const [salesmanName, setSalesmanName] = useState('')

  const createText = 'Descargar reporte'
  const headers = [
    'Hora',
    'Código',
    'Cliente',
    'Actividad',
    'Total Cobrado',
    'Ubicación Lat.',
    'Ubicación Long.'
  ]
  const [startDate, setStartDate] = useState(getStartDate())
  const [endDate, setEndDate] = useState(getEndDate())
  const [invalidRangeLimit, setInvalidRangeLimit] = useState(false)
  const [invalidRangeFormat, SetInvalidRangeFormat] = useState(false)
  const [downloadData, setDownloadData] = useState([])

  useEffect(() => {
    async function fetchData() {
      await fetchDetailsFromServer()
    }

    fetchData()
  }, [])

  const fetchDownloadData = () => {
    fetch(
      BACKEND_URL +
        '/businesses/1/reports/charge_effectiveness/user?db_ref=' +
        dbRef +
        '&start_date=' +
        startDate +
        '&end_date=' +
        endDate +
        '&business_tenant_uid=' +
        cookie.load('business_tenant_uid') +
        '&page=1' +
        '&per_page=10000',
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setDownloadData(data['details'])
      })
      .catch((e) => console.log(e))
  }

  const fetchDetailsFromServer = (page = 1) => {
    setIsFetching(true)
    fetch(
      BACKEND_URL +
        '/businesses/1/reports/charge_effectiveness/user?db_ref=' +
        dbRef +
        '&start_date=' +
        startDate +
        '&end_date=' +
        endDate +
        '&business_tenant_uid=' +
        cookie.load('business_tenant_uid') +
        '&page=' +
        page +
        '&per_page=' +
        per_page,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        if (data['details'].length !== 0) {
          setTotalCount(data['details'][0]['total_count'])
          setData(data['details'])
          setSalesmanName(data['salesman_name'])
          fetchDownloadData()
        }
        setIsFetching(false)
        //setTotalPage((setTotalPage(data)));
      })
      .catch((e) => console.log(e))
  }

  const formatActivityName = (activity_name, motive_text, status) => {
    if (activity_name === CHARGES && status === STATUS_CANCELLED) {
      return CANCELLED_CHARGES
    } else if (activity_name === CHARGES && status === STATUS_SEND) {
      return 'COBRO'
    } else if (activity_name === CHARGES && status === null) {
      return activity_name
    } else {
      return activity_name + ': ' + motive_text
    }
  }

  const formatTotal = (total, status) => (status === STATUS_CANCELLED ? '-' + total : total)

  const buildData = () => {
    const array = downloadData.map((a) => [
      a.created_date,
      a.client_db_ref,
      a.client_name,
      formatActivityName(a.activity_name, a.motive_text, a.status),
      formatTotal(a.total, a.status),
      a.latitude,
      a.longitude
    ])
    array.splice(0, 0, headers)
    array.splice(0, 0, [])
    array.splice(0, 0, ['Fecha', startDate + ' - ' + endDate])
    array.splice(0, 0, ['Cobrador', salesmanName])
    array.splice(0, 0, ['Supervisor', cookie.load('user_name')])
    array.splice(0, 0, ['Reporte:', 'Efectividad de Cobro'])
    return array
  }

  const searchFromDates = (event) => {
    const start_date = new Date(startDate)
    const end_date = new Date(endDate)
    const diff_in_time = end_date.getTime() - start_date.getTime()
    // To calculate the no. of days between two dates
    const diff_in_days = diff_in_time / (1000 * 3600 * 24)
    // Validations
    if (start_date > end_date) {
      SetInvalidRangeFormat(true)
      setInvalidRangeLimit(false)
    } else if (diff_in_days > 90) {
      SetInvalidRangeFormat(false)
      setInvalidRangeLimit(true)
    } else {
      SetInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      window.location.href =
        '/visitas_de_cobro_por_usuario?db_ref=' +
        dbRef +
        '&start_date=' +
        startDate +
        '&end_date=' +
        endDate
    }
  }

  const selectStartDate = (event) => {
    setStartDate(event.target.value)
  }

  const selectEndDate = (event) => {
    setEndDate(event.target.value)
  }

  return (
    <div>
      <div>
        <div>
          <TopBar searchPlaceholder={''} userName={''} />
        </div>
        <div className="main-view">
          {invalidRangeLimit && (
            <div className="alert alert-danger" role="alert">
              <strong>
                ERROR: Rango de fechas escogidas pasan de los 90 días. Por favor escoger otra fecha.
              </strong>
            </div>
          )}
          {invalidRangeFormat && (
            <div className="alert alert-danger" role="alert">
              <strong>ERROR: Fecha de inicio debe ser antes que la fecha de fin.</strong>
            </div>
          )}
          <div className="index-header">
            <h2 className="mb-20 float-unset">Reporte: Efectividad de cobros - {salesmanName}</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div className="range-dates">
                <span style={{ color: '#4D4845', fontSize: '18px', marginRight: '15px' }}>
                  Desde
                </span>
                <input
                  max={getCurrentDate()}
                  onChange={selectStartDate}
                  defaultValue={startDate}
                  className="select-date-report"
                  type="date"
                  name="start_date"
                  required
                />
                <span style={{ color: '#4D4845', fontSize: '18px', marginRight: '15px' }}>
                  {' '}
                  - Hasta
                </span>
                <input
                  max={getCurrentDate()}
                  onChange={selectEndDate}
                  defaultValue={endDate}
                  className="select-date-report"
                  type="date"
                  name="end_date"
                  required
                />
                <button onClick={searchFromDates} className="search-range-dates-btn">
                  Buscar
                </button>
                <CSVLink
                  className="btn btn-default download-report-button"
                  data={buildData()}
                  filename={
                    'ReporteEfectividadCobros_' +
                    cookie.load('business_name') +
                    '_' +
                    salesmanName +
                    '_' +
                    getCurrentDate() +
                    '.csv'
                  }
                >
                  {createText}
                  <FontAwesomeIcon icon={faArrowCircleDown} />
                </CSVLink>
              </div>
              <h5>
                <FontAwesomeIcon icon={faExclamationCircle} />
                Nota: Solo puedes escoger un rango de fechas de un maximo de 90 dias.
              </h5>
              <hr />
              <div className="show-header">
                <div className="col-xs-6">
                  <h2>Detalles de reporte:</h2>
                </div>
              </div>
              <ShowUserChargeReportVisitsView
                data={data}
                salesman={salesmanName}
                isFetching={isFetching}
              />
              <div className="container">
                <div className="row">
                  <div className="center-block" style={{ marginLeft: 70 }}>
                    <PaginationUserChargeReportData.Provider value={paginationParams}>
                      <UserChargeReportPagination totalCount={totalCount} />
                    </PaginationUserChargeReportData.Provider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <NavBar activeItem="Reportes" isEditing={false} />
      </div>
    </div>
  )
}
export default ShowUserChargeReportVisitsApp

import React, { Component } from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import * as keyboardCodes from '../constants/keyboardCodes'
import { DISCARD_CHANGES_CONFIRMATION } from '../constants/appMessages'
import EssentialsAssociationsView from './EssentialsAssociationsView'

export default class EssentialsAssociationsApp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: true,
      searchPlaceholder: '',
      title: 'Cliente nuevo',
      collapsed: false, 
    }
  }

  componentDidMount() {
    this.setState({ isFetching: false })
    const collapsed = localStorage.getItem('collapsedState') === 'true';
    this.setState({ collapsed });
  }

  passEditState = (editStateVal) => {
    this.setState({ isEditing: editStateVal })
  }

  handleSearch = (event) => {
    if (event.keyCode === keyboardCodes.ENTER_KEY && window.confirm(DISCARD_CHANGES_CONFIRMATION)) {
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        window.location = '/clientes?query=' + query
      }
    }
  }

  handleToggleCollapse = (collapsed) => {
    this.setState({ collapsed }, () => {
      localStorage.setItem('collapsedState', collapsed);
    });
  };
  

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
              onToggleCollapse={this.handleToggleCollapse} 
              collapsed={this.state.collapsed} 
            />
          </div>
          <div onClick={this.handlePaginationClick}>
            <EssentialsAssociationsView data={this.state.data} title={this.state.title} collapsed={this.state.collapsed}/>
          </div>
        </div>
        <div>
          <NavBar activeItem="Infaltables" isEditing={false} collapsed={this.state.collapsed} />
        </div>
      </div>
    )
  }
}
import React, { useContext, useEffect, useState } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons'
import { PriceListCreateContext } from '../context/PriceListCreateProvider'
import uuid from 'uuid/v4'
import { TYPES } from '../context/state/Types'
import AsyncSelect from 'react-select/async'
import IconOption from '../../order/component/IconOption'
import { SubmitCreateProductContext } from '../context/SubmitCreateProductProvider'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const CreateProductPriceListStep = () => {
  const { state, dispatch } = useContext(PriceListCreateContext)

  const { showWarningPage2 } = useContext(SubmitCreateProductContext)

  const [data, setData] = useState([])
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    fetchDataToCreate()
  }, [])

  const fetchDataToCreate = () => {
    fetch(BACKEND_URL + '/businesses/1/products/new', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data)
        setFetching(false)
      })
      .catch((e) => console.log(e))
  }

  const addNewPriceListBlock = () => {
    const newPriceList = { price_list_id: '', price_list_name: '', price: 0, id: uuid() }
    dispatch({
      type: TYPES.ADD_PRICE_LIST,
      newPriceList
    })
  }

  const removePriceListBlock = (id) => {
    dispatch({ type: TYPES.DELETE_PRICE_LIST, id })
  }

  const updatePriceListPrice = (event, pr) => {
    const quantityValue = event.target.value
    dispatch({ type: TYPES.CHANGE_PRICE, pr, quantityValue })
  }

  const updatePriceListName = (event, pr) => {
    const selectPriceList = event.priceListObject
    dispatch({ type: TYPES.CHANGE_PRICE_LIST_NAME, pr, selectPriceList })
  }

  const searchForProduct = (inputValue) => {
    const result = data.price_lists.filter(
      (t) =>
        t.description.toLowerCase().includes(inputValue.toLowerCase()) ||
        t.db_ref.toLowerCase().includes(inputValue.toLowerCase())
    )
    return result.map((i) => ({
      value: i.description,
      label: i.db_ref.concat(' ' + i.description),
      priceListObject: i
    }))
  }

  const promiseProductOptions = (inputValue) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchForProduct(inputValue))
      }, 1000)
    })

  const getPriceList = (object, index) => (
    <>
      <div className="col-md-6 mt-16">
        <div>Lista de precio</div>
        <AsyncSelect
          placeholder={'Buscar listas de precios por nombre o código'}
          noOptionsMessage={() => 'Escriba para poder buscar y agregar listas de precios'}
          loadingMessage={() => 'Cargando listas de precios...'}
          cacheOptions
          defaultOptions={false}
          name="selectOption"
          onChange={(e) => updatePriceListName(e, object)}
          components={{ Option: IconOption }}
          loadOptions={promiseProductOptions}
        />
      </div>
      <div className="col-md-6 form-inline mt-16">
        <div>Precio</div>
        <span className="icon-dollar">
          <FontAwesomeIcon icon={faDollarSign} />
        </span>
        <input
          onChange={(e) => {
            updatePriceListPrice(e, object)
          }}
          name="price"
          style={{ textAlign: 'right', marginRight: 5 }}
          className="form-control"
          value={object.price}
          placeholder="0.00"
          type="number"
          step="0.1"
          autoComplete="off"
        />
        {state.priceList.length > 1 && (
          <button
            className="delete-ubq-product-input"
            onClick={(e) => removePriceListBlock(object.id)}
            type="button"
          >
            <FontAwesomeIcon className="delete-price-list-button" icon={faTrash} />
            Borrar lista
          </button>
        )}
      </div>
    </>
  )

  return (
    <>
      <div className="">
        {fetching === false && (
          <div>
            <hr />
            <div className="container-fluid">
              <div>
                <div>
                  <span>
                    <b>Paso 2 de 2: </b>
                  </span>
                  Seleccione la(s) lista(s) de precio que desea asignarle al producto.
                </div>
                {showWarningPage2 && (
                  <div className="red">
                    Por favor llene todos los campos precio y nombre de lista de precio para crear
                    producto.
                  </div>
                )}
              </div>
              {state.priceList.map((object, index) => (
                <div key={index} className="row">
                  {getPriceList(object, index)}
                </div>
              ))}
              <div className="row">
                <div className="col-md-6">
                  <button
                    onClick={addNewPriceListBlock}
                    className="mt-33 float-left add-price-list-button"
                    type="button"
                  >
                    <span className="fs-20">+</span> Agregar lista de precio
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {fetching === true && (
          <div className="container-fluid">
            <p>Obteniendo datos</p>
          </div>
        )}
      </div>
    </>
  )
}

export default CreateProductPriceListStep

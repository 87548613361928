import React from 'react'
import cookie from 'react-cookies'
import * as apiService from '../../services/apiService'
import * as apiStatusCodes from '../../constants/apiStatusCodes'
import validate from '../../helpers/validation'

const { Provider, Consumer } = React.createContext()

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

class DeliveryDetailProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      isEditing: false,
      detailsData: '',
      activeTab: 'Detalles',
      formIsValid: true,
      failedCreationInfo: null,
      id: this.props.id,
      title: null,
      formControls: {
        name: {
          value: '',
          placeholder: '',
          valid: true,
          touched: false,
          validationRules: {
            minLength: 1,
            isRequired: true
          }
        },
        active: {
          value: 'true',
          placeholder: '',
          valid: true,
          touched: false,
          options: [
            { value: true, displayValue: 'Si' },
            { value: false, displayValue: 'No' }
          ]
        },
        code: {
          value: '',
          placeholder: '',
          valid: true,
          touched: false,
          validationRules: {
            minLength: 1,
            isRequired: true
          }
        },
        email: {
          value: '',
          placeholder: '',
          valid: true,
          validationRules: {
            isRequired: true,
            isEmail: true
          },
          touched: false
        },
        phone: {
          value: '',
          placeholder: '',
          valid: true,
          touched: false
        }
      }
    }
  }

  componentDidMount() {
    this.fetchDetailsFromServer()
  }

  changeEditMode = (val) => {
    this.setState({ isEditing: val })
  }

  // in order to change active state for tabs
  handleTabClick = (event) => {
    // if tab was clicked (tab is an <a> element)
    if (event.target.tagName === 'A') {
      if (this.state.isEditing === false) {
        this.setState({ activeTab: event.target.innerText }, this.render)
      } else {
        const val = window.confirm(
          'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
        )
        if (val === true) {
          this.setState({ isEditing: false })
          this.setState({ activeTab: event.target.innerText }, this.render)
        }
      }
    }
  }
  fetchDetailsFromServer = () => {
    this.setState({ isFetching: true })

    apiService
      .getDeliveryDetails(this.state.id)
      .then((response) => apiService.handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        this.setState(({ formControls }) => ({
          formControls: {
            ...formControls,
            active: {
              ...this.state.formControls.active,
              value: data.active
            },
            phone: {
              ...this.state.formControls.phone,
              value: data.mobile ? data.mobile : ''
            },
            email: {
              ...this.state.formControls.email,
              value: data.email ? data.email : ''
            },
            code: {
              ...this.state.formControls.code,
              value: data.db_ref
            },
            name: {
              ...this.state.formControls.name,
              value: data.name
            }
          },
          detailsData: data,
          isFetching: false
        }))
      })
      .catch((e) => {
        console.log(e)
        if (e.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  changeHandler = (event) => {
    const name = event.target.name
    const value = event.target.value

    const updatedControls = {
      ...this.state.formControls
    }
    const updatedFormElement = {
      ...updatedControls[name]
    }

    updatedFormElement.value = value
    updatedFormElement.touched = true
    updatedFormElement.valid = validate(value, updatedFormElement.validationRules)

    updatedControls[name] = updatedFormElement

    let formIsValid = true
    for (const inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid
    }

    this.setState({
      formControls: updatedControls,
      formIsValid: formIsValid
    })
  }

  handleUpdateDeliverer = (event) => {
    if (event.target.value === 'Actualizar detalles') {
      this.setState({ isEditing: true })
    } else if (event.target.value === 'Cancelar') {
      this.setState({ isEditing: false })
    }
  }

  formSubmitUpdate = (event) => {
    event.preventDefault()
    if (this.state.formIsValid) {
      const { formControls } = this.state
      fetch(BACKEND_URL + '/deliveries_users/' + this.state.id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          name: formControls.name.value,
          active: formControls.active.value,
          email: formControls.email.value,
          mobile: formControls.phone.value
        })
      })
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => this.evaluateResponse(obj, false))
        .catch((e) => console.log(e))
    } else {
      this.setState({
        failedCreationInfo: 'Por favor introduzca los campos obligatorios'
      })
    }
  }

  hideFailedCreationMessage = () => {
    this.setState({ failedCreationInfo: null })
  }

  evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        this.setState({
          failedCreationInfo: 'Verifique la informacion ingresada.'
        })
        setTimeout(this.hideFailedCreationMessage, 15000)
        break
      case 409:
        this.setState({
          failedCreationInfo:
            'Error al guardar cambios. El codigo ingresado ya existe. Por favor escoger otro codigo.'
        })
        setTimeout(this.hideFailedCreationMessage, 15000)
        break
      case 404:
        this.setState({
          failedCreationInfo: 'Servidor fuera de servicio'
        })
        setTimeout(this.hideFailedCreationMessage, 15000)
        break
      case 500:
        this.setState({
          failedCreationInfo: 'Error interno en el sistema'
        })
        setTimeout(this.hideFailedCreationMessage, 15000)
        break
      default:
        // eslint-disable-next-line no-case-declarations
        const data = obj.body
        window.location = '/entregadores/' + data['id']
        break
    }
  }

  render() {
    return (
      <Provider
        value={{
          failedCreation: this.state.failedCreationInfo,
          handleUpdateDeliverer: this.handleUpdateDeliverer,
          changeHandler: this.changeHandler,
          formSubmitUpdate: this.formSubmitUpdate,
          changeEditMode: this.changeEditMode,
          formControl: this.state.formControls,
          isEditing: this.state.isEditing,
          isFetching: this.state.isFetching,
          deliverer: this.state.detailsData,
          activeTab: this.state.activeTab,
          id: this.props.id,
          handleTabClick: this.handleTabClick
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export { DeliveryDetailProvider, Consumer as DeliveryDetailConsumer }

import React from 'react'
import { ClientEcommerceConsumer } from '../context/ClientEcommerceContext'
import { Link } from 'react-router-dom'
import IndexEcommerceApp from '../IndexEcommerceApp'

export default class ShowEcommerceClientTab extends React.Component {
  render() {
    return (
      <ClientEcommerceConsumer>
        {({ isFetching }) => (
          <div>
            {isFetching ? (
              <div className="show-title mt-33">
                <p className="text-align-center">Obteniendo detalles de cliente E-Commerce ...</p>
              </div>
            ) : (
              <div>
                <IndexEcommerceApp />
              </div>
            )}
          </div>
        )}
      </ClientEcommerceConsumer>
    )
  }
}

import _ from 'lodash'

export const BasePromoReducer = (state, action) => {
  const baseProducts = state
  const product = action.payload

  switch (action.type) {
    case 'ADD_TO_BASE_PRODUCT': {
      const orderPackages = _.sortBy(product?.packages, ['quantity']).reverse()
      product.base_product_ids = [product.id]
      product.base_product_ids = [product.id]
      product.base_product_uid = product.db_ref
      product.base_product_db_ref = product.db_ref
      product.base_product_name = product.name
      product.base_package = null
      product.base_package_uid = null
      product.base_quantity = 0
      product.basePackageQuantity = null
      product.packages = orderPackages
      return [product, ...baseProducts]
    }
     

    case 'REMOVE_BASE_PRODUCT': {
      return baseProducts.filter((item) => item.id !== product.id)
    }

    case 'CHANGE_BASE_QUANTITY_PRODUCT': {
      baseProducts.forEach((pr, index) => {
        baseProducts[index].base_quantity = product.baseQuantity
      })
      return [...baseProducts]
    }
      

    case 'CHANGE_BASE_PACKAGE_PRODUCT': {
      const findBasePackageProduct = baseProducts.find((item) => item.id === product.id)

      if (findBasePackageProduct) {
        const productIndex = baseProducts.findIndex((item) => item.id === product.id)
        baseProducts[productIndex].base_package = product.basePackage
        baseProducts[productIndex].base_package_uid = product.basePackage_uid
        baseProducts[productIndex].base_package_quantity = product.basePackageQuantity
        baseProducts[productIndex].basePackageQuantity = product.basePackageQuantity
        return [...baseProducts]
      }
      return state
    }
      

    case 'CLEAR_BASE_PRODUCT': {
      return []
    }

    default: {
      return state
    }
      
  }
}

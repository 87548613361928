import React from 'react'
import cookie from 'react-cookies'
import { endsVowel } from '../helpers/utils'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ShowPromoView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      detailsData: null,
      id: window.location.href.split('/')[window.location.href.split('/').length - 1]
    }
  }

  componentDidMount() {
    this.fetchDetailsFromServer()
  }

  fetchDetailsFromServer = () => {
    this.setState({ isFetching: true })
    fetch(BACKEND_URL + '/promos/' + this.state.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => this.setState({ detailsData: data, isFetching: false }))
      .catch((e) => console.log(e))
  }

  suspendPromotion = () => {
    var response = window.confirm('Desea continuar con la suspension de la promoción?')
    if (response === true) {
      fetch(BACKEND_URL + '/promos/' + this.state.id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((data) => (window.location = '/promociones/' + this.state.id))
        .catch((e) => console.log(e))
    }
  }

  copyPromotion = () => {
    var response = window.confirm('¿Está seguro de crear una copia a partir de esta promoción?')
    if (response === true) {
      window.location = '/nueva_promo?clone=' + this.state.id
    }
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  getPackageBonusFormat = (quantity, pck) => {
    if (quantity > 1 && pck) {
      return `${endsVowel(pck) ? '(s)' : '(es)'}`
    } else {
      return ''
    }
  }

  render() {
    if (this.state.isFetching === false) {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="main-show-header">
            <h2>
              Promoción:{' '}
              {this.state.detailsData['applies_to_type'] === 'client' &&
                (this.state.detailsData['applies_to'][0]
                  ? '[' +
                    this.state.detailsData['external_promo_db_ref'] +
                    '] ' +
                    this.state.detailsData['applies_to'][0]['db_ref'] +
                    ' - ' +
                    this.state.detailsData['applies_to'][0]['name']
                  : ' ')}
              {this.state.detailsData['applies_to_type'] === 'qualifier' &&
                '[' + this.state.detailsData['external_promo_db_ref'] + '] ' + 'Atributo(s)'}
            </h2>
          </div>
          <div>
            <div className="index-table">
              <div className="show-area">
                <div className="show-header">
                  <h2>Detalles de promoción:</h2>
                  <div className="show-buttons">
                    {(this.state.detailsData['status'] === 'active' ||
                      this.state.detailsData['status'] === 'scheduled') && (
                      <input
                        type="submit"
                        className="suspend-button"
                        onClick={this.suspendPromotion}
                        value="Suspender promoción"
                      />
                    )}
                    <input
                      type="submit"
                      className="copy-promo-button"
                      onClick={this.copyPromotion}
                      value="Crear copia de promoción"
                    />
                  </div>
                </div>
                <hr className="mt-70" />
                <div>
                  <div className="promo-show-data">
                    <div>
                      <b>Fecha de la promoción:</b>
                    </div>
                    <div style={{ height: 'auto' }}>
                      <div>Inicio</div>
                      <div>
                        {this.state.detailsData['start_time']
                          .split('T')[0]
                          .replace('-', '/')
                          .replace('-', '/')}
                      </div>
                    </div>
                    <div style={{ height: 'auto' }}>
                      <div>Fin</div>
                      <div>
                        {this.state.detailsData['end_time']
                          .split('T')[0]
                          .replace('-', '/')
                          .replace('-', '/')}
                      </div>
                    </div>
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Código de promoción:</b>
                    </div>
                    <div style={{ height: 'auto' }}>
                      {this.state.detailsData['external_promo_db_ref']}
                    </div>
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Descripción:</b>
                    </div>
                    <div style={{ height: 'auto' }}>
                      {this.state.detailsData.description
                        ? this.state.detailsData.description
                        : '-'}
                    </div>
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Tipo de promoción:</b>
                    </div>
                    {this.state.detailsData['promo_type'] === 'bonus_product' && (
                      <div>Bonificación de una referencia</div>
                    )}
                    {this.state.detailsData['promo_type'] === 'assorted_bonus_product' && (
                      <div>Bonificación surtida</div>
                    )}
                    {(this.state.detailsData['promo_type'] === 'discount' || this.state.detailsData['promo_type'] === 'assorted_scale_discount_product' || this.state.detailsData['promo_type'] === 'scale_prices_reduction')&& (
                      <div>Descuento de una referencia / Descuento surtido</div>
                    )}
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Cliente o Atributo(s):</b>
                    </div>
                    <div style={{ height: 'auto' }}>
                      {this.state.detailsData['applies_to_type'] === 'client' && (
                        <div>Cliente individual</div>
                      )}
                      {this.state.detailsData['applies_to_type'] === 'qualifier' && (
                        <div>Atributo(s)</div>
                      )}
                    </div>
                  </div>
                  {this.state.detailsData['applies_to_type'] === 'client' && (
                    <div className="promo-show-data">
                      <div>
                        <b>Nombre de Cliente: </b>
                      </div>
                      <div style={{ height: 'auto' }}>
                        {this.state.detailsData['applies_to'][0]
                          ? this.state.detailsData['applies_to'][0]['db_ref'] +
                            ' - ' +
                            this.state.detailsData['applies_to'][0]['name']
                          : ' '}
                      </div>
                    </div>
                  )}
                  {this.state.detailsData['applies_to_type'] === 'qualifier' && (
                    <>
                      <div className="promo-show-data">
                        <div>
                          <b>Atributo A: </b>
                        </div>
                        <div style={{ height: 'auto', overflowWrap: 'break-word' }}>
                          {this.state.detailsData['qualifier_2']
                            ? this.state.detailsData['qualifier_2'].join(', ')
                            : '-'}
                        </div>
                      </div>
                      <div className="promo-show-data">
                        <div>
                          <b>Atributo B: </b>
                        </div>
                        <div style={{ height: 'auto', overflowWrap: 'break-word' }}>
                          {this.state.detailsData['qualifier_3']
                            ? this.state.detailsData['qualifier_3'].join(', ')
                            : '-'}
                        </div>
                      </div>
                      <div className="promo-show-data">
                        <div>
                          <b>Atributo C: </b>
                        </div>
                        <div style={{ height: 'auto', overflowWrap: 'break-word' }}>
                          {this.state.detailsData['qualifier_4']
                            ? this.state.detailsData['qualifier_4'].join(', ')
                            : '-'}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="promo-show-data" style={{ height: 'auto' }}>
                    <div>
                      <b>Producto(s) Base:</b>
                    </div>
                    {this.state.detailsData['promo_type'] === 'bonus_product' && (
                      <div>
                        {this.state.detailsData['promotion']['base_products'][0]['db_ref'] +
                          ' - ' +
                          this.state.detailsData['promotion']['base_products'][0]['name']}
                      </div>
                    )}

                    {this.state.detailsData['promo_type'] === 'assorted_bonus_product' && (
                      <div style={{ height: 'auto' }}>
                        {this.state.detailsData['promotion']['base_products'].map(
                          (product, index) => (
                            <div key={index}>
                              {this.state.detailsData['promotion']['base_products'][index][
                                'db_ref'
                              ] +
                                ' - ' +
                                this.state.detailsData['promotion']['base_products'][index]['name']}
                            </div>
                          )
                        )}
                      </div>
                    )}

                    {this.state.detailsData['promo_type'] === 'assorted_scale_discount_product' && (
                      <div style={{ height: 'auto' }}>
                        {this.state.detailsData['promotion']['products'].map((product, index) => (
                          <div key={index}>
                            {this.state.detailsData['promotion']['products'][index]['db_ref'] +
                              ' - ' +
                              this.state.detailsData['promotion']['products'][index]['name']}
                          </div>
                        ))}
                      </div>
                    )}

                    {this.state.detailsData['promo_type'] === 'scale_prices_reduction' && (
                      <div style={{ height: 'auto' }}>
                        {this.state.detailsData['promotion']['products'][0]['db_ref'] +
                          ' - ' +
                          this.state.detailsData['promotion']['products'][0]['name']}
                      </div>
                    )}
                  </div>
                  {this.state.detailsData['promo_type'] === 'scale_prices_reduction' && (
                    <div className="promo-show-data">
                      <div>
                        <b>Regla de promoción:</b>
                      </div>
                      <div style={{ height: 'auto' }}>
                        {this.state.detailsData['promotion']['scale_discount_hash']?.map(
                          (scale) => (
                            <div key={scale.uuid}>
                              {scale.min} - {scale.max}{' '}
                              {this.state.detailsData['promotion']['base_package']} {scale.discount}
                              %
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  {this.state.detailsData['promo_type'] === 'assorted_scale_discount_product' && (
                    <div className="promo-show-data">
                      <div>
                        <b>Regla de promoción:</b>
                      </div>
                      <div style={{ height: 'auto' }}>
                        {this.state.detailsData['promotion']['scale_discount_hash']?.map(
                          (scale) => (
                            <div key={scale.uuid}>
                              {scale.min} - {scale.max}{' '}
                              {this.state.detailsData['promotion']['base_package_units']
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .join(', ')
                                .replace(/, ([^,]*)$/, ' y/o $1')}{' '}
                              {scale.discount}%
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  {this.state.detailsData['promo_type'] === 'bonus_product' && (
                    <>
                      <div className="promo-show-data">
                        <div>
                          <b>Producto de bonificación:</b>
                        </div>
                        <div>
                          {this.state.detailsData['promotion']['bonus_products'][0]['db_ref'] +
                            ' - ' +
                            this.state.detailsData['promotion']['bonus_products'][0]['name']}
                        </div>
                      </div>
                      <div className="promo-show-data">
                        <div>
                          <b>Regla de promoción:</b>
                        </div>
                        {this.state.detailsData['promotion']['promo_rule_type'] === 'one_increment' && (
                          <div className='width-500'>
                            {this.state.detailsData['promotion']['base_quantity'] +
                              ' ' +
                              this.state.detailsData['promotion']['base_package'] +
                              ' ' +
                              ' + ' +
                              this.state.detailsData['promotion']['bonus_quantity'] +
                              ' ' +
                              this.state.detailsData['promotion']['bonus_package']}
                          </div>
                        )}
                        {this.state.detailsData['promotion']['promo_rule_type'] === 'scale' && (
                          <div className='width-500'>
                            {this.state.detailsData['promotion']['promo_scale_array']?.map(
                            (scale) => (
                            <div key={scale.uuid}>
                              {scale.min} - {scale.max}{' '}
                              {this.state.detailsData['promotion']['base_package']}{', por cada '} 
                              {scale.inc_factor}{' '} {this.state.detailsData['promotion']['base_package']}
                              {', se regala '}{scale.bonus_qty}{' '}{this.state.detailsData['promotion']['bonus_package']} 
                            </div>
                            )
                            )}
                          </div>
                        )}
                      </div>
                      <div className="promo-show-data">
                        <div>
                          <b>Máximo a bonificar:</b>
                        </div>
                        <div>
                        {this.state.detailsData['promotion']['bonus_limit'] == "0"
                          ? '-'
                          : this.state.detailsData['promotion']['bonus_limit']}
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.detailsData['promo_type'] === 'assorted_bonus_product' && (
                    <>
                      <div className="promo-show-data">
                        <div>
                          <b>Producto de bonificación:</b>
                        </div>
                        <div>
                        <div style={{ height: 'auto' }}>
                          {this.state.detailsData['promotion']['bonus_products'].map(
                            (product, index) => (
                              <div key={index}>
                                {this.state.detailsData['promotion']['bonus_products'][index][
                                  'db_ref'
                                ] +
                                  ' - ' +
                                  this.state.detailsData['promotion']['bonus_products'][index]['name']}
                              </div>
                            )
                          )}
                        </div>
                        </div>
                      </div>
                      <div className="promo-show-data">
                        <div>
                          <b>Regla de promoción:</b>
                        </div>
                        {this.state.detailsData['promotion']['promo_rule_type'] === 'one_increment' && (
                          <div className='width-500'>
                            {this.state.detailsData['promotion']['base_quantity'] +
                              ' ' +
                              this.state.detailsData['promotion']['base_package_units']
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .join(', ')
                                .replace(/, ([^,]*)$/, ' y/o $1') +
                              ' ' +
                              ' + ' +
                              this.state.detailsData['promotion']['bonus_quantity'] +
                              ' ' +
                              this.state.detailsData['promotion']['bonus_package']}
                          </div>
                        )}
                        {this.state.detailsData['promotion']['promo_rule_type'] === 'scale' && (
                          <div className='width-500'>
                            {this.state.detailsData['promotion']['promo_scale_array']?.map(
                            (scale) => (
                            <div key={scale.uuid}>
                              {scale.min} - {scale.max}{' '}
                              {this.state.detailsData['promotion']['base_package_units']
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .join(', ')
                                .replace(/, ([^,]*)$/, ' y/o $1')}{', por cada '}
                              {scale.inc_factor}{' '} {this.state.detailsData['promotion']['base_package_units']
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .join(', ')
                                .replace(/, ([^,]*)$/, ' y/o $1')}{', se regala '}{scale.bonus_qty}{' '}{this.state.detailsData['promotion']['bonus_package']} 
                            </div>
                            )
                            )}
                          </div>
                        )}
                      </div>
                      <div className="promo-show-data">
                        <div>
                          <b>Máximo a bonificar:</b>
                        </div>
                        <div>
                        {this.state.detailsData['promotion']['bonus_limit'] == "0"
                          ? '-'
                          : this.state.detailsData['promotion']['bonus_limit']}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="promo-show-data">
                    <div>
                      <b>Estado de promoción:</b>
                    </div>
                    {this.state.detailsData['status'] === 'active' && <div>Activa</div>}
                    {this.state.detailsData['status'] === 'expired' && <div>Caducada</div>}
                    {this.state.detailsData['status'] === 'scheduled' && <div>No Iniciada</div>}
                    {this.state.detailsData['status'] === 'suspended' && <div>Suspendida</div>}
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Fecha de creación:</b>
                    </div>
                    <div>{this.getFormattedDate(this.state.detailsData['created_at'])}</div>
                  </div>
                  <div className="promo-show-data">
                    <div>
                      <b>Fecha de actualización:</b>
                    </div>
                    <div>{this.getFormattedDate(this.state.detailsData['updated_at'])}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="show-title">
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    }
  }
}

import React from 'react'
import cookie from 'react-cookies'
import ReactDOM from 'react-dom'
import CategoryGoalRow from './CategoryGoalRow'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class CategoryGoalsSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categoryGoals: [],
      priceUnits: [],
      goalCategories: [],
      total: 0,
      isFetching: false,
      isCreating: false,
      business_id: 1,
      selected_cycle_id: this.props.selected_cycle_id,
      salesman_id: this.props.salesman_id
    }
  }

  // whenever cycle changes it does not render again but resetting creating mode
  shouldComponentUpdate(nextProps) {
    if (this.props.selected_cycle_id !== nextProps.selected_cycle_id) {
      this.setState({ isCreating: false })
      return false
    } else {
      return true
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({ selected_cycle_id: nextProps.selected_cycle_id }, this.fetchDataFromServer)
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  startCreating = (event) => {
    this.props.changeEditMode(true)
    this.setState({ isCreating: true })
  }

  cancelCreating = (event) => {
    this.props.changeEditMode(false)
    this.setState({ isCreating: false })
  }

  fetchDataFromServer = () => {
    if (this.state.selected_cycle_id) {
      this.setState({ isFetching: true })
      fetch(
        BACKEND_URL +
          '/businesses/' +
          this.state.business_id +
          '/cycles/' +
          this.state.selected_cycle_id +
          '/salesmen/' +
          this.state.salesman_id +
          '/category_goals',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        })
        .then((data) =>
          this.setState({ categoryGoals: data['table'], total: data['count'], isFetching: false })
        )
      this.fetchMissingProdyctTypes()
      this.fetchSalesUnits()
    }
  }

  fetchMissingProdyctTypes = () => {
    if (this.state.selected_cycle_id) {
      fetch(
        BACKEND_URL +
          '/businesses/' +
          this.state.business_id +
          '/cycles/' +
          this.state.selected_cycle_id +
          '/salesmen/' +
          this.state.salesman_id +
          '/goal_categories/missing',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
        .then((response) => {
          if (response.status === 401) {
            window.location = '/'
          }
          return response.json()
        })
        .then((data) => this.setState({ goalCategories: data }))
        .catch((e) => console.log(e))
    }
  }

  fetchSalesUnits = () => {
    fetch(BACKEND_URL + '/price_units', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => this.setState({ priceUnits: data['table'] }))
      .catch((e) => console.log(e))
  }

  render() {
    return (
      <div>
        {this.state.categoryGoals.length > 0 && this.state.isFetching == false && (
          <div>
            <div className="subheader">
              <b>Cuota de venta por categoria:</b>
            </div>
            <div className="mt-33">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>CATEGORIA</th>
                    <th>CUOTA</th>
                    <th>UNIDAD</th>
                    <th className="text-align-center">ACCION</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.categoryGoals.map((obj, index) => (
                    <CategoryGoalRow
                      key={index}
                      goal={obj}
                      salesman_id={this.state.salesman_id}
                      priceUnits={this.state.priceUnits}
                      goalCategories={this.state.goalCategories}
                      selected_cycle_id={this.state.selected_cycle_id}
                      price_unit_id={obj['price_unit']['id']}
                      goal_category_id={obj['goal_category']['id']}
                      isCreating={this.state.isCreating}
                      cancelCreatingMode={() => this.cancelCreating()}
                      changeEditMode={(val) => this.props.changeEditMode(val)}
                    />
                  ))}
                  {this.state.isCreating == true && (
                    <CategoryGoalRow
                      goal={null}
                      salesman_id={this.state.salesman_id}
                      priceUnits={this.state.priceUnits}
                      goalCategories={this.state.goalCategories}
                      selected_cycle_id={this.state.selected_cycle_id}
                      price_unit_id={null}
                      isCreating={this.state.isCreating}
                      cancelCreatingMode={() => this.cancelCreating()}
                      changeEditMode={(val) => this.props.changeEditMode(val)}
                    />
                  )}
                </tbody>
              </table>
            </div>
            {this.state.isCreating == false && (
              <input
                onClick={this.startCreating.bind(this)}
                className="add-category-goal-button"
                value="+  Agregar categoria"
                type="submit"
              />
            )}
          </div>
        )}
        {this.state.categoryGoals.length === 0 && this.state.isFetching == false && (
          <div>
            {this.state.isCreating == false && (
              <div className="show-data">
                <div className="subheader">
                  <b>Cuota de venta por categoria:</b>
                </div>
                <div>No existe cuota actual</div>
                {this.state.selected_cycle_id && (
                  <input
                    onClick={this.startCreating.bind(this)}
                    value="Crear cuota"
                    className="create-goal-button"
                    type="submit"
                  />
                )}
                {!this.state.selected_cycle_id && (
                  <input
                    onClick={this.startCreating.bind(this)}
                    value="Crear cuota"
                    className="inactive create-goal-button"
                    type="submit"
                    disabled
                  />
                )}
              </div>
            )}
            {this.state.isCreating == true && (
              <div>
                <div className="subheader">
                  <b>Cuota de venta por categoria:</b>
                </div>
                <div className="mt-33">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>CATEGORIA</th>
                        <th>CUOTA</th>
                        <th>UNIDAD</th>
                        <th className="text-align-center">ACCION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <CategoryGoalRow
                        goal={null}
                        salesman_id={this.state.salesman_id}
                        priceUnits={this.state.priceUnits}
                        goalCategories={this.state.goalCategories}
                        selected_cycle_id={this.state.selected_cycle_id}
                        price_unit_id={null}
                        isCreating={this.state.isCreating}
                        cancelCreatingMode={() => this.cancelCreating()}
                        changeEditMode={(val) => this.props.changeEditMode(val)}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

import React from 'react'
import cookie from 'react-cookies'
import Pagination from '../layout/Pagination'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ProductHistoryTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      data: [],
      current_page: 1,
      per_page: 25,
      total_pages: 1,
      query: '',
      searchInput: ''
    }
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  handleSearch = (e) => {
    this.setState({ searchInput: e.target.value })
  }

  handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      this.buildSearchQuery()
    }
  }

  buildSearchQuery = () => {
    let queryParam = ''
    if (this.state.searchInput) {
      queryParam = `&q=${this.state.searchInput}`
    }
    this.setState({ query: queryParam }, () => {
      this.fetchDataFromServer()
    })
  }

  resetFilters = () => {
    this.setState({ searchInput: '' })
  }

  fetchDataFromServer = () => {
    this.setState({ isFetching: true })
    fetch(
      BACKEND_URL +
        '/products/' +
        this.props.id +
        '/product_price_audits' +
        '?page=' +
        this.state.current_page +
        '&per_page=' +
        this.state.per_page +
        (this.state.query ? this.state.query : ''),
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          data: data['table'],
          isFetching: false,
          total_pages: Math.ceil(data['count'] / this.state.per_page)
        })
      )
      .catch((e) => console.log(e))
  }

  handlePagination = (event) => {
    if (event.target.id === 'first-page') {
      if (this.state.current_page !== 1) {
        this.setState({ current_page: 1 }, this.fetchDataFromServer)
      }
    } else if (event.target.id === 'last-page') {
      if (this.state.current_page !== this.state.total_pages) {
        this.setState({ current_page: this.state.total_pages }, this.fetchDataFromServer)
      }
    } else if (event.target.id === 'previous-page') {
      if (this.state.current_page !== 1) {
        this.setState(
          { current_page: parseInt(this.state.current_page) - 1 },
          this.fetchDataFromServer
        )
      }
    } else if (event.target.id === 'next-page') {
      if (this.state.current_page !== this.state.total_pages) {
        this.setState(
          { current_page: parseInt(this.state.current_page) + 1 },
          this.fetchDataFromServer
        )
      }
    }
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  render() {
    const data = this.props.data
    return (
      <div>
        <div className="title-and-search-containter mt-50">
          <h4 className="">Listas de precios asignados a producto:</h4>

          <div className="search-input-containter mb-20">
            <input
              value={this.state.searchInput}
              onChange={this.handleSearch}
              onKeyDown={this.handleEnterSearch}
              className="round-search-input"
              placeholder="Buscar históricos por nombre o descripción"
            />
            {this.state.searchInput === '' && (
              <span className="glyphicon glyphicon-search form-control-feedback" />
            )}
            {this.state.searchInput && (
              <div
                title="Limpiar texto"
                onClick={this.resetFilters}
                className="round-search-input-x"
              >
                X
              </div>
            )}
          </div>
        </div>

        <div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th style={{ verticalAlign: 'middle', width: '20%' }}>
                  <span className="th-header-text  ">FECHA Y HORA</span>
                </th>
                <th style={{ verticalAlign: 'middle', width: '20%' }}>
                  <span className="th-header-text">NOMBRE</span>
                </th>
                <th style={{ verticalAlign: 'middle', width: '20%' }}>
                  <span className="th-header-text">DESCRIPCION</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.isFetching == false &&
                this.state.data.length > 0 &&
                this.state.data.map((obj, index) => (
                  <tr key={index}>
                    <td className="td-text-limited">{this.getFormattedDate(obj['timestamp'])}</td>
                    <td className="td-text-limited">{obj['user_email']}</td>
                    <td className="td-text-limited">{obj['description']}</td>
                  </tr>
                ))}
              {this.state.isFetching == false && this.state.data.length == 0 && (
                <tr>
                  <td className="td-text-limited" colSpan="3">
                    No hay resultados
                  </td>
                </tr>
              )}
              {this.state.isFetching == true && (
                <tr>
                  <td className="td-text-limited" colSpan="3">
                    Obteniendo datos...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <nav onClick={this.handlePagination.bind(this)}>
            {this.state.total_pages > 1 && (
              <Pagination
                current_page={this.state.current_page}
                total_pages={this.state.total_pages}
              />
            )}
          </nav>
        </div>
      </div>
    )
  }
}

import React from 'react'
import { validateAccessRole } from '../../helpers/userRole'
import { ClientConsumer } from '../context/ClientContext'
import { GlobalConsumer } from '../context/GlobalContext'
import cookie from 'react-cookies'

export default class ShowClientTab extends React.Component {
  state = {
    isClientEditing: this.props.isClientEditing,
    isFetching: false
  }

  getFormattedDate = (date) => {
    const d = new Date(date)
    return d.toLocaleString()
  }

  render() {
    if (this.state.isFetching === false) {
      return (
        <ClientConsumer>
          {({
            clientData,
            failedCreation,
            failedCreationInfo,
            handleClickButton,
            handleSubmitEditClient,
            handleChange,
            clientEditDetailsData
          }) => (
            <GlobalConsumer>
              {({ handleEditingClient, globalEdit }) => (
                <div>
                  {globalEdit === false && (
                    <div>
                      <div className="show-product-header show-header">
                        <h2>Detalles del cliente</h2>
                        <div className="show-buttons-responsive">
                          {validateAccessRole(cookie.load('user_role')?.clients_module)
                            ?.can_write && (
                            <input
                              type="submit"
                              onClick={(event) => {
                                handleClickButton(event)
                                handleEditingClient(event)
                              }}
                              value="Actualizar cliente"
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        <hr />
                        <div className="pl-20">
                          <div>
                            <div className="show-data">
                              <div>Grupo de Cliente</div>
                              {clientData['client_group'] && (
                                <div>{clientData['client_group']['description']}</div>
                              )}
                            </div>
                            <div className="show-data">
                              <div>Canal de Ventas</div>
                              {clientData['sales_channel'] && (
                                <div>{clientData['sales_channel']['description']}</div>
                              )}
                            </div>
                            <div className="show-data">
                              <div>Tipo de Pago</div>
                              {clientData['payment_type'] && (
                                <div>{clientData['payment_type']['description']}</div>
                              )}
                            </div>
                            <div className="show-data">
                              <div>Plazo de Credito</div>
                              {clientData['credit_term'] && (
                                <div>{clientData['credit_term']['description']}</div>
                              )}
                            </div>
                            <div className="show-data">
                              <div>Provincia</div>
                              {clientData['province'] && (
                                <div>{clientData['province']['name']}</div>
                              )}
                            </div>
                            <div className="show-data">
                              <div>Distrito</div>
                              {clientData['district'] && (
                                <div>{clientData['district']['name']}</div>
                              )}
                            </div>
                            <div className="show-data">
                              <div>Corregimiento</div>
                              {clientData['town'] && <div>{clientData['town']['name']}</div>}
                            </div>
                            <div className="show-data">
                              <div>Lista de Precio que le aplica</div>
                              {clientData['price_list'] && (
                                <div>{clientData['price_list']['description']}</div>
                              )}
                            </div>
                            <div className="show-data">
                              <div>Codigo</div>
                              <div>{clientData['db_ref']}</div>
                            </div>
                            <div className="show-data">
                              <div>Activo</div>
                              {clientData['active'] === true && <div>Si</div>}
                              {clientData['active'] === false && <div>No</div>}
                            </div>
                            <div className="show-data">
                              <div>Ruc</div>
                              <div>{clientData['taxpayer_id']}</div>
                            </div>
                            <div className="show-data">
                              <div>DV</div>
                              <div>{clientData['taxpayer_verificator_digit']}</div>
                            </div>
                            <div className="show-data">
                              <div>Razon Social</div>
                              <div>{clientData['business_name']}</div>
                            </div>
                            <div className="show-data">
                              <div>Nombre Comercial</div>
                              <div>{clientData['commercial_name']}</div>
                            </div>
                            <div className="show-data">
                              <div>Nombre de cliente en sistema</div>
                              <div>{clientData['customer_name_db_ref']}</div>
                            </div>
                            <div className="show-data">
                              <div>Monto de Credito</div>
                              <div>{clientData['credit_limit']}</div>
                            </div>
                            <div className="show-data">
                              <div>Representante Legal</div>
                              <div>{clientData['legal_representative']}</div>
                            </div>
                            <div className="show-data">
                              <div>Persona de contacto para cobro de</div>
                              <div>{clientData['billing_contact_name']}</div>
                            </div>
                            <div className="show-data">
                              <div>Pago: correo electronico</div>
                              <div>{clientData['billing_contact_email']}</div>
                            </div>
                            <div className="show-data">
                              <div>Pago: Telefono</div>
                              <div>{clientData['billing_contact_phone']}</div>
                            </div>
                            <div className="show-data">
                              <div>Persona de contacto</div>
                              <div>{clientData['contact_name']}</div>
                            </div>
                            <div className="show-data">
                              <div>Correo electronico</div>
                              <div>{clientData['contact_email']}</div>
                            </div>
                            <div className="show-data">
                              <div>Telefono</div>
                              <div>{clientData['contact_phone']}</div>
                            </div>
                            <div className="show-data">
                              <div>Ciudad</div>
                              <div>{clientData['city']}</div>
                            </div>
                            <div className="show-data">
                              <div>Calle</div>
                              <div>{clientData['street']}</div>
                            </div>
                            <div className="show-data">
                              <div>Guia de Ubicacion</div>
                              <div>{clientData['location_description']}</div>
                            </div>
                            <div className="show-data">
                              <div>Coordenadas en grados decimales (latitud)</div>
                              <div>{clientData['geographical_latitude']}</div>
                            </div>
                            <div className="show-data">
                              <div>Coordenadas en grados decimales (longitud)</div>
                              <div>{clientData['geographical_longitud']}</div>
                            </div>
                            <div className="show-data hidden">
                              <div>Ruta asignada</div>
                              <div>{clientData['route_id']}</div>
                            </div>
                            <div className="show-data">
                              <div>Fecha de creación</div>
                              <div>{this.getFormattedDate(clientData['created_at'])}</div>
                            </div>
                            <div className="show-data">
                              <div>Fecha de actualización</div>
                              <div>{this.getFormattedDate(clientData['updated_at'])}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {globalEdit === true && (
                    <div>
                      {failedCreation === true && (
                        <div className="show-product-header">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="alert alert-danger" role="alert">
                                <p>{failedCreationInfo}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="show-product-header show-header">
                        <h2>
                          Actualizar detalles de cliente:{' '}
                          {clientData['db_ref'] + '-' + clientData['customer_name_db_ref']}
                        </h2>
                      </div>
                      <div>
                        <div className="show-area">
                          <form onSubmit={handleSubmitEditClient}>
                            <div className="show-header-edit">
                              <h2>Detalles del cliente:</h2>
                              <div className="show-buttons-responsive">
                                <input
                                  type="submit"
                                  onClick={handleEditingClient}
                                  className="cancel-edit-button"
                                  value="Cancelar"
                                />
                                <input type="submit" value="Guardar" />
                              </div>
                            </div>
                            <hr />
                            <div>
                              <div className="show-data">
                                <div>Grupo de Cliente</div>
                                <select name="client_group_id" onChange={handleChange}>
                                  {(!clientData.client_group ||
                                    Object.keys(clientData.client_group).length === 0) && (
                                    <option value="">Escoja una opción</option>
                                  )}
                                  {clientData.client_group && (
                                    <option value={clientData.client_group.id} hidden>
                                      {clientData.client_group.description}
                                    </option>
                                  )}
                                  {clientEditDetailsData &&
                                    clientEditDetailsData.client_group.map((obj) => (
                                      <option key={obj.id} value={obj.id}>
                                        {obj['description']}
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="show-data">
                                <div>Canal de Ventas</div>
                                <select name="sales_channel_id" onChange={handleChange}>
                                  {(!clientData.sales_channel ||
                                    Object.keys(clientData.sales_channel).length === 0) && (
                                    <option value="">Escoja una opción</option>
                                  )}
                                  {clientData.sales_channel && (
                                    <option value={clientData.sales_channel.id} hidden>
                                      {clientData.sales_channel.description}
                                    </option>
                                  )}
                                  {clientEditDetailsData &&
                                    clientEditDetailsData.sales_channel.map((obj) => (
                                      <option key={obj.id} value={obj.id}>
                                        {obj['description']}
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="show-data">
                                <div>Tipo de Pago</div>
                                <select name="payment_type_id" onChange={handleChange}>
                                  {(!clientData['payment_type'] ||
                                    Object.keys(clientData['payment_type']).length === 0) && (
                                    <option value="">Escoja una opción</option>
                                  )}
                                  {clientData['payment_type'] && (
                                    <option value={clientData['payment_type']['id']} hidden>
                                      {clientData['payment_type']['description']}
                                    </option>
                                  )}
                                  {clientEditDetailsData &&
                                    clientEditDetailsData['payment_type'].map((obj) => (
                                      <option key={obj.id} value={obj.id}>
                                        {obj['description']}
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="show-data">
                                <div>Plazo de Crédito</div>
                                <select name="credit_term_id" onChange={handleChange}>
                                  {(!clientData['credit_term'] ||
                                    Object.keys(clientData['credit_term']).length === 0) && (
                                    <option value="">Escoja una opción</option>
                                  )}
                                  {clientData['credit_term'] && (
                                    <option value={clientData['credit_term'].id} hidden>
                                      {clientData['credit_term']['description']}
                                    </option>
                                  )}
                                  {clientEditDetailsData &&
                                    clientEditDetailsData['credit_term'].map((obj) => (
                                      <option key={obj.id} value={obj.id}>
                                        {obj['description']}
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="show-data">
                                <div>Provincia</div>
                                <select name="province_id" onChange={handleChange}>
                                  {(!clientData['province'] ||
                                    Object.keys(clientData['province']).length === 0) && (
                                    <option value="">Escoja una opción</option>
                                  )}
                                  {clientData['province'] && (
                                    <option value={clientData['province'].id} hidden>
                                      {clientData['province']['name']}
                                    </option>
                                  )}
                                  {clientEditDetailsData &&
                                    clientEditDetailsData['province'].map((obj) => (
                                      <option key={obj.id} value={obj.id}>
                                        {obj['name']}
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="show-data">
                                <div>Distrito</div>
                                <select name="district_id" onChange={handleChange}>
                                  {(!clientData['district'] ||
                                    Object.keys(clientData['district']).length === 0) && (
                                    <option value="">Escoja una opción</option>
                                  )}
                                  {clientData['district'] && (
                                    <option value={clientData['district'].id} hidden>
                                      {clientData['district']['name']}
                                    </option>
                                  )}
                                  {clientEditDetailsData &&
                                    clientEditDetailsData['district'].map((obj) => (
                                      <option key={obj.id} value={obj.id}>
                                        {obj['name']}
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="show-data">
                                <div>Corregimiento</div>
                                <select name="town_id" onChange={handleChange}>
                                  {(!clientData['town'] ||
                                    Object.keys(clientData['town']).length === 0) && (
                                    <option value="">Escoja una opción</option>
                                  )}
                                  {clientData['town'] && (
                                    <option value={clientData['town'].id} hidden>
                                      {clientData['town']['name']}
                                    </option>
                                  )}
                                  {clientEditDetailsData &&
                                    clientEditDetailsData['town'].map((obj) => (
                                      <option key={obj.id} value={obj.id}>
                                        {obj['name']}
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="show-data">
                                <div>
                                  Lista de Precio que le aplica<span className="red">*</span>
                                </div>
                                <select required name="price_list_id" onChange={handleChange}>
                                  {(!clientData['price_list'] ||
                                    Object.keys(clientData['price_list']).length === 0) && (
                                    <option value="">Escoja una opción</option>
                                  )}
                                  {clientData['price_list'] && (
                                    <option value={clientData['price_list'].id} hidden>
                                      {clientData['price_list']['description']}
                                    </option>
                                  )}
                                  {clientEditDetailsData &&
                                    clientEditDetailsData['price_list'].map((obj) => (
                                      <option key={obj.id} value={obj.id}>
                                        {obj['description']}
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="show-data">
                                <div>
                                  Codigo cliente<span className="red">*</span>
                                </div>
                                <input
                                  type="text"
                                  name="db_ref"
                                  disabled
                                  defaultValue={clientData['db_ref']}
                                />
                              </div>

                              <div className="show-data">
                                <div>Activo</div>
                                <select name="active" onChange={handleChange}>
                                  {clientData['active'] === true && (
                                    <option value={true} hidden>
                                      Si
                                    </option>
                                  )}
                                  {!clientData['active'] === true && (
                                    <option value={false} hidden>
                                      No
                                    </option>
                                  )}
                                  <option value="true">Si</option>
                                  <option value="false">No</option>
                                </select>
                              </div>
                              <div className="show-data">
                                <div>Ruc</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="taxpayer_id"
                                  defaultValue={clientData['taxpayer_id']}
                                  placeholder="0000000-0-000000"
                                />
                              </div>
                              <div className="show-data">
                                <div>DV</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="taxpayer_verificator_digit"
                                  defaultValue={clientData['taxpayer_verificator_digit']}
                                  placeholder="00"
                                />
                              </div>
                              <div className="show-data">
                                <div>Razon Social</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="business_name"
                                  defaultValue={clientData['business_name']}
                                  placeholder="Razón Social"
                                />
                              </div>
                              <div className="show-data">
                                <div>Nombre Comercial</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="commercial_name"
                                  defaultValue={clientData['commercial_name']}
                                  placeholder="Nombre comercial"
                                />
                              </div>
                              <div className="show-data">
                                <div>
                                  Nombre de cliente en sistema<span className="red">*</span>
                                </div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="customer_name_db_ref"
                                  defaultValue={clientData['customer_name_db_ref']}
                                  required
                                  placeholder="Nombre cliente en sistema"
                                />
                              </div>
                              <div className="show-data">
                                <div>Monto de Credito</div>
                                <input
                                  type="number"
                                  onChange={handleChange}
                                  name="credit_limit"
                                  step="0.01"
                                  max="999999"
                                  defaultValue={clientData['credit_limit']}
                                  placeholder="$$"
                                />
                              </div>
                              <div className="show-data">
                                <div>Representante Legal</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="legal_representative"
                                  defaultValue={clientData['legal_representative']}
                                  placeholder="Nombre representante legal"
                                />
                              </div>
                              <div className="show-data">
                                <div>Persona de contacto para cobro de</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="billing_contact_name"
                                  defaultValue={clientData['billing_contact_name']}
                                  placeholder="Nombre persona de contacto"
                                />
                              </div>
                              <div className="show-data">
                                <div>Pago: correo electronico</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="billing_contact_email"
                                  defaultValue={clientData['billing_contact_email']}
                                  placeholder="Correo electrónico de pago"
                                />
                              </div>
                              <div className="show-data">
                                <div>Pago: Telefono</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="billing_contact_phone"
                                  defaultValue={clientData['billing_contact_phone']}
                                  placeholder="0000-0000"
                                />
                              </div>
                              <div className="show-data">
                                <div>Persona de contacto</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="contact_name"
                                  defaultValue={clientData['contact_name']}
                                  placeholder="Nombre persona de contacto"
                                />
                              </div>
                              <div className="show-data">
                                <div>Correo electronico</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="contact_email"
                                  defaultValue={clientData['contact_email']}
                                  placeholder="Correo electrónico persona de contacto"
                                />
                              </div>
                              <div className="show-data">
                                <div>Telefono</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="contact_phone"
                                  defaultValue={clientData['contact_phone']}
                                  placeholder="0000-0000"
                                />
                              </div>
                              <div className="show-data">
                                <div>Ciudad</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="city"
                                  defaultValue={clientData['city']}
                                  placeholder="Ciudad"
                                />
                              </div>
                              <div className="show-data">
                                <div>Calle</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="street"
                                  defaultValue={clientData['street']}
                                  placeholder="Calle"
                                />
                              </div>
                              <div className="show-data">
                                <div>Guia de Ubicacion</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="location_description"
                                  defaultValue={clientData['location_description']}
                                  placeholder="Guía de Ubicación"
                                />
                              </div>
                              <div className="show-data">
                                <div>Coordenadas en grados decimales (latitud)</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="geographical_latitude"
                                  defaultValue={clientData['geographical_latitude']}
                                  placeholder="00.000000"
                                />
                              </div>
                              <div className="show-data">
                                <div>Coordenadas en grados decimales (longitud)</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="geographical_longitud"
                                  defaultValue={clientData['geographical_longitud']}
                                  placeholder="00.000000"
                                />
                              </div>
                              <div className="show-data hidden">
                                <div>Ruta asignada</div>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  name="route_id"
                                  defaultValue={clientData['route_id']}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </GlobalConsumer>
          )}
        </ClientConsumer>
      )
    } else {
      return (
        <div className="main-view">
          <div className="show-title">
            <p>Obteniendo datos ...</p>
          </div>
        </div>
      )
    }
  }
}

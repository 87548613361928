import React from 'react'
import TabWithDropdown from '../../layout/TabWithDropdown'
import PosDetailsTab from './PosDetailsTab'

export default class PosDetailsView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessfulMessage: false,
      alertState: 'alert alert-success',
      createText: 'Guardar',
      isPosting: false,
      activity: props.defaultDetails
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ details: nextProps.defaultDetails })
  }

  componentDidMount() {
    this.setQueryParams()
  }

  showSuccessfulMessageCreation = () => {
    if (document.referrer.includes('nuevo_pedido')) {
      this.setState({ showSuccessfulMessage: true })
    } else {
      this.setState({ showSuccessfulMessage: false })
    }
  }

  hiddenAlert = () => {
    this.setState({
      showSuccessfulMessage: false, 
    });
  }

  disableButton = () => {
    this.setState({ createText: 'Guardando...', isPosting: true })
  }

  enableButton = () => {
    this.setState({ createText: 'Guardar', isPosting: false })
  }

  setQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const success = urlParams.get('success') || ''
    if (success) {
      this.setState({ showSuccessfulMessage: true })
    }
  }

  render() {
    return (
      <div>
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          {this.state.showSuccessfulMessage && (
            <div className="alert alert-success" role="alert">
              <button type="button" className="close" onClick={this.hiddenAlert} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <strong className="success-message">La tarea en punto de venta fue creada exitosamente</strong>
            </div>
          )}
          <div className="main-show-header">
            <h2>Tareas en punto de venta: {this.state.activity?.short_uuid}</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div>
                <div>
                  <div>
                    {this.state.activity?.status === 'received' ||
                      (this.state.activity?.status === 'sent' && (
                        <ul className="nav nav-tabs">
                          <TabWithDropdown
                            name="Ejecución en punto de venta"
                            activeTab={'Ejecución en punto de venta'}
                            items={[]}
                          />
                        </ul>
                      ))}
                  </div>

                  <div>
                    {this.state.activity && (
                      <PosDetailsTab
                        activity={this.state.activity}
                        transactionUuid={this.props.details.uuid}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

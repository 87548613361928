import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
// import ProductPriceListRow from './ProductPriceListRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const UserRoutesDetailTab = () => {
    const [isFetching, setIsFetching] = useState(true)
    const [routesData, setRoutesData] = useState(null)
    const [addNewRoute, setAddNewRoute] = useState(false)
    const [missingRoutes, setMissingRoutes] = useState(null)
    const [price, setPrice] = useState(null)
    const [routeId, setRouteId] = useState(null)
    const [invalidRouteId, setInvalidRouteId] = useState(false)

    // useEffect(() => {
    //     async function fetchData() {
    //       await fetchDataFromServer()
    //     }
    
    //     fetchData()
    //   }, [])

//   const fetchDataFromServer = () => {
//     setIsFetching(true)

//     fetch(BACKEND_URL + '/products/' + this.props.id + '/product_pricelist/missing_pricelists', {
//       method: 'GET',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Authorization: 'Bearer ' + cookie.load('token')
//       }
//     })
//       .then((response) => response.json())
//       .then((data) => setMissingPriceLists(data))
//       .catch((e) => console.log(e))

//     fetch(BACKEND_URL + '/products/' + this.props.id + '/product_pricelist', {
//       method: 'GET',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Authorization: 'Bearer ' + cookie.load('token')
//       }
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setPriceListsData(data)  
//         setIsFetching(false)
//       })
//       .catch((e) => console.log(e))
//   }

  const setOptionsForMissingRoutes = () => {
    const items = []
    missingRoutes.map((obj) =>
      items.push(
        <option
          key={obj.id}
          value={obj.description + ' - ' + obj.db_ref}
          name={obj.description + ' - ' + obj.db_ref}
        >
          {obj.id}
        </option>
      )
    )
    return items
  }

  const startAddingNewRoute = () => {
    setAddNewRoute(true)
  }

  const cancelAddNewRoute = () => {
    setAddNewRoute(false)
  }

  const sendAddNewRoute = (event) => {
    event.preventDefault()
    if (routeId == null) {
      setInvalidRouteId(true)
    } else {
      setInvalidRouteId(true)
      fetch(BACKEND_URL + '/product_pricelist/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          price: price,
          product_id: this.props.id,
          price_list_id: routeId
        })
      })
        .then((response) => response.json())
        .then((data) => (window.location = '/productos/' + this.props.id + '/listas_de_precios'))
        .catch((e) => console.log(e))
    }
  }

  const handleInputPriceChange = (event) => {
    const obj = {}
    obj[event.target.name] = event.target.value
    this.setState(obj)
  }

  const handleSearchRoute = (event) => {
    const element = document.getElementsByName(event.target.value)[0]
    if (element) {
      const price_list_id = element.innerText
      if (price_list_id) {
        setRouteId(price_list_id)
      }
    }
  }
    return (
      <div className="show-area-tab">
        {addNewRoute === true && (
          <form onSubmit={sendAddNewRoute.bind(this)}>
            <div className="show-header show-routes-header">
              <h2 className="show-routes-subtitle">Clientes asignados a usuario:</h2>
              <div className="cancel-save-block">
                <input
                  id="cancel-add"
                  onClick={cancelAddNewRoute.bind(this)}
                  className="cancel-edit-button"
                  value="Cancelar"
                  type="submit"
                />
                <input value="Guardar" className="save-button" type="submit" />
              </div>
            </div>
            <hr className="mt-70" />
            <div className="add-price-list-block">
              <h2>Escoja el cliente que desea asignarle al usuario:</h2>
              <div className="add-price-list-inputs">
                <div className="client-select">
                  <div>Cliente</div>
                  <input
                    onChange={handleSearchRoute.bind(this)}
                    list="all-price-lists"
                    placeholder="Escribir nombre o código"
                    type="text"
                    required
                  />
                  <span className="icon-search" >
                    <FontAwesomeIcon className="icon-search" icon={faSearch} />
                  </span>
                  <datalist id="all-price-lists">
                    {isFetching === false && setOptionsForMissingRoutes()}
                  </datalist>
                  {invalidRouteId === true && (
                    <div className="red">
                      Por favor selecione un cliente dentro de las opciones.
                    </div>
                  )}
                </div>
                <div className="client-select">
                  <div>Día de visita</div>
                  {/* <span className="icon-dollar">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </span> */}
                  {/* <input
                    required
                    name="price"
                    placeholder="0.00"
                    step="0.000001"
                    onChange={handleInputPriceChange.bind(this)}
                    type="number"
                  /> */}
                  <select
                    // onChange={handleInputPriceChange.bind(this)}
                    className="select-promo-type"
                    name="visitDay"
                    required
                    style={{paddingRight: '10px'}}
                  >
                    <option value="" hidden>
                      Escoger una opcion
                    </option>
                    <option value="0">Lunes</option>
                    <option value="1">Martes</option>
                    <option value="2">Miercoles</option>
                    <option value="3">Jueves</option>
                    <option value="4">Viernes</option>
                    <option value="5">Sabado</option>
                    <option value="6">Domingo</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        )}
        {addNewRoute === false && (
          <div className="show-header show-routes-header">
            <h2 className="show-routes-subtitle">Clientes asignados a usuario:</h2>
            <div className="show-route-buttons">
                <input
                type="submit"
                onClick={startAddingNewRoute.bind(this)}
                value="Asignar cliente"
                />
            </div>
          </div>
        )}
        <div className="mt-50">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>CÓDIGO</th>
                <th>NOMBRE</th>
                <th>DÍA DE VÍSITA</th>
                <th className="text-align-center">ACCION</th>
              </tr>
            </thead>
            <tbody>
              {/* {this.state.isFetching === false && this.state.RoutesData && (
                <>
                  {this.state.priceListsData['table'].length === 0 && (
                    <tr>
                      <td colSpan="4">No hay listas de precio asignada a este producto.</td>
                    </tr>
                  )}
                  {this.state.priceListsData['table'].length > 0 &&
                    this.state.priceListsData['table'].map((obj) => (
                      <ProductPriceListRow key={obj.id} obj={obj} id={this.props.id} />
                    ))}
                </>
              )} */}
              <tr>
                <td>12345</td>
                <td>Distribuidora 1</td>
                <td>Todos los dias</td>
                <td className="text-align-center">
                    <button
                        // onClick={this.unassignRoute.bind(this)}
                        className="unassign-price-list-button"
                        type="button"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </td>
              </tr>
              <tr>
                <td>12346</td>
                <td>Distribuidora 2</td>
                <td>Todos los dias</td>
                <td className="text-align-center">
                    <button
                        // onClick={this.unassignRoute.bind(this)}
                        className="unassign-price-list-button"
                        type="button"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </td>
              </tr>
              <tr>
                <td>12347</td>
                <td>Distribuidora 3</td>
                <td>Todos los dias</td>
                <td className="text-align-center">
                    <button
                        // onClick={this.unassignRoute.bind(this)}
                        className="unassign-price-list-button"
                        type="button"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </td>
              </tr>
              <tr>
                <td>12348</td>
                <td>Distribuidora 4</td>
                <td>Todos los dias</td>
                <td className="text-align-center">
                    <button
                        // onClick={this.unassignRoute.bind(this)}
                        className="unassign-price-list-button"
                        type="button"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </td>
              </tr>
              <tr>
                <td>12349</td>
                <td>Distribuidora 5</td>
                <td>Todos los dias</td>
                <td className="text-align-center">
                    <button
                        // onClick={this.unassignRoute.bind(this)}
                        className="unassign-price-list-button"
                        type="button"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </td>
              </tr>
              {isFetching === false && (
                <tr>
                  <td colSpan="4">Obteniendo datos...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
}

export default UserRoutesDetailTab

import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faArrowCircleDown, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import cookie from 'react-cookies'
import { CSVLink, CSVDownload } from 'react-csv'
import { useHistory } from 'react-router-dom'
import { MapModalProvider } from '../hook/MapModalProvider'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const ChargeEffectivenessIndexView = (props) => {
  const history = useHistory()

  const [headers] = useState([
    'Código',
    'Cobrador',
    'Hora de última visita',
    'Clientes visitados',
    'Clientes con cobros',
    'Anulados',
    'Efectividad',
    'Total de monto'
  ])

  const getCurrentDate = () => new Date().getFullYear() + '-' + getMonth() + '-' + getDay()

  const getMonth = () => {
    const m = new Date().getMonth() + 1
    if (m < 10) {
      return '0' + m
    } else {
      return m
    }
  }
  const getDay = () => {
    const d = new Date().getDate()
    if (d < 10) {
      return '0' + d
    } else {
      return d
    }
  }

  const getStartDate = () => {
    if (window.location.href.indexOf('start_date') >= 0) {
      const startDay = window.location.href.split('start_date=')
      return startDay[startDay.length - 1].split('&')[0]
    } else {
      return getCurrentDate()
    }
  }

  const getEndDate = () => {
    if (window.location.href.indexOf('end_date') >= 0) {
      return window.location.href.split('end_date=')[
        window.location.href.split('end_date=').length - 1
      ]
    } else {
      return getCurrentDate()
    }
  }

  const searchInput = React.createRef()
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [startDate, setStartDate] = useState(getStartDate())
  const [endDate, setEndDate] = useState(getEndDate())
  const [invalidRangeLimit, setInvalidRangeLimit] = useState(false)
  const [invalidRangeFormat, SetInvalidRangeFormat] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const handleChange = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      setSearchTerm(event.target.value)
    }
  }

  useEffect(() => {
    async function fetchData() {
      await fetchChargesDataFromServer()
    }

    fetchData()
  }, [])

  useEffect(() => {
    const results = data.filter((charge) => {
      const poetName =
        charge.user_db_ref.toLowerCase() +
        charge.user_name.toLowerCase() +
        charge.route_db_ref?.toLowerCase()
      return poetName.indexOf(searchTerm.toLowerCase()) !== -1
    })
    setSearchResults(results)
  }, [searchTerm, data])

  const fetchChargesDataFromServer = () => {
    fetch(
      BACKEND_URL +
        '/businesses/1/reports/charge_effectiveness?start_date=' +
        startDate +
        '&end_date=' +
        endDate +
        '&q=' +
        searchTerm +
        '&business_tenant_uid=' +
        cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setData(data['result'])
        setIsFetching(false)
      })
      .catch((e) => console.log(e))
  }

  const handleShowChargeEffectivenessDetails = (uid, event) => {
    event.preventDefault()
    history.push(
      '/visitas_de_cobro_por_usuario?db_ref=' +
        uid +
        '&start_date=' +
        startDate +
        '&end_date=' +
        endDate
    )
  }

  const searchFromDates = (event) => {
    const start = document.getElementsByName('start_date')[0].value
    const end = document.getElementsByName('end_date')[0].value
    const start_date = new Date(start)
    const end_date = new Date(end)
    const diff_in_time = end_date.getTime() - start_date.getTime()
    // To calculate the no. of days between two dates
    const diff_in_days = diff_in_time / (1000 * 3600 * 24)
    // Validations
    if (start_date > end_date) {
      SetInvalidRangeFormat(true)
      setInvalidRangeLimit(false)
    } else if (diff_in_days > 90) {
      SetInvalidRangeFormat(false)
      setInvalidRangeLimit(true)
    } else {
      SetInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      window.location.href = 'efectividad_de_cobros?start_date=' + start + '&end_date=' + end
    }
  }

  const deleteSearch = () => {
    setSearchTerm('')
    searchInput.current.value = ''
  }

  const getTimestampFromDateTime = (date) => {
    const d = new Date(date)
    return d.toLocaleString()
  }

  const buildData = () => {
    if (searchResults != null) {
      const array = searchResults.map((a) => [
        a.route_db_ref,
        a.user_name,
        a.last_visit,
        a.no_visited_clients,
        a.no_clients_with_charges,
        a.no_cancelled_charges,
        a.efectividad,
        a.total
      ])
      array.splice(0, 0, headers)
      array.splice(0, 0, [])
      array.splice(0, 0, ['Fecha', startDate + ' - ' + endDate])
      array.splice(0, 0, ['Supervisor', cookie.load('user_name')])
      array.splice(0, 0, ['Reporte:', 'Efectividad de Cobro'])
      return array
    }
  }

  if (isFetching) {
    return (
      <div className="main-view">
        <div className="index-header">
          <br />
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="main-view">
        {invalidRangeLimit && (
          <div className="alert alert-danger" role="alert">
            <strong>
              ERROR: Rango de fechas escogidas pasan de los 90 días. Por favor escoger otra fecha.
            </strong>
          </div>
        )}
        {invalidRangeFormat && (
          <div className="alert alert-danger" role="alert">
            <strong>ERROR: Fecha de inicio debe ser antes que la fecha de fin.</strong>
          </div>
        )}
        <div className="index-header">
          <h2 className="mb-20 float-unset">{props.title}</h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div className="range-dates">
              <span style={{ color: '#4D4845', fontSize: '18px', marginRight: '15px' }}>Desde</span>
              <input
                max={getCurrentDate()}
                defaultValue={startDate}
                className="select-date-report"
                type="date"
                name="start_date"
                required
              />
              <span style={{ color: '#4D4845', fontSize: '18px', marginRight: '15px' }}>
                {' '}
                - Hasta
              </span>
              <input
                max={getCurrentDate()}
                defaultValue={endDate}
                className="select-date-report"
                type="date"
                name="end_date"
                required
              />
              <button onClick={searchFromDates} className="search-range-dates-btn">
                Buscar
              </button>
              <CSVLink
                className="btn btn-default download-report-button"
                data={buildData()}
                filename={
                  'ReporteEfectividadCobros_' +
                  cookie.load('business_name') +
                  '_' +
                  getCurrentDate() +
                  '.csv'
                }
              >
                Descargar reporte
                <FontAwesomeIcon icon={faArrowCircleDown} />
              </CSVLink>
            </div>
            <h5>
              <FontAwesomeIcon icon={faExclamationCircle} />
              Nota: Solo puedes escoger un rango de fechas de un maximo de 90 dias.
            </h5>
            <hr />
            <div className="show-header">
              <div className="col-xs-6">
                <h2>Detalles de reporte de cobros diarias:</h2>
              </div>
              <div className="col-xs-6">
                <div className="input-group show-buttons">
                  <input
                    autoComplete="off"
                    ref={searchInput}
                    onKeyDown={handleChange}
                    className="search-price-list-products"
                    placeholder="Buscar cobrador por código o nombre"
                  />
                  {searchTerm === '' && (
                    <span className="glyphicon glyphicon-search form-control-feedback" />
                  )}
                  {searchTerm !== '' && (
                    <span className="delete-search-icon" onClick={deleteSearch}>
                      &times;
                    </span>
                  )}
                </div>
              </div>
            </div>
            <hr className="mt-90" />
            <div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>CODIGO</th>
                    <th>COBRADOR</th>
                    <th>FECHA Y HORA DE ULTIMA ACTIVIDAD</th>
                    <th>CLIENTES VISITADOS</th>
                    <th>CLIENTES CON COBROS</th>
                    <th>ANULADOS</th>
                    <th>EFECTIVIDAD</th>
                    <th>TOTAL DE MONTO</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.length > 0 &&
                    searchResults.map((model, index) => (
                      <tr
                        className="cursor-pointer"
                        key={index}
                        onClick={(event) =>
                          handleShowChargeEffectivenessDetails(model.user_db_ref, event)
                        }
                      >
                        <td>{model['route_db_ref']}</td>
                        <td>{model['user_name']}</td>
                        <td>
                          {model['last_visit'] === null
                            ? '-'
                            : getTimestampFromDateTime(model['last_visit'])}
                        </td>
                        <td>{model['no_visited_clients']}</td>
                        <td>{model['no_clients_with_charges']}</td>
                        <td>{model['no_cancelled_charges']}</td>
                        <td>
                          {model['no_clients_with_charges'] === 0
                            ? 0
                            : (
                                (model['no_clients_with_charges'] / model['no_visited_clients']) *
                                100
                              ).toFixed(2)}{' '}
                          %
                        </td>
                        <td>${parseFloat(model['total']).toFixed(2)}</td>
                      </tr>
                    ))}
                  {searchResults.length === 0 && (
                    <tr>
                      <td colSpan="7">No hubo resultados para esta fecha.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChargeEffectivenessIndexView

import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { formatDateNoHours } from '../../../helpers/utils'

const InvalidDateRangeModal = ({ show, handleClose, startDate, endDate, username }) => (
  <Modal
    show={show}
    dialogClassName="modal-90w close-button-modal-maps"
    onHide={handleClose}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header closeButton>
      <Modal.Title id="example-modal-sizes-title-lg">
        <p>
          <b>Mapa de Geocercas</b>
        </p>
        <p className="text-title-modal-map">Usuario: {username}</p>
        <p className="text-title-modal-map">
          Fecha: {formatDateNoHours(startDate)} - {formatDateNoHours(endDate)}
        </p>
        <p className="text-title-modal-map">Hora de última actividad: -</p>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="map-modal-error">
        <div className="map-error-label">
          <p>No se puede mostrar un rango de fechas personalizado en el mapa de las geocercas.</p>
          <p>Por favor, elige un solo día para ver las visitas.</p>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
)

export default InvalidDateRangeModal

import React, { useContext } from 'react'
import { getDay, getMonth } from '../../../helpers/formatDate'
import { BonusPromoContext } from '../../context/BonusPromoContext'

const DateAndDescriptionSection = ({cloningPromo, promoType, promoScaleArray, scaleArray, startTime, setStartTime, endTime, setEndTime, description, setDescription}) => {

    const {bonusProducts} = useContext(BonusPromoContext)

    const handleStartTimeChange = (event) => {
        event.preventDefault()
        const obj = {}
        obj[event.target.name] = event.target.value
        setStartTime(obj.startTime)
    }
    
    const handleEndTimeChange = (event) => {
        event.preventDefault()
        const obj = {}
        obj[event.target.name] = event.target.value
        setEndTime(obj.endTime)
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }


    if (cloningPromo) {
      return null
    }
    return (
      <div className="promo-card">
        <div className="title-promos-form">
        {promoType === 'discount'? <b>5. Fecha y descripción</b>
          :<b>6. Fecha y descripción</b>}
          
        </div>
        <hr className="hr-promo-card" />
        {(bonusProducts[0]?.bonus_quantity == null || bonusProducts[0]?.bonus_quantity == 0) && promoScaleArray[0]?.bonus_qty == 0 && scaleArray[0]?.discount == 0 && (
          <>
            {promoType === 'discount'? <p className='promo-card-subtitle'>5.1: Escoja la fecha de inicio y fecha final de la promoción</p>
            :<p className='promo-card-subtitle'>6.1: Escoja la fecha de inicio y fecha final de la promoción</p>}
            <div className="d-flex align-flex-center">
              <div className="float-left">
                <label className="promo-card-heading">Fecha inicio</label>
                <br />
                <input
                  min={new Date().getFullYear() + '-' + getMonth() + '-' + getDay()}
                  onChange={handleStartTimeChange}
                  className="start-date inactive"
                  type="date"
                  name="startTime"
                  required
                  disabled
                />
              </div>
              <div className="dates-separator-2"></div>
              <div className="float-right">
                <label className="promo-card-heading">Fecha final</label>
                <br />
                <input
                  min={startTime}
                  onChange={handleEndTimeChange}
                  className="end-date inactive"
                  type="date"
                  name="endTime"
                  required
                  disabled
                />
              </div>
            </div>
            {promoType === 'discount'? <p className='promo-card-subtitle mt-20'>5.2: Agregue una descripción o nombre para la promoción</p>
            :<p className='promo-card-subtitle mt-20'>6.2: Agregue una descripción o nombre para la promoción</p>}
            <div className="form-group form-control-width">
              <input
                type="text"
                maxLength="40"
                className="form-control inactive"
                id="description"
                name="description"
                onChange={handleDescriptionChange}
                value={description}
                placeholder="Ejemplo: Promoción Surtida Productos Marca Ubiqua"
                required
                disabled
              />
              <p className="small text-muted m-0 pull-right">
                Cantidad máxima de caracteres {Math.abs(description.length - 40)}/40
              </p>
            </div>
          </>
        )}
        {(bonusProducts[0]?.bonus_quantity > 0 || promoScaleArray[0]?.bonus_qty > 0 || scaleArray[0]?.discount > 0) && (
          <>
            {promoType === 'discount'? <p className='promo-card-subtitle'>5.1: Escoja la fecha de inicio y fecha final de la promoción</p>
            :<p className='promo-card-subtitle'>6.1: Escoja la fecha de inicio y fecha final de la promoción</p>}
            <div className="d-flex align-flex-center">
              <div className="float-left">
                <label className="promo-card-heading">Fecha inicio</label>
                <br />
                <input
                  min={new Date().getFullYear() + '-' + getMonth() + '-' + getDay()}
                  onChange={handleStartTimeChange}
                  className="start-date"
                  type="date"
                  name="startTime"
                  required
                />
              </div>
              <div className="dates-separator-2"></div>
              <div className="float-right">
                <label className="promo-card-heading">Fecha final</label>
                <br />
                <input
                  min={startTime}
                  onChange={handleEndTimeChange}
                  className="end-date"
                  type="date"
                  name="endTime"
                  required
                />
              </div>
            </div>
            {promoType === 'discount'? <p className='promo-card-subtitle mt-20'>5.2: Agregue una descripción o nombre para la promoción</p>
            :<p className='promo-card-subtitle mt-20'>6.2: Agregue una descripción o nombre para la promoción</p>}
            <div className="form-group form-control-width">
              <input
                type="text"
                maxLength="40"
                className="form-control"
                id="description"
                name="description"
                onChange={handleDescriptionChange}
                value={description}
                placeholder="Ejemplo: Promoción Surtida Productos Marca Ubiqua"
                required
              />
              <p className="small text-muted m-0 pull-right">
                Cantidad máxima de caracteres {Math.abs(description.length - 40)}/40
              </p>
            </div>
          </>
        )}
      </div>
    )
}
export default DateAndDescriptionSection
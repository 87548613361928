import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import axios from 'axios'
import cookie from 'react-cookies'
import { RRule, SPANISH } from 'rrule'
import { fixDateString, getDay, getMonth } from '../../helpers/formatDate'
import { replaceAccentedCharacters } from '../../helpers/utils'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const PriceCaptureTask = ({
  setCloningTask,
  cloningTask,
  taskData,
  readyToCollect,
  setSaving,
  setReadyToCollect,
  formDataCollector,
  setShowProductAlert,
  isStep3Completed,
  setIsStep3Completed
}) => {
  const [description, setDescription] = useState('')
  const [clientOrAttribute, setClientOrAttribute] = useState('')
  const [clients, setClients] = useState([])
  const [products, setProducts] = useState([])
  const [productSelection, setProductSelection] = useState('')
  const [qualifiers, setQualifiers] = useState({
    qualifier_2: [],
    qualifier_3: [],
    qualifier_4: [],
    qualifier_5: []
  })
  const [qualifierA, setQualifierA] = useState([])
  const [qualifierB, setQualifierB] = useState([])
  const [qualifierC, setQualifierC] = useState([])
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [undefinedEndTime, setUndefinedEndTime] = useState(true)
  const [recurrenceSelection, setRecurrenceSelection] = useState('')
  const [recurrenceInterval, setRecurrenceInterval] = useState(1)
  const [recurrenceWeekDaysSelection, setRecurrenceWeekDaysSelection] = useState([])
  const [productsBase, setProductsBase] = useState({})
  const [unitOfSale, setUnitOfSale] = useState('')
  const [prodName, setProdName] = useState('')
  const [prodPck, setProdPck] = useState('')
  const [competitionProducts, setCompetitionProducts] = useState([])
  const [isUnitOfSale1Available, setIsUnitOfSale1Available] = useState(false)
  const [isUnitOfSale2Available, setIsUnitOfSale2Available] = useState(false)
  const [prodNameError, setProdNameError] = useState('')
  const [prodPckError, setProdPckError] = useState('')
  const [openSelector, setOpenSelector] = useState('')

  const recurrenceSelectionType = [
    { value: '', text: 'Escoja una opción', hidden: true },
    { value: 'daily', text: 'Todos los días' },
    { value: 'weekly', text: 'Semanal' }
  ]

  const recurrenceWeekDays = [
    { rrlue: RRule.MO, value: 'Lunes', text: 'L' },
    { rrlue: RRule.TU, value: 'Martes', text: 'M' },
    { rrlue: RRule.WE, value: 'Miércoles', text: 'M' },
    { rrlue: RRule.TH, value: 'Jueves', text: 'J' },
    { rrlue: RRule.FR, value: 'Viernes', text: 'V' },
    { rrlue: RRule.SA, value: 'Sábado', text: 'S' },
    { rrlue: RRule.SU, value: 'Domingo', text: 'D' }
  ]

  const onChangeRadios = (event) => {
    if (event.target.value === 'true') {
      setUndefinedEndTime(true)
      setEndTime(null)
    } else {
      setUndefinedEndTime(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      increaseRecurrenceInterval()
    } else if (e.keyCode === 40) {
      decreaseRecurrenceInterval()
    }
  }

  const handleToggleDay = (day) => {
    const dayVal = day?.value
    const recurrenceWeekDaySelection = recurrenceWeekDays.find((day) => day.value === dayVal)
    if (!recurrenceWeekDaySelection) {
      return
    }

    const index = recurrenceWeekDaysSelection.findIndex(
      (day) => day.value === recurrenceWeekDaySelection.value
    )
    if (index === -1) {
      setRecurrenceWeekDaysSelection([...recurrenceWeekDaysSelection, recurrenceWeekDaySelection])
    } else {
      let arr = [...recurrenceWeekDaysSelection]
      arr.splice(index, 1)
      setRecurrenceWeekDaysSelection(arr)
    }
  }

  const onChangeRecurrenceInterval = (event) => {
    let strNum = event?.target?.value
    if (!strNum) {
      setRecurrenceInterval(1)
      return
    }

    let num = Number(strNum)
    if (isNaN(num)) return
    num = Math.max(1, Math.min(99, num))
    setRecurrenceInterval(num)
  }

  const handleRecurrenceTypeChange = (event) => {
    if (event?.target?.value) {
      setRecurrenceSelection(event?.target?.value)
    }
  }

  const handleStartTimeChange = (event) => {
    if (event?.target?.value) {
      setStartTime(event?.target?.value)
    }
  }

  const handleEndTimeChange = (event) => {
    if (event?.target?.value) {
      setEndTime(event?.target?.value)
    }
  }

  const handleChangeQualifierA = (event) => {
    if (event != null) {
      setQualifierA(event.map((e) => e.value))
    }
  }

  const handleChangeQualifierB = (event) => {
    if (event != null) {
      setQualifierB(event.map((e) => e.value))
    }
  }

  const handleChangeQualifierC = (event) => {
    if (event != null) {
      setQualifierC(event.map((e) => e.value))
    }
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleProdNameChange = (event) => {
    setProdName(event.target.value)
    if (event.target.value.trim()) {
      setProdNameError('')
    }
  }

  const handleProdPckChange = (event) => {
    setProdPck(event.target.value)
    if (event.target.value.trim()) {
      setProdPckError('')
    }
  }

  const handleAddCompetitionProduct = (event) => {
    event.preventDefault()

    const trimmedProdName = prodName.trim()
    const trimmedProdPck = prodPck.trim()

    setProdNameError('')
    setProdPckError('')

    if (!trimmedProdName) {
      setProdNameError('Ingrese el nombre del producto.')
    }

    if (!trimmedProdPck) {
      setProdPckError('Ingrese la unidad de venta.')
    }

    if (trimmedProdName && trimmedProdPck) {
      setCompetitionProducts((competitionProducts) => [
        ...competitionProducts,
        { uid: crypto.randomUUID(), name: trimmedProdName, package: trimmedProdPck }
      ])
      resetCompetitionProductInputs()
    }
  }

  const resetCompetitionProductInputs = () => {
    setProdName('')
    setProdPck('')
  }

  const isAnyKindOfProductSelected = () => {
    return products?.length > 0 ? true : false
  }

  const isClientOrSomeQualifierSelected = () => {
    return clients?.length > 0 || isSomeQualifierSelected()
  }
  const isSomeQualifierSelected = () => {
    return qualifierA?.length > 0 || qualifierB?.length > 0 || qualifierC?.length > 0
  }

  const noAvailableQualifiers = () => {
    return (
      (!qualifierAOptions?.length || qualifierAOptions?.length === 0) &&
      (!qualifierBOptions?.length || qualifierBOptions?.length === 0) &&
      (!qualifierCOptions?.length || qualifierCOptions?.length === 0)
    )
  }

  const handleMenuOpen = (selectorName) => {
    setOpenSelector(selectorName)
  }

  const handleMenuClose = () => {
    setOpenSelector('')
  }

  const handleClientOrAttributeChange = (event) => {
    setClientOrAttribute(event.target.value)
    setClients([])
    setQualifierA([])
    setQualifierB([])
    setQualifierC([])
  }

  const handleUnitOfSaleChange = (event) => {
    setUnitOfSale(event.target.value)
  }

  const resolveEndTime = () => {
    if (undefinedEndTime) {
      return (
        <>
          de forma <span className="highlight-blue">indefinida</span>
        </>
      )
    }
    if (endTime != '') {
      return (
        <>
          hasta el <span className="highlight-blue">{getFormattedDateOnly(endTime)}</span>
        </>
      )
    }
  }

  const increaseRecurrenceInterval = () => {
    onChangeRecurrenceInterval({ target: { value: recurrenceInterval + 1 } })
  }

  const decreaseRecurrenceInterval = () => {
    onChangeRecurrenceInterval({ target: { value: recurrenceInterval - 1 } })
  }

  const getFormattedDateOnly = (date) => {
    const d = new Date(fixDateString(date))
    return d.toLocaleDateString()
  }

  const truncatePreviewText = (text) => {
    return text?.length > 15 ? text.substring(0, 15) + '...' : text
  }

  const buildRecurrence = () => {
    let rule
    switch (recurrenceSelection) {
      case 'daily':
        rule = new RRule({
          freq: RRule.DAILY
        })
        break
      case 'weekly':
        rule = new RRule({
          freq: RRule.WEEKLY,
          interval: recurrenceInterval,
          byweekday: recurrenceWeekDaysSelection.map((day) => day.rrlue)
        })
        break
    }
    return rule
  }

  const buildRecurrenceMessage = () => {
    let rule = buildRecurrence()
    if (rule && startTime) {
      return (
        <>
          Esta tarea se ejecutará <span className="highlight-blue">{rule.toText(SPANISH)}</span> a
          partir del <span className="highlight-blue">{getFormattedDateOnly(startTime)}</span>{' '}
          {resolveEndTime()}
        </>
      )
    }
  }

  const getClient = async (inputValue) => {
    await sleepWaitResponse(1000)
    return fetch(`${BACKEND_URL}/businesses/1/clients?q=${inputValue}&active=true`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) =>
        data.table.map((cl) => ({
          value: cl.name,
          label: `${cl.name} - ${cl.db_ref}`,
          client: cl
        }))
      )
      .catch((error) => {
        console.error('Error fetching clients:', error)
        return []
      })
  }

  const getQualifiers = () => {
    fetch(BACKEND_URL + '/promo_qualifiers', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setQualifiers(data.result)
      })
      .catch((e) => console.log(e))
  }

  const validateAtLeastOneQualifier = (_qualifierA, _qualifierB, _qualifierC) => {
    return _qualifierA?.length > 0 || _qualifierB?.length > 0 || _qualifierC?.length > 0
      ? 'success'
      : null
  }

  const getProductsBaseData = () => {
    fetch(BACKEND_URL + '/businesses/1/products/new', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setProductsBase(data)
      })
      .catch((e) => console.log(e))
  }

  const qualifierAOptions = qualifiers?.qualifier_2.map((value) => ({
    value: value,
    label: value,
    qualifier_2: value
  }))

  const qualifierBOptions = qualifiers?.qualifier_3.map((value) => ({
    value: value,
    label: value,
    qualifier_3: value
  }))

  const qualifierCOptions = qualifiers?.qualifier_4.map((value) => ({
    value: value,
    label: value,
    qualifier_4: value
  }))

  const getProducts = async (inputValue) => {
    await sleepWaitResponse(1000)

    return fetch(
      `${BACKEND_URL}/businesses/1/products?q=${replaceAccentedCharacters(
        inputValue
      )}&productactive=true`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        return data.table.map((p) => ({
          value: p.name,
          label: `${p.name} - ${p.db_ref}`,
          product: p
        }))
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const handleClientOption = (options) => {
    setClients(options)
  }

  const handleProductOption = (options) => {
    if (!options?.product) {
      return
    }
    if (products.find((e) => e.id === options.product.id)) {
      return
    }
    setProducts([...products, options.product])
  }

  const sleepWaitResponse = (ms) =>
    // eslint-disable-next-line no-undef
    new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })

  const getTaskQuantity = () => {
    let taskQuantity = 0
    if (products?.length > 0) {
      taskQuantity += products.length
    }
    if (competitionProducts?.length > 0) {
      taskQuantity += competitionProducts.length
    }
    return taskQuantity
  }

  const collectData = () => {
    let rule = buildRecurrence()
    let jsonData = {
      description: description,
      recurrence_format: rule.toString(),
      recurrence_interval: recurrenceInterval,
      recurrence_description: rule.toText(SPANISH),
      start_date: fixDateString(startTime),
      end_date: fixDateString(endTime),
      qualifier_1: clients.map((c) => c.client.db_ref).sort(),
      qualifier_2: qualifierA,
      qualifier_3: qualifierB,
      qualifier_4: qualifierC,
      products_db_ref: products.map((p) => p.db_ref).sort(),
      product_names: products.map((p) => p.db_ref + ' ' + p.name).sort(),
      unit_of_sale: unitOfSale,
      task_quantity: getTaskQuantity(),
      competition_products: competitionProducts
    }
    formDataCollector(jsonData)
  }

  const setUpTask = async () => {
    if (taskData) {
      setCloningTask(true)
      setDescription(taskData.description)

      if (taskData.client_names?.length > 0) {
        setClientOrAttribute('client')
        let _clients = []
        for (const clientName of taskData.qualifier_1) {
          let c = await getClient(clientName)
          if (c?.length > 0) {
            _clients.push(c[0])
          }
        }
        setClients(_clients)
      } else {
        setClientOrAttribute('qualifier')
        if (qualifierAOptions?.length > 0) {
          setQualifierA(taskData.qualifier_2)
        }
        if (qualifierBOptions?.length > 0) {
          setQualifierB(taskData.qualifier_3)
        }
        if (qualifierCOptions?.length > 0) {
          setQualifierC(taskData.qualifier_4)
        }
      }

      let _products = []
      for (const productDbRef of taskData.products_db_ref) {
        let p = await getProducts(productDbRef)
        if (p?.length > 0) {
          _products.push(p[0]?.product)
        }
      }
      let cleanProducts = _products.filter(
        (value, index, self) => index === self.findIndex((t) => t.db_ref === value.db_ref)
      )
      setProducts(cleanProducts)

      setUnitOfSale(taskData.unit_of_sale)
      setCompetitionProducts(taskData.competition_products)
      setCloningTask(false)
      await sleepWaitResponse(1000)
      setSaving(false)
    }
  }

  const checkUnitsOfSaleAvailability = () => {
    const hasUnitOfSale1 =
      products.length > 0 &&
      products.every(
        (product) =>
          product.packages[0] && product.packages[0].unit && product.packages[0].unit !== '-'
      )
    const hasUnitOfSale2 =
      products.length > 0 &&
      products.every(
        (product) =>
          product.packages[1] && product.packages[1].unit && product.packages[1].unit !== '-'
      )
    setIsUnitOfSale1Available(hasUnitOfSale1)
    setIsUnitOfSale2Available(hasUnitOfSale2)
  }

  const validateProductSelection = () => {
    if (products.length === 0 && competitionProducts.length === 0) {
      setShowProductAlert(true)
      return false
    }
    setShowProductAlert(false)
    return true
  }

  useEffect(() => {
    const isStep3Completed =
      clients.length > 0 || qualifierA.length > 0 || qualifierB.length > 0 || qualifierC.length > 0

    setIsStep3Completed(isStep3Completed)
  }, [clients, qualifierA, qualifierB, qualifierC])

  useEffect(() => {
    checkUnitsOfSaleAvailability()
  }, [products])

  useEffect(() => {
    getQualifiers()
    getProductsBaseData()
  }, [])

  useEffect(() => {
    if (taskData) {
      setUpTask()
    }
  }, [taskData])

  useEffect(() => {
    if (readyToCollect) {
      if (!validateProductSelection()) {
        setShowProductAlert(true)
        setSaving(false)
        setReadyToCollect(false)
      } else {
        collectData()
      }
    }
  }, [readyToCollect])

  if (cloningTask) {
    return null
  }
  return (
    <>
      <div className="promo-form-section">
        <div className="title-promos-form">
          <b>
            PASO 2: Escriba una descripción de la tarea que será visible para sus colaboradores en
            la aplicación
          </b>
        </div>
        <hr className="hr-promos-form" />
        <div className="form-group form-control-width">
          <input
            type="text"
            maxLength="40"
            className="form-control"
            id="description"
            name="description"
            onChange={handleDescriptionChange}
            value={description}
            placeholder="Ejemplo: Tarea marca Ubiqua"
            required
          />
          <p className="small text-muted m-0 pull-right">
            Cantidad máxima de caracteres {Math.abs(description.length - 40)}/40
          </p>
        </div>
      </div>
      <div className="promo-form-section">
        <div className="title-promos-form">
          <b>PASO 3: Escoja a qué clientes se debe aplicar esta tarea</b>
        </div>
        <hr className="hr-promos-form" />
        <p>Por cliente individual o Atributo(s)</p>

        <div>
          <div className="form-control-width">
            <select
              onChange={handleClientOrAttributeChange}
              className={`select-appliesType form-control-width ${
                description === '' && 'inactive'
              }`}
              name="applies_target_to_type"
              value={clientOrAttribute}
              disabled={description === ''}
              required
            >
              <option value="" hidden>
                Escoja una opción
              </option>
              <option value="client">Cliente individual</option>
              {!noAvailableQualifiers() && <option value="qualifier">Atributo(s)</option>}
            </select>
          </div>
        </div>
      </div>

      {clientOrAttribute === 'client' && (
        <div className="promo-form-section">
          <div className="title-promos-form">
            <p>
              <b>PASO 3.1: Escoja los clientes que aplican para esta tarea</b>
            </p>
          </div>
          <div
            className="fake-validator-container"
            style={{ zIndex: openSelector === 'client' ? 5000 : 1 }}
          >
            <input
              className="input-fake-validator"
              type="text"
              required
              value={clients.length >= 1 ? clients : null}
            />
            <AsyncSelect
              className="clients"
              placeholder={'Buscar cliente por nombre o código'}
              noOptionsMessage={() => 'No hubo resultados'}
              loadingMessage={() => 'Cargando clientes...'}
              name="selectOptionClients"
              loadOptions={getClient}
              isClearable={true}
              searchParamName={'q'}
              isSearchable={true}
              isMulti
              value={clients}
              onChange={(e) => handleClientOption(e)}
            />
          </div>
        </div>
      )}

      {clientOrAttribute === 'qualifier' && (
        <div className="promo-form-section step3">
          <div className="title-promos-form">
            <b>
              PASO 3.1: Escoja los atributos que deben de tener los clientes para activar esta
              tarea. Mínimo debe de escoger 1 atributo
            </b>
          </div>
          <div className="qualifier-title">Atributo A</div>
          <div className="fake-validator-container" style={{ zIndex: 3 }}>
            {qualifierAOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierAOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={(e) => handleChangeQualifierA(e)}
              options={qualifierAOptions}
              value={qualifierA?.map((q) => ({ value: q, label: q }))}
            />
          </div>
          <div className="qualifier-title">Atributo B</div>
          <div className="fake-validator-container" style={{ zIndex: 2 }}>
            {qualifierCOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierBOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={(e) => handleChangeQualifierB(e)}
              options={qualifierBOptions}
              value={qualifierB?.map((q) => ({ value: q, label: q }))}
            />
          </div>
          <div className="qualifier-title">Atributo C</div>
          <div className="fake-validator-container">
            {qualifierCOptions.length > 0 && (
              <input
                className="input-fake-validator"
                type="text"
                required
                value={validateAtLeastOneQualifier(qualifierA, qualifierB, qualifierC)}
              />
            )}
            <Select
              placeholder={'Buscar o escoger una opcion'}
              noOptionsMessage={() => 'Buscar'}
              loadingMessage={() => 'Cargando...'}
              className="qualifier"
              isDisabled={qualifierCOptions?.length === 0}
              isClearable={true}
              isMulti
              onChange={(e) => handleChangeQualifierC(e)}
              options={qualifierCOptions}
              value={qualifierC?.map((q) => ({ value: q, label: q }))}
            />
          </div>
        </div>
      )}

      <div className="promo-form-section">
        <div className="title-promos-form">
          <b>PASO 4: Escoja los productos (propios y/o de competencia) que aplican a esta tarea</b>
        </div>
        <p>
          <b>Nota: </b>Asegúrese de incluir al menos un producto propio o uno de la competencia para
          continuar.
        </p>
        <hr className="hr-promos-form" />
      </div>

      <div className="promo-form-section step4">
        <div className="title-promos-form">
          <p>
            <b>
              <span style={{ fontStyle: 'italic' }}>Opcional</span> - PASO 4.1: Escoja los
              producto(s) propio(s) que aplican para esta tarea
            </b>
          </p>
        </div>
        <div className="form-control-width">
          <div
            className="fake-validator-container"
            style={{ zIndex: openSelector === 'product' ? 5000 : 1 }}
          >
            <input
              className={`input-fake-validator ${!isClientOrSomeQualifierSelected() && 'inactive'}`}
              type="text"
              value={products.length >= 1 ? products : null}
              disabled={!isClientOrSomeQualifierSelected()}
            />
            <AsyncSelect
              className={`clients ${!isClientOrSomeQualifierSelected() && 'inactive'} `}
              placeholder={'Escribir nombre o codigo del producto'}
              noOptionsMessage={() => 'No hubo resultados'}
              loadingMessage={() => 'Cargando productos...'}
              name="selectOptionProducts"
              disabled={!isClientOrSomeQualifierSelected()}
              loadOptions={getProducts}
              isClearable={true}
              searchParamName={'q'}
              isSearchable={true}
              value={[]}
              onChange={(e, am) => {
                handleProductOption(e)
                am.action = 'clear'
              }}
              onMenuOpen={() => handleMenuOpen('product')}
              onMenuClose={handleMenuClose}
            />
          </div>
        </div>
        {products?.length > 0 && (
          <div className="promo-form-section row">
            <table className="table table-hover promos-product-table">
              <thead>
                <tr>
                  <th className="pl-20">CODIGO</th>
                  <th>NOMBRE DEL PRODUCTO</th>
                  <th>UNIDAD DE VENTA 1</th>
                  <th>UNIDAD DE VENTA 2</th>
                  <th>ACCIÓN</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={`${product.id}-${index}`}>
                    <td className="pl-20">{product.db_ref}</td>
                    <td>{product.name}</td>
                    <td>
                      {product.packages[0]?.unit && (
                        <>
                          {product.packages[0]?.unit}{' '}
                          {product.packages[0]?.quantity > 1
                            ? `de ${product.packages[0]?.quantity}`
                            : ''}
                        </>
                      )}
                      {!product.packages[0]?.unit && '-'}
                    </td>
                    <td>
                      {product.packages[1]?.unit && (
                        <>
                          {product.packages[1]?.unit}{' '}
                          {product.packages[1]?.quantity > 1
                            ? `de ${product.packages[1]?.quantity}`
                            : ''}
                        </>
                      )}
                      {!product.packages[1]?.unit && '-'}
                    </td>
                    <td
                      className="delete-selected-product"
                      onClick={(e) => setProducts(products.filter((p) => p.id !== product.id))}
                    >
                      &times;
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="mb-50" />
      <>
        <p className="title-promos-form">
          <b>PASO 4.2: Seleccione la unidad de venta de los productos propios</b>
        </p>
        <div className="form-control-width">
          <select
            onChange={handleUnitOfSaleChange}
            className={`select-promo-type form-control-width ${
              !isAnyKindOfProductSelected() && 'inactive'
            }`}
            name="base_package_promo"
            required={products?.length > 0}
            disabled={!isAnyKindOfProductSelected()}
            value={unitOfSale ? unitOfSale : null}
          >
            <option value="" hidden>
              Escoja una opción
            </option>
            {isUnitOfSale1Available && (
              <option key={11} value="unit_of_sale_1">
                {`Unidad de venta 1`}
              </option>
            )}
            {isUnitOfSale2Available && (
              <option key={12} value="unit_of_sale_2">
                {`Unidad de venta 2`}
              </option>
            )}
          </select>
        </div>
      </>

      <div className="mb-50" />
      <p className="title-promos-form">
        <b>
          <span style={{ fontStyle: 'italic' }}>Opcional</span> - PASO 4.3: Agregue los productos de
          competencia que aplican a esta tarea
        </b>
      </p>

      <div className="row">
        <div className="form-control-prod-name-width col-sm-1">
          <input
            type="text"
            maxLength="40"
            className={`form-control ${!isClientOrSomeQualifierSelected() && 'inactive'}`}
            id="competitor-prod-name"
            name="competitor-prod-name"
            onChange={handleProdNameChange}
            placeholder="Nombre del producto"
            value={prodName}
            disabled={!isClientOrSomeQualifierSelected()}
          />
          {prodNameError && <p className="error-message">{prodNameError}</p>}
          {!prodNameError && (
            <p className="small text-muted m-0 pull-right font-size-prod">
              Cantidad máxima de caracteres {Math.abs(prodName.length - 40)}/40
            </p>
          )}
        </div>

        <div className="form-control-prod-pck-width col-sm-1" style={{ marginLeft: -15 }}>
          <input
            type="text"
            maxLength="20"
            className={`form-control ${!isClientOrSomeQualifierSelected() && 'inactive'}`}
            id="competitor-prod-pck"
            name="competitor-prod-pck"
            onChange={handleProdPckChange}
            placeholder="Unidad de venta"
            value={prodPck}
            disabled={!isClientOrSomeQualifierSelected()}
            required={prodName.length > 0}
          />
          {prodPckError && <p className="error-message">{prodPckError}</p>}

          {!prodPckError && (
            <p className="small text-muted m-0 pull-right font-size-prod">
              Cantidad máxima de caracteres {Math.abs(prodPck.length - 20)}/20
            </p>
          )}
        </div>

        <button
          className={`btnsurvey col-sm-1 ${!isClientOrSomeQualifierSelected() && 'inactive'}`}
          style={{ marginTop: -25 }}
          disabled={!isClientOrSomeQualifierSelected()}
          onClick={handleAddCompetitionProduct}
          type="submit"
        >
          <span className="icon-survey-add">+</span>{' '}
          <span className="select-add-question">Agregar</span>
        </button>
      </div>

      {competitionProducts?.length > 0 && (
        <div className="promo-form-section row">
          <table className="table table-hover promos-product-table">
            <thead>
              <tr>
                <th className="pl-20">NOMBRE DEL PRODUCTO</th>
                <th>UNIDAD DE VENTA </th>
                <th>ACCIÓN</th>
              </tr>
            </thead>
            <tbody>
              {competitionProducts.map((product, index) => (
                <tr key={`${product.name}-${index}`}>
                  <td className="pl-20">{product.name}</td>
                  <td>{product.package}</td>
                  <td
                    className="delete-selected-product"
                    onClick={(e) =>
                      setCompetitionProducts(
                        competitionProducts.filter((p) => p.name !== product.name)
                      )
                    }
                  >
                    &times;
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="promo-form-section-dates">
        <div className="title-promos-form">
          <b>PASO 5: Escoja una fecha de inicio y/o fecha final para esta tarea</b>
        </div>
        <hr className="hr-promos-form" />
        <div className="d-flex align-flex-center">
          <div className="float-left">
            <label>Fecha inicio</label>
            <br />
            <input
              min={new Date().getFullYear() + '-' + getMonth() + '-' + getDay()}
              onChange={handleStartTimeChange}
              className="start-date"
              type="date"
              name="startTime"
              required
            />
          </div>
          {!undefinedEndTime && (
            <>
              <div className="dates-separator-2"></div>
              <div className="float-right">
                <label>Fecha final</label>
                <br />
                <input
                  min={startTime}
                  onChange={handleEndTimeChange}
                  className="end-date"
                  type="date"
                  name="endTime"
                  required
                />
              </div>
            </>
          )}
        </div>
        <div className="mt-16">
          <div>
            <input
              onChange={onChangeRadios}
              type="radio"
              name="taskRange"
              id="taskRange-undefined"
              checked={undefinedEndTime}
              value={true}
            />
            <label className="ml-10" htmlFor="taskRange-undefined">
              Tarea indefinida
            </label>
          </div>
          <div>
            <input
              onChange={onChangeRadios}
              type="radio"
              name="taskRange"
              id="taskRange-defined"
              checked={!undefinedEndTime}
              value={false}
            />
            <label className="ml-10" htmlFor="taskRange-defined">
              Con fecha de expiración
            </label>
          </div>
        </div>
        <div className="mb-40" />
      </div>

      <div className="promo-form-section">
        <div className="title-promos-form">
          <p>
            <b>PASO 5.1: Establezca una recurrencia para la tarea</b>
          </p>
        </div>
        <div className="form-control-width">
          <select
            className="form-control"
            name="recurrenceSelectionType"
            required
            onChange={handleRecurrenceTypeChange}
          >
            {recurrenceSelectionType?.map((obj) => (
              <option key={obj.value} name={obj.value} value={obj.value} hidden={obj.hidden}>
                {obj.text}
              </option>
            ))}
          </select>
        </div>
        {recurrenceSelection === 'weekly' && (
          <>
            <div className="form-control-width mt-20">
              <p>
                Se repite cada{' '}
                <div className="number-input-changer-container ">
                  <input
                    className="number-input-changer"
                    type="text"
                    value={recurrenceInterval}
                    onChange={onChangeRecurrenceInterval}
                    onKeyDown={handleKeyDown}
                  />
                  <div
                    onClick={increaseRecurrenceInterval}
                    className="number-input-changer-btn plus"
                  >
                    +
                  </div>
                  <div
                    onClick={decreaseRecurrenceInterval}
                    className="number-input-changer-btn minus"
                  >
                    -
                  </div>
                </div>{' '}
                semana
                {recurrenceInterval && recurrenceInterval > 1 ? 's' : ''}{' '}
              </p>
            </div>
            <div className="form-control-width mt-20">
              <p>Escoja los día(s)</p>
              <div className="days-btn-group">
                {recurrenceWeekDays.map((day, index) => (
                  <button
                    type="button"
                    onClick={() => handleToggleDay(day)}
                    value={day.value}
                    title={day.value}
                    key={index}
                    className={`days-btn ${
                      recurrenceWeekDaysSelection.find((d) => d.value === day.value) && 'active'
                    }`}
                  >
                    {day.text}
                  </button>
                ))}
              </div>
              <div className="fake-validator-container">
                <input
                  className="input-fake-validator"
                  type="text"
                  required
                  value={
                    recurrenceWeekDaysSelection && recurrenceWeekDaysSelection.length > 0
                      ? JSON.stringify(recurrenceWeekDaysSelection)
                      : null
                  }
                />
              </div>
            </div>
          </>
        )}
        <p className="small text-muted mt-20">{buildRecurrenceMessage()}</p>
        <div className="mb-50" />
      </div>
    </>
  )
}
export default PriceCaptureTask

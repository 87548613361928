import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faGripVertical, faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useRef, useEffect } from 'react'
import autosize from 'autosize'

const SurveyTextTask = ({
  id,
  onDuplicate,
  onDelete,
  onUpdateQuestion,
  data,
  questionNumber,
  classNames,
  showOptionsButton,
  disableQuestion,
  isDetailsSurvey,
  showDescriptionsCreate,
  showDescriptionsDetails,
  showresponseDetails,
  showresponsePostask,
  provided
}) => {
  const [isVisible, setIsVisible] = useState(true)
  const [showOptions, setShowOptions] = useState(false)
  const optionsContainerRef = useRef(null)

  const [formData, setFormData] = useState({
    question: data.question || '',
    descriptions: data.descriptions || '',
    response: data.response || ''
  })

  const [isDuplicating, setIsDuplicating] = useState(false)
  const [rows, setRows] = useState(1)
  const textareaRefQuestion = useRef(null)
  const textareaRefDescription = useRef(null)

  useEffect(() => {
    autosize(textareaRefQuestion.current)
    autosize(textareaRefDescription.current)
    return () => {
      autosize(textareaRefQuestion.current)
      autosize(textareaRefDescription.current)
    }
  }, [])

  useEffect(() => {
    if (!isDuplicating) {
      setFormData({
        question: data.question || '',
        descriptions: data.descriptions || '',
        response: data.response || ''
      })
    }
  }, [id, isDuplicating])

  useEffect(() => {
    setFormData({
      question: data.question || '',
      descriptions: data.descriptions || '',
      response: data.response || ''
    })
  }, [data])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsContainerRef.current && !optionsContainerRef.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsVisible(false)
  }

  const handleDuplicate = () => {
    const duplicatedData = { ...formData }
    onDuplicate(duplicatedData)
    setShowOptions(false)
    setIsDuplicating(true)
  }

  const handleDelete = () => {
    setIsVisible(false)
    setShowOptions(false)
    onDelete(id)
  }

  const handleChange = (e) => {
    const { name, value, scrollHeight } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    onUpdateQuestion(id, name, value)
    const calculatedRows = Math.floor(scrollHeight / 20)
    setRows(calculatedRows)
  }
  const handleTextareaClick = () => {
    autosize.update(textareaRefQuestion.current)
  }
  const handleDescriptionClick = () => {
    autosize.update(textareaRefDescription.current)
  }

  const calculateTextareaRows = (text) => {
    const charactersPerLine = 100
    const lineCount = Math.ceil(text.length / charactersPerLine)
    return Math.min(lineCount, 3)
  }

  const handleToggleOptions = () => {
    setShowOptions(!showOptions)
  }
  return (
    <div>
      {isVisible &&
        (showOptionsButton ? (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`${classNames?.container || 'survey-text-task-container'}`}
          >
            <div className={`${classNames?.headers || 'headers'}`}>
              {showOptionsButton && (
                <div className="question-text">
                  <p>Pregunta&nbsp;{questionNumber}:</p>
                </div>
              )}
              {showOptionsButton && (
                <div
                  className="drag-handle drag-handle-move"
                  {...provided.dragHandleProps}
                  title="Arrastra para reubicar la pregunta"
                >
                  <FontAwesomeIcon icon={faGripHorizontal} />
                </div>
              )}
              {showOptionsButton && (
                <div className="options-container" ref={optionsContainerRef}>
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    className="ellipsis-icon"
                    onClick={handleToggleOptions}
                  />
                  {showOptions && (
                    <div className="options">
                      <button onClick={handleDuplicate}>Duplicar</button>
                      <div className="divider"></div>
                      <button onClick={handleDelete}>Eliminar</button>
                    </div>
                  )}
                </div>
              )}
            </div>

            {showDescriptionsCreate && (
              <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
                <textarea
                  ref={textareaRefQuestion}
                  className={`${classNames?.fontsquestion || 'form-control form-control-details'}`}
                  id={`question-${id}`}
                  name="question"
                  maxLength="250"
                  placeholder="Escriba el título de su pregunta"
                  value={formData.question}
                  onChange={handleChange}
                  onClick={handleTextareaClick}
                  required
                  disabled={disableQuestion}
                  style={{ resize: 'none', minHeight: '30px', height: 'auto' }}
                  rows={1}
                />
              </div>
            )}
            {showDescriptionsCreate && (
              <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
                <textarea
                  ref={textareaRefDescription}
                  className={`${
                    classNames?.fontsdescriptions || 'form-control form-control-details'
                  }`}
                  id={`descriptions-${id}`}
                  name="descriptions"
                  maxLength="250"
                  placeholder={isDetailsSurvey ? '' : 'Descripción opcional'}
                  value={formData.descriptions}
                  onChange={handleChange}
                  onClick={handleDescriptionClick}
                  disabled={disableQuestion}
                  style={{ resize: 'none', minHeight: '30px', height: 'auto' }}
                  rows={1}
                />
              </div>
            )}
            {showDescriptionsDetails && formData.descriptions && (
              <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
                <textarea
                  className={`${
                    classNames?.fontsdescriptions || 'form-control  form-control-details'
                  }`}
                  id={`descriptions-${id}`}
                  name="descriptions"
                  placeholder={isDetailsSurvey ? '' : 'Descripción opcional'}
                  value={formData.descriptions}
                  disabled={disableQuestion}
                  style={{
                    height: 'auto',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    resize: 'none'
                  }}
                  rows={calculateTextareaRows(formData.descriptions)}
                />
              </div>
            )}
            {showDescriptionsCreate && (
              <div className={`${classNames?.response || 'form-group '}`}>
                <input
                  disabled
                  type="text"
                  className={`${classNames?.responseplace || 'form-control - response-surveys'}`}
                  id=""
                  name=""
                  value={formData.response || ''}
                  placeholder="Escriba su respuesta "
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={`${classNames?.container || 'survey-text-task-container'}`}>
            <div className={`${classNames?.headers || 'headers'}`}>
              {showOptionsButton && (
                <div className="question-text">
                  <p>Pregunta&nbsp;{questionNumber}:</p>
                </div>
              )}
            </div>
            {showDescriptionsDetails && formData.question && (
              <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
                <div className="question-container">
                  {disableQuestion && (
                    <div className="question-number">
                      <b>{questionNumber}.</b>
                    </div>
                  )}
                  <textarea
                    className={`${
                      classNames?.fontsquestion || 'form-control form-control-details'
                    } responsive-textarea`}
                    name="question"
                    placeholder="Escriba el título de su pregunta"
                    value={formData.question}
                    disabled={disableQuestion}
                    style={{
                      height: 'auto',
                      wordWrap: 'break-word',
                      whiteSpace: 'pre-wrap',
                      resize: 'none'
                    }}
                    rows={calculateTextareaRows(formData.question)}
                  />
                </div>
              </div>
            )}
            {showDescriptionsDetails && formData.descriptions && (
              <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
                <textarea
                  className={`${
                    classNames?.fontsdescriptions || 'form-control  form-control-details'
                  }`}
                  id={`descriptions-${id}`}
                  name="descriptions"
                  placeholder={isDetailsSurvey ? '' : 'Descripción opcional'}
                  value={formData.descriptions}
                  disabled={disableQuestion}
                  style={{
                    height: 'auto',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    resize: 'none'
                  }}
                  rows={calculateTextareaRows(formData.descriptions)}
                />
              </div>
            )}
            {showresponseDetails && (
              <div className={`${classNames?.response || 'form-group '}`}>
                <input
                  disabled
                  type="text"
                  className={`${classNames?.responseplace || 'form-control - response-surveys'}`}
                  id=""
                  name=""
                  value={formData.response || ''}
                  placeholder="Escriba su respuesta "
                  onChange={handleChange}
                />
              </div>
            )}

            {showresponsePostask && formData.response && (
              <div className={`${classNames?.formsgroup || 'form-group blue-border-input'}`}>
                <div className="question-container">
                  <div className="question-number">
                    <b>{'Respuesta: '}</b>
                  </div>
                  <input
                    disabled
                    type="text"
                    className={`${classNames?.responseplace || 'form-control - response-surveys'}`}
                    id=""
                    name=""
                    value={formData.response}
                    placeholder="Escriba su respuesta "
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default SurveyTextTask

import cookie from 'react-cookies'
import taxSettings from '../flags_variants/tax_settings.json'
import documentTypes from '../flags_variants/document_types.json'

export const getDiscountPrice = (price, discount) =>
  discount && discount > 0 ? price - price * (discount / 100) : price

export const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase()

export const capitalizeFirstLetterFrom = (sentence) => {
  let letter = capitalizeFirstLetter(sentence)
  let sliced = sentence.slice(1)
  return letter + sliced
}

export const formatPrice = (price) => `P${price}`

export const formatDate = (date) => new Date(date).toLocaleDateString('en-US')

export const replaceAccentedCharacters = (text) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
}

export const formatDiscount = (data, decimals = 5) => {
  if (parseFloat(data) > 100 || parseFloat(data) < 0) {
    return 0
  }
  if (data) {
    const regex = new RegExp('(\\.\\d{' + decimals + '})\\d+', 'g')
    const validDiscountNumber = data.replace(regex, '$1')
    return parseFloat(validDiscountNumber)
  } else {
    return 0
  }
}

export const formatMoney = (amount) => {
  let locale = cookie.load('intl_locale')
  let currency = cookie.load('intl_currency')

  let totalAmount = Number(amount).toFixed(2)
  if (locale && currency && locale !== 'null' && currency !== 'null') {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(totalAmount)
  } else {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount)
  }
}

export const effectiveLocationStr = (effectiveLocation, clientLocation, latitude) => {
  if (!clientLocation || Number(clientLocation) === 0 || !latitude || Number(latitude) === 0 || latitude === "0.0") {
    return 'N/A';
  }
  return effectiveLocation ? 'Sí' : 'No';
}

export const effectiveLocationWithAllowedLocation = (effectiveLocation, hasAllowedLocation) => {
  if (!hasAllowedLocation) return 'N/A'
  if (effectiveLocation) return 'Sí'
  return 'No'
}

export const safePercent = (value, of) => {
  let validValue = Number(value)
  let validOf = Number(of)
  let result = validOf === 0 ? 0 : Math.round((validValue / validOf) * 100)
  if (result > 100) {
    result = 100
  }
  return result
}

export const endsVowel = (str) => {
  for (const i of 'aeiou') {
    if (str.endsWith(i)) {
      return true
    }
  }
  return false
}

export const setUpTaxSettings = (intlLocale) => {
  if (intlLocale && (intlLocale != null || intlLocale != '')) {
    let ts = taxSettings[intlLocale]
    if (ts) {
      cookie.save('tax_settings', ts, { path: '/' })
      return
    }
  }
  cookie.save('tax_settings', taxSettings['default'], { path: '/' })
}

export const parseDocumentType = (documentType) => {
  let dt = documentTypes[documentType]
  if (dt) {
    return dt
  }
  return documentType
}

export const replaceWithBr = (str) => {
  return str.replace(/\n/g, '<br />')
}

export const clearCoordinates = (coord) => {
  if (!coord) { 
    return null; 
  }
  let newCoord = parseFloat(coord);
  if (isNaN(newCoord)) { 
    return null; 
  }
  if (newCoord === 0) { 
    return null; 
  }
  return coord;
}

export const getLastActivityTime = (data) => {
  if (!data.length || !data[0].created_date){
      return '-'
    } 

  const lastActivityDate = new Date(data[0].created_date)

  if (isNaN(lastActivityDate)) {
    return '-'
  }

  let hours = lastActivityDate.getHours()
  const minutes = String(lastActivityDate.getMinutes()).padStart(2, '0')
  const suffix = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12

  return `${hours}:${minutes} ${suffix}`
}

export const assignClientIndicesAndFilter = (data) => {
  let currentIndex = 0;
  let lastClientDbRef = null;
  const processedData = data.reduceRight((resultArray, currentItem) => {
    if (currentItem.client_db_ref !== lastClientDbRef) {
      lastClientDbRef = currentItem.client_db_ref;
      currentIndex++;
    }
    if (currentItem.latitude && currentItem.longitude && currentItem.latitude !== '0.0' && currentItem.longitude !== '0.0') {
      resultArray.push({
        ...currentItem,
        client_index: currentIndex,
      });
    } else {
      resultArray.push({
        ...currentItem,
        client_index: currentIndex,
      });
    }
    return resultArray;
  }, []).reverse();

  return processedData;
};

export const allCoordinatesValid = (data) => {
  return data.every((item) => {
    const lat = item.latitude
    const long = item.longitude
    return (
      (lat === '0.0' && long === '0.0') ||(lat === 0 && long === 0) ||lat === null ||long === null)
  })
}

export const allCoordinatesValidClient = (item) => {
  const lat = item.client_latitude;
  const long = item.client_longitude;
  return (
    (lat === "0.0000000000" && long === "0.0000000000") ||
    (lat === null || long === null)
  );
};
export const formatDateNoHours = (dateString) => {
  const date = new Date(`${dateString}T00:00:00`);
  if (isNaN(date)) {
    return dateString;
  }
  const options = {  
    day: 'numeric',  
    month: 'numeric',  
    year: 'numeric'  
  };
  return date.toLocaleDateString("es-ES", options);
}

import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage = ({ redirect: redirect }) => (
  <section className="not_found_section">
    {redirect !== false && (
      <div className="container">
        <div className="row mb-50">
          <div className="col-sm-5">
            <h1 className="not_found_section_title mb-20">¿¿ALÓ??</h1>
            <div className="not_found_section_subtitle">
              <p className="mb-50">La página que estas buscando no existe o se mudó.</p>
              <p>
                ¡No te preocupes! Para regresar a la pagina principal dale clic{' '}
                <Link to={redirect} className="redirect_link">
                  aquí
                </Link>
                .
              </p>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="man-icon" />
          </div>
        </div>
      </div>
    )}
  </section>
)
export default NotFoundPage

import React from 'react'
import cookie from 'react-cookies'
import { DispatchConsumer } from '../context/OrderContext'
import ConvertOrderInput from '../component/protected_component/ConvertOrderInput'
import { formatMoney } from '../../helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class OrderDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderDetails: this.props.orderDetails || [],
      taxSettings: cookie.load('tax_settings')
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({ orderDetails: nextProps.orderDetails })
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  cancelOrder = () => {
    const response = window.confirm('¿Esta seguro que quiere anular el pedido?')
    if (response === true) {
      fetch(BACKEND_URL + '/orders/' + this.state.orderDetails.uuid, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((data) => (window.location = '/pedidos/' + this.props.transactionUuid))
        .catch((e) => console.log(e))
    }
  }

  calculateTaxRate = () => {
    let total = 0
    if (this.state.orderDetails['origin_of_order'] == 'UbiquaApp') {
      this.state.orderDetails.details.map(
        (obj) => (total += (parseFloat(obj.extended_price) * parseFloat(obj.tax_rate)) / 100)
      )
    } else {
      this.state.orderDetails.details.map(
        (obj) => (total += parseFloat(obj.extended_price) * parseFloat(obj.tax_rate))
      )
    }
    return total
  }

  calculateTaxRate2 = () => {
    let total = 0
    this.state.orderDetails.details.map(
      (obj) => (total += (parseFloat(obj.extended_price) * parseFloat(obj.tax_rate_2)) / 100)
    )
    return total
  }

  calculateTotal = () => {
    if (this.state.orderDetails['origin_of_order'] == 'UbiquaApp') {
      return Number(this.state.orderDetails['total'])
    }
    let total = 0
    this.state.orderDetails.details.map((obj) => {
      const calculatedTaxPrice = obj.calculated_tax_price
        ? obj.calculated_tax_price
        : obj.extended_price
      return (total += parseFloat(calculatedTaxPrice))
    })
    return total
  }

  getDiscountPercent = (_discount = 0) => {
    let discount = Number(_discount)
    if (discount === 0) return '-'
    return `${discount.toFixed(2)}%`
  }

  getRowSubtotal = (_extendedPrice = 0, _discount = 0) => {
    let subtotal = 0
    let extendedPrice = parseFloat(_extendedPrice)
    let discountPercent = parseFloat(_discount)
    if (!discountPercent || discountPercent == 0) {
      subtotal = extendedPrice
    } else {
      subtotal = extendedPrice * discountPercent
    }
    return subtotal
  }

  calculateSubTotal = () => {
    if (this.state.orderDetails['origin_of_order'] == 'UbiquaApp') {
      return Number(this.state.orderDetails['subtotal']) + this.getTotalDiscount()
    }
    return Number(this.state.orderDetails['total'])
  }

  getWeight = (_weight = 0) => {
    let weight = Number(_weight).toFixed(2)
    if (weight == 0) return 'N/A'
    return weight
  }

  getTotalDiscount = () => {
    let discountTotal = 0
    this.state.orderDetails.details.map((obj) => {
      if (obj.discount && obj.discount > 0) {
        let totalPrice = obj.unit_price * obj.quantity
        discountTotal += obj.discount * totalPrice
      } else {
        return 0
      }
      const discountPrice = obj.discount_price ? obj.discount_price : 0
      return (discountTotal += parseFloat(discountPrice))
    })
    return discountTotal
  }

  getTotalWeight = () => {
    let tw = Number(this.state.orderDetails['total_weight']).toFixed(2)
    if (tw == 0) return 'N/A'
    return tw
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          {/* {this.props.transactionUuid && ( */}
          <>
            <DispatchConsumer>
              {({ handleDispatchState, isDispatch }) => (
                <div>
                  <div>
                    <div className="row show-product-header">
                      <div className="col-sm-6">
                        <h3 className="fs-18">Detalles del pedido:</h3>
                      </div>
                      {!isDispatch && this.state.orderDetails?.origin_of_order != 'UbiquaApp' && (
                        <div className="col-sm-6">
                          {this.state.orderDetails.status === 'created' && (
                            <div className="show-buttons">
                              <ConvertOrderInput
                                handleDispatchState={handleDispatchState}
                                cancelOrder={this.cancelOrder}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <hr />
                  </div>
                </div>
              )}
            </DispatchConsumer>
          </>
          {/* )} */}
          <div>
            <div className="show-product-header-ubiqua-app show-header">
              <div>
                <div className="order-client-name">{this.state.orderDetails['client_name']}</div>
                <div className="order-client-db_ref">
                  Código: {this.state.orderDetails['client_db_ref']}
                </div>
              </div>
              {/* {this.state.orderDetails?.origin_of_order == 'UbiquaApp' && (
                <button className="float-right download-order-button" onClick={this.download}>
                  Descargar pedido
                  <FontAwesomeIcon icon={faArrowCircleDown} />
                </button>
              )} */}
            </div>
            <hr />
            <div>
              <table cellSpacing="10">
                <tbody>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Fecha de creación:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.getFormattedDate(this.state.orderDetails['created_date'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Número de pedido:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.orderDetails['order_db_ref']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Vendedor:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.orderDetails['salesman_name']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Fecha de entrega:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.orderDetails['delivery_date'] || '-'}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Dirección de entrega:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.state.orderDetails['delivery_address'] || 'N/A'}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Comentarios generales:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.orderDetails['comments'] || '-'}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className="mr-20 mb-10">
                <div>
                  <b>Productos en Pedido:</b>
                </div>
              </div>

              <table className="table table-hover mb-0">
                <thead>
                  <tr>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">CÓDIGO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">NOMBRE DEL PRODUCTO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">PESO NETO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">UNIDAD DE VENTA</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">CANTIDAD</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">PRECIO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">% DESCUENTO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">SUB-TOTAL</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orderDetails['details'].map((model, index) => (
                    <tr key={index}>
                      <td className="td-text-limited">{model['product_db_ref']}</td>
                      <td className="td-text-limited">{model['product_name']}</td>
                      <td className="td-text-limited">{this.getWeight(model['weight'])}</td>
                      <td className="td-text-limited">{model['price_unit']}</td>
                      <td className="td-text-limited">{Number(model['quantity']).toFixed(2)}</td>
                      <td className="td-text-limited">
                        {formatMoney(Number(model['unit_price']))}
                      </td>
                      <td className="td-text-limited">
                        {this.getDiscountPercent(model['discount'])}
                      </td>
                      {/* <td>{formatMoney(model['extended_price']) }</td> */}
                      <td className="td-text-limited">{formatMoney(model['extended_price'])}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <hr className="mt-0" />
              <div className="totals-container">
                <div className="totals-card">
                  <div className="totals-line">
                    <div>
                      <b>SUB-TOTAL:</b>
                    </div>
                    <div>{formatMoney(this.state.orderDetails['subtotal'])}</div>
                  </div>
                  <div className="totals-line">
                    <div>
                      <b>DESCUENTOS:</b>
                    </div>
                    <div>-{formatMoney(this.state.orderDetails['discount'] || 0)}</div>
                  </div>
                  {this.state.taxSettings['tax_rate_2'] && (
                    <div className="totals-line">
                      <div>
                        <b>{this.state.taxSettings['tax_rate_2']}:</b>
                      </div>
                      <div>{formatMoney(this.calculateTaxRate2())}</div>
                    </div>
                  )}
                  <div className="totals-line">
                    <div>
                      <b>{this.state.taxSettings['tax_rate']}:</b>
                    </div>
                    <div>{formatMoney(this.calculateTaxRate())}</div>
                  </div>
                  <div className="totals-line">
                    <div>
                      <h4>
                        <b>TOTAL:</b>
                      </h4>
                    </div>
                    <div>{formatMoney(this.calculateTotal())}</div>
                  </div>
                  <hr />
                  <div className="totals-line">
                    <div>
                      <b>PESO TOTAL:</b>
                    </div>
                    <div>{this.getTotalWeight()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

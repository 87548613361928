import React, { useContext, useState } from 'react'
import { ProductCreateContext } from '../context/ProductCreateProvider'
import ImageUploading from 'react-images-uploading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faFileUpload, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const CreateProductDetailsStep = () => {
  const { errors, register, newProductInfo, onChangeImage, maxNumber, images } =
    useContext(ProductCreateContext)

  const [categoryValue, setCategoryValue] = useState(0)
  const [categorySelected, setCategorySelected] = useState(false)

  const handleDropdownChange = (e) => {
    setCategoryValue(e.target.value)
    setCategorySelected(e.target.value != '' && e.target.value != null)
  }

  const categoryFiltered = newProductInfo.category_subcategory_relation?.filter(
    (c) => c.category_id == categoryValue
  )

  const maxMbFileSize = 5242880

  const [description, setDescription] = useState('')
  const [nameproduct, setNameProduct] = useState('')

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleNameProductsChange = (event) => {
    setNameProduct(event.target.value)
  }

  return (
    <>
      <hr />
      <div className="inline-block full-width mb-40">
        <div className="float-left">
          <span>
            <b>Paso 1 de 2: </b>
          </span>
          Por favor, complete los campos obligatorios marcados con{' '}
          <span style={{ color: 'red' }}>*</span> para proceder
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Activo:</label>
        <div className="col-sm-8">
          <select
            name="active"
            {...register('active')}
            className={`form-control ${errors.active ? 'is-invalid' : ''}`}
          >
            <option value="true">Si</option>
            <option value="false">No</option>
          </select>
          <div className="invalid-feedback">{errors.active?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputName" className="col-sm-3 col-form-label">
          Código interno<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="code"
            type="text"
            {...register('code')}
            className={`form-control ${errors.code ? 'is-invalid' : ''}`}
            placeholder="00000"
          />
          <div className="invalid-feedback">{errors.code?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputName" className="col-sm-3 col-form-label">
          Código de barra<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="barcode"
            type="text"
            {...register('barcode')}
            className={`form-control ${errors.barcode ? 'is-invalid' : ''}`}
            placeholder="0123-4567"
          />
          <div className="invalid-feedback">{errors.barcode?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Nombre de producto<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="name"
            type="text"
            {...register('name')}
            maxLength="50"
            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
            placeholder="Nombre"
            value={nameproduct}
            onChange={handleNameProductsChange}
          />
          <span className="float-right" style={{ color: '#808080' }}>
            Cantidad máxima de caracteres {Math.abs(nameproduct.length - 50)}/50
          </span>
          <div className="invalid-feedback">{errors.name?.message}</div>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Descripción
        </label>
        <div className="col-sm-8">
          <textarea
            name="description"
            autoComplete="off"
            maxLength="400"
            {...register('description')}
            className={`form-control ${errors.description ? 'is-invalid' : ''}`}
            placeholder="Descripción del producto"
            value={description}
            onChange={handleDescriptionChange}
          />
          <span className="float-right" style={{ color: '#808080' }}>
            Cantidad máxima de caracteres {Math.abs(description.length - 400)}/400
          </span>
          <div className="invalid-feedback">{errors.description?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          ITBMS<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <select
            name="itbms"
            {...register('itbms')}
            className={`form-control ${errors.itbms ? 'is-invalid' : ''}`}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.taxes != null
              ? newProductInfo.taxes
                  .filter((v) => v['name'])
                  .map((obj) => (
                    <option key={obj.id} value={obj.id}>{`ITBMS - ${obj['name']}`}</option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.itbms?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Marca<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <select
            name="brand"
            {...register('brand')}
            className={`form-control ${errors.brand ? 'is-invalid' : ''}`}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.brand != null
              ? newProductInfo.brand
                  .sort((a, b) => a['description'].localeCompare(b['description']))
                  .map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {obj['description']}
                    </option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.brand?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Unidad de venta 1<span className="red">*</span>:
        </label>

        <div className="col-sm-3">
          <select
            name="unit"
            {...register('unit')}
            className={`form-control ${errors.unit ? 'is-invalid' : ''}`}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.package != null
              ? newProductInfo.package
                  .sort((a, b) => a['description'].localeCompare(b['description']))
                  .map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {obj['description']}
                    </option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.unit?.message}</div>
        </div>

        <div className="col-sm-1 text-center" style={{ paddingTop: 5 }}>
          de
        </div>

        <div className="col-sm-3">
          <input
            name="unitQuantity"
            type="number"
            {...register('unitQuantity')}
            className={`form-control ${errors.unitQuantity ? 'is-invalid' : ''}`}
            placeholder="00"
          />
          <div className="invalid-feedback">{errors.unitQuantity?.message}</div>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label" />
        <div className="col-sm-8">
          <input
            name="splitUnit"
            {...register('splitUnit')}
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
          />
          <label
            className="form-check-label"
            htmlFor="exampleCheck1"
            style={{ paddingLeft: 10, fontWeight: 500, color: '#27588D' }}
          >
            Activar venta de empaque en unidad
          </label>
          <div className="invalid-feedback">{errors.splitUnit?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Peso por unidad:
        </label>
        <div className="col-sm-3">
          <input
            name="weight"
            type="number"
            step="0.01"
            {...register('weight')}
            className={`form-control ${errors.weight ? 'is-invalid' : ''}`}
            placeholder="00"
          />
          <div className="invalid-feedback">{errors.weight?.message}</div>
        </div>
        <div className="col-sm-1 text-center" style={{ paddingTop: 5 }}>
          Unidad
        </div>
        <div className="col-sm-3">
          <select
            name="unitWeight"
            {...register('unitWeight')}
            className={`form-control ${errors.unitWeight ? 'is-invalid' : ''}`}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.price_unit != null
              ? newProductInfo.price_unit
                  .sort((a, b) => a['description'].localeCompare(b['description']))
                  .map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {obj['description']}
                    </option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.unitWeight?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Categoría<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <select
            name="category"
            {...register('category')}
            className={`form-control ${errors.category ? 'is-invalid' : ''}`}
            onChange={handleDropdownChange}
          >
            <option value="" hidden disabled selected>
              Escoja una opción
            </option>
            {newProductInfo.categories != null
              ? newProductInfo.categories
                  .sort((a, b) => a['description'].localeCompare(b['description']))
                  .map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {obj['description']}
                    </option>
                  ))
              : []}
          </select>
          <div className="invalid-feedback">{errors.category?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Subcategoría
        </label>
        <div className="col-sm-8">
          {categorySelected ? (
            <select
              name="subCategory"
              {...register('subCategory')}
              className={`form-control ${errors.subCategory ? 'is-invalid' : ''}`}
            >
              <option value="" hidden disabled selected>
                Escoja una opción
              </option>
              {categoryFiltered != null
                ? categoryFiltered
                    .sort((a, b) =>
                      a['subcategory_description'].localeCompare(b['subcategory_description'])
                    )
                    .map((obj) => (
                      <option key={obj.id} value={obj.subcategory_id}>
                        {obj['subcategory_description']}
                      </option>
                    ))
                : []}
            </select>
          ) : (
            <select
              name="subCategory"
              {...register('subCategory')}
              className={`form-control ${errors.subCategory ? 'is-invalid' : ''}`}
              disabled
            >
              <option hidden />
            </select>
          )}
          <div className="invalid-feedback">{errors.subCategory?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <div className="col-md-3">
          <label htmlFor="inputCode" className="col-form-label">
            Imagen
          </label>
          <p style={{ fontSize: 13, color: '#AAAAAA' }}>
            Notas:
            <ul>
              <li>Imagen debe ser formato PNG o JPG.</li>
              <li>Máximo de 1 imagen.</li>
              <li>Tamaño máximo de 600px x 600 px.</li>
              <li>Tamaño de archivo inferior a 5 MB.</li>
            </ul>
          </p>
        </div>
        <div className="col-sm-8 ml-15" style={{ maxWidth: 'calc(67% - 30px)', margin: '0 auto' }}>
          <ImageUploading
            multiple
            value={images}
            onChange={onChangeImage}
            maxNumber={maxNumber}
            maxFileSize={maxMbFileSize}
            resolutionWidth={600}
            resolutionHeight={600}
            resolutionType={'less'}
            acceptType={['jpg', 'png']}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
              errors
            }) => (
              <div className="upload__image-wrapper">
                <div className="form-group row show-data-form">
                  <div className="">
                    <div
                      className="upload-drop-zone"
                      id="drop-zone"
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <span className="drag-drop-icon">
                        <FontAwesomeIcon className="drag-drop-icon" size="5x" icon={faFileUpload} />
                      </span>
                      <span>Haz drag and drop o haz clic en este botón</span>
                    </div>
                  </div>
                  {errors && (
                    <div style={{ color: '#E10000' }}>
                      {errors.maxNumber && <span>Máximo de 1 imagen por producto</span>}
                      {errors.acceptType && (
                        <span>Verifique que la imagen sea formato .png .jpg</span>
                      )}
                      {errors.resolution && (
                        <span>
                          Verifique que la imagen sea formato .png .jpg y que las dimensiones sean
                          por debajo de 600px x 600px.
                        </span>
                      )}
                      {errors.maxFileSize && (
                        <span>El tamaño de la imagen no debe ser mayor a 5MB</span>
                      )}
                    </div>
                  )}
                </div>

                {imageList.map((image, index) => (
                  <div className="row" key={index}>
                    <div className="image-item">
                      <div className="col-md-6">
                        <img src={image['data_url']} alt="" width="100" />
                        <span>{image.name}</span>
                      </div>
                      <div className="col-md-6">
                        <div className="image-item__btn-wrapper">
                          <p
                            className="delete-ubq-product-label"
                            onClick={() => onImageRemove(index)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                            Eliminar imagen
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
      </div>
    </>
  )
}

export default CreateProductDetailsStep

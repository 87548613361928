import React from 'react'
import { UserAuditContext } from '../context/UserAuditProvider'
import { getFormattedDate } from '../services/apiUserService'

//Vista de tab "Historial" de Detalles de Usuario en Usuario

const UserAuditTab = () => (
  <>
    <UserAuditContext.Consumer>
      {({ userAuditData, isFetching }) => (
        <div>
          {!isFetching && userAuditData.length === 0 ? (
            <div className="mt-70">
              <div className="text-center">
                <br />
                <p>Obteniendo datos para usuarios ....</p>
              </div>
            </div>
          ) : (
            <div className="show-area-tab">
              <div className="show-header ml-12">
                <h2 style={{marginLeft: '10px'}}>Historico de cambios en las configuraciones de usuario:</h2>
              </div>
              <div className="mt-25">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>NOMBRE</th>
                      <th>DESCRIPCIÓN</th>
                      <th>FECHA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>configuracion@ubiqua.me</td>
                      <td>Actualizar Crear Devoluciones - Si</td>
                      <td>02/12/2022, 2:43:42</td>
                    </tr>
                    <tr>
                      <td>configuracion@ubiqua.me</td>
                      <td>Actualizar Crear Devoluciones - No</td>
                      <td>01/12/2022, 23:16:54</td>
                    </tr>
                    <tr>
                      <td>configuracion@ubiqua.me</td>
                      <td>Actualizar Crear Cotizaciones - Si</td>
                      <td>30/11/2022, 12:03:45</td>
                    </tr>
                    {/* {userAuditData.length > 0 ? (
                      userAuditData.map((user) => (
                        <tr className="cursor-pointer" key={user.id}>
                          <td id={user['id']}>{user.user_email}</td>
                          <td id={user['id']}>
                            {user.description.split('\n').map((c) => (
                              <p key={c}> {c} </p>
                            ))}
                          </td>
                          <td id={user['id']}>{getFormattedDate(user.timestamp)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No hubo resultados</td>
                      </tr>
                    )} */}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </UserAuditContext.Consumer>
  </>
)

export default UserAuditTab

import React from 'react'
import TabWithDropdown from '../../layout/TabWithDropdown'
import RefundDetailsTab from './RefundDetailsTab'

export default class ShowRefundView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessfulMessage: false,
      alertState: 'alert alert-success',
      createText: 'Guardar',
      isPosting: false,
      activity: props.defaultDetails
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ details: nextProps.defaultDetails })
  }

  componentDidMount() {
    this.showSuccessfulMessageCreation()
  }

  showSuccessfulMessageCreation = () => {
    if (document.referrer.includes('nuevo_pedido')) {
      this.setState({ showSuccessfulMessage: true })
    } else {
      this.setState({ showSuccessfulMessage: false })
    }
  }

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  }

  disableButton = () => {
    this.setState({ createText: 'Guardando...', isPosting: true })
  }

  enableButton = () => {
    this.setState({ createText: 'Guardar', isPosting: false })
  }

  isDelivery = () => {
    let rt = this.state.activity?.refund_type
    if (rt && rt != 'salesman_refund') return true
    return false
  }

  handleRefundType = () => {
    let rt = this.state.activity?.refund_type
    switch (rt) {
      case 'refund':
        return 'Rechazo total'
      case 'partial_refund':
        return 'Rechazo parcial'
      default:
        return 'Devolución'
    }
  }

  render() {
    return (
      <div>
        <div className="main-view">
          <div className="main-show-header">
            <h2>
              {this.handleRefundType()}: {this.state.activity?.refund_db_ref}
            </h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div>
                <div style={{float: 'right'}}>
                  <b className="order-status-b">
                    Estado:
                  </b>
                  {this.state.activity?.status === 'received' &&(
                    <span className="order-status received-style"> RECIBIDO</span>
                  )}
                  {this.state.activity?.status === 'sent' &&(
                    <span className="order-status sent-style"> ENVIADO</span>
                  )}
                  {this.state.activity?.status === 'dispatched' && (
                    <span className="order-status dispatched-style"> DESPACHADO</span>
                  )}
                  {this.state.activity?.status === 'invoiced' && (
                    <span className="order-status billed-style"> FACTURADO</span>
                  )}       
                </div>
                <div>
                  <div>
                    {this.state.activity?.status === 'received' ||
                      (this.state.activity?.status === 'sent' && (
                        <ul className="nav nav-tabs">
                          <TabWithDropdown
                            name={this.isDelivery() ? 'Entrega' : 'Devolución'}
                            activeTab={this.isDelivery() ? 'Entrega' : 'Devolución'}
                            items={[]}
                          />
                        </ul>
                      ))}
                  </div>

                  <div>
                    {this.state.activity && (
                      <RefundDetailsTab
                        activity={this.state.activity}
                        transactionUuid={this.props.details.uuid}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

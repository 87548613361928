import React from 'react'
import NavBar from '../../layout/NavBar'
import TopBar from '../../layout/TopBar'
import ShowChargeView from './ShowChargeView'
import cookie from 'react-cookies'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ShowChargeApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchPlaceholder: '',
      id: window.location.href.split('/')[window.location.href.split('/').length - 1],
      isFetching: true,
      query: '',
      details: null,
      defaultUuid: null,
      defaultDetails: null,
      collapsed: false,
    }
  }

  componentDidMount() {
    this.fetchTransactionDetails()
    const collapsed = localStorage.getItem('collapsedState') === 'true';
    this.setState({ collapsed });
  }

  fetchTransactionDetails = () => {
    this.setState({ isFetching: true })
    fetch(BACKEND_URL + '/charges/' + this.props.match.params.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        if (response.status === 404) {
          return {
            error: true,
            message: 'No se encontró el cobro'
          }
        }
        return response.json()
      })
      .then((data) => {
        if (data.error) {
          this.setState({
            error: data.message,
            isFetching: false
          })
        } else {
          this.setState({
            details: data,
            defaultUuid: data?.uuid,
            defaultDetails: data,
            isFetching: false
          })
        }
      })
      .catch((e) => console.log(e))
  }

  handleSearch = (event, isEditing) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        if (isEditing) {
          const val = window.confirm(
            'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
          )
          if (val === true) {
            // redirecting to index accroding to clicked
            window.location = '/pedidos?query=' + query
          }
        } else {
          window.location = '/pedidos?query=' + query
        }
        event.target.value = ''
      }
    }
  }

  handleToggleCollapse = (collapsed) => {
    this.setState({ collapsed }, () => {
      localStorage.setItem('collapsedState', collapsed);
    });
  };
  

  render() {
    return (
      <div>
        {this.state.isFetching === false ? (
          <div>
            <div>
              <div>
                <div onKeyDown={(e) => this.handleSearch(e, false)}>
                  <TopBar
                    searchPlaceholder={this.state.searchPlaceholder}
                    userName={this.state.userName}
                    onToggleCollapse={this.handleToggleCollapse} 
                    collapsed={this.state.collapsed} 
                  />
                </div>
                <div>
                  {this.state.error ? (
                    <div className="main-view">
                      <div className="main-show-header">
                        <p>{this.state.error}</p>
                      </div>
                    </div>
                  ) : (
                    <ShowChargeView
                      details={this.state.details}
                      position={this.props.match.params.id}
                      defaultDetails={this.state.defaultDetails}
                      collapsed={this.state.collapsed} 
                    />
                  )}
                </div>
              </div>
              <div>
                <NavBar activeItem="Cobro" isEditing={false} collapsed={this.state.collapsed} />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
              onToggleCollapse={this.handleToggleCollapse} 
              collapsed={this.state.collapsed} 
            />
            <div className="main-view">
              <div className="show-title">
                <p>Obteniendo datos ....</p>
              </div>
            </div>
            <div>
              <NavBar activeItem="Cobro" isEditing={false} collapsed={this.state.collapsed} />
            </div>
          </div>
        )}
      </div>
    )
  }
}

import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Paginator } from 'array-paginator'
import {
  faAngleRight,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleDoubleLeft
} from '@fortawesome/free-solid-svg-icons'

export function LocalPagination({ data, onPageChange, searchInput, display }) {
  const [paginator, setPaginator] = useState(new Paginator(data, 50, 1))

  useEffect(() => {
    if (data?.length >= 0) {
      let pg = new Paginator(data, 50, 1)
      setPaginator(pg)
      onPageChange(pg.first())
    }
  }, [searchInput])

  if (display) {
    return (
      <ul className="pagination">
        <li className="page-item">
          <a
            onClick={() => {
              if (paginator.hasFirst()) onPageChange(paginator.first())
            }}
            id="first-page"
            className="page-link cursor-pointer disabled"
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} id="first-page" />
          </a>
        </li>
        <li className="page-item">
          <a
            onClick={() => {
              if (paginator.hasPrevious()) onPageChange(paginator.previous())
            }}
            id="previous-page"
            className="page-link cursor-pointer"
          >
            <FontAwesomeIcon icon={faAngleLeft} id="previous-page" />
          </a>
        </li>
        <li
          onClick={() => {
            if (paginator.hasNext()) onPageChange(paginator.next())
          }}
          className="page-item"
        >
          <a id="next-page" className="page-link cursor-pointer">
            <FontAwesomeIcon icon={faAngleRight} id="next-page" />
          </a>
        </li>
        <li className="page-item">
          <a
            onClick={() => {
              if (paginator.hasLast()) onPageChange(paginator.last())
            }}
            id="last-page"
            className="page-link cursor-pointer"
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} id="last-page" />
          </a>
        </li>
        <div className="pagination-label">
          <b>
            {paginator?.current} de {paginator?.total} páginas
          </b>
        </div>
      </ul>
    )
  } else {
    return <></>
  }
}

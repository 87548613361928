import _ from 'lodash'
import { formatDiscount, getDiscountPrice } from '../../helpers/utils'

export const AssortedScalePromoReducer = (state, action) => {
  const assortedScaleProducts = state
  const product = action.payload

  switch (action.type) {
    case 'ADD_ASSORTED_SCALE_PRODUCT': {
      const orderPackages = _.sortBy(product?.packages, ['quantity']).reverse()
      product.base_product_ids = [product.id]
      product.base_product_ids = [product.id]
      product.base_product_uid = product.db_ref
      product.base_product_db_ref = product.db_ref
      product.base_product_name = product.name
      product.discount = 0
      product.discountPrice = 0
      product.originalPrice = product.price
      product.base_package_uid = null
      product.base_package = null
      product.basePackageQuantity = 1
      product.base_package_hash = {}
      product.base_package_uids = orderPackages.map((pk) => pk.uid)
      product.base_packages = orderPackages.map((pk) => pk.unit)
      product.packages = orderPackages
      return [product, ...assortedScaleProducts]
    }
      

    case 'REMOVE_ASSORTED_SCALE_PRODUCT': {
      return assortedScaleProducts.filter((item) => item.id !== product.id)
    }
      
    case 'CHANGE_DISCOUNT_ASSORTED_SCALE_PRODUCT': {
      const incrementProduct = assortedScaleProducts.find((item) => item.id === product.id)
      if (incrementProduct) {
        const incrementProductIndex = assortedScaleProducts.findIndex(
          (item) => item.id === product.id
        )
        const discount = formatDiscount(product.discount)
        assortedScaleProducts[incrementProductIndex].discount = discount
        const parseDiscount = getDiscountPrice(parseFloat(product.price), discount)
        assortedScaleProducts[incrementProductIndex].price = parseDiscount
        assortedScaleProducts[incrementProductIndex].discountPrice = parseDiscount
        return [...assortedScaleProducts]
      }
      return state
    }
      
    case 'CHANGE_BASE_PACKAGE_ASSORTED_PRODUCTS': {
      const hash = {}
      assortedScaleProducts.forEach((pr, index) => {
        assortedScaleProducts[index].base_package_uid =
          assortedScaleProducts[index].base_package_uids[product.basePackage]
        assortedScaleProducts[index].base_package =
          assortedScaleProducts[index].base_packages[product.basePackage]
        hash[assortedScaleProducts[index].base_product_db_ref] =
          assortedScaleProducts[index].base_package_uid
        assortedScaleProducts[index].base_package_hash = hash
      })
      return [...assortedScaleProducts]
    }
      
    case 'CLEAR_ASSORTED_SCALE_PRODUCTS': {
      return []
    }

    default: {
      return state
    }
  }
}

import React, { useState, useEffect, useContext, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cookie from 'react-cookies'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { UserInfoContext } from './context/UserInfoProvider'
import { UbiquaAppUserConsumer } from './UsersIndexApp'

// Index de Usuarios con filtros (incompleta)

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const UsersIndexView = (props) => {
  const [data, setData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [typeFilter, setTypeFilter] = useState({})
  const [searchResults, setSearchResults] = useState([])
  const { getRoleLabel } = useContext(UserInfoContext)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort();
  };
  window.addEventListener("beforeunload", abortCurrentFetchs);
  //////

  useEffect(() => {
    async function fetchData() {
      await fetchFilterDataFromServer()
    }

    fetchData()
  }, [])

  useEffect(() => {
    let type_values = []
    let results = {}

    if (typeFilter) {
      type_values = Object.entries(typeFilter)
        .filter((value) => value[1] === true)
        .map((k, v) => k[0])
    }
    results = data.filter((item) => {
      if (type_values.length > 0) {
        const typeName = item.type_of_salesman || ''
        return type_values.map((k) => typeName === k).some((k) => k === true)
      } else {
        return data
      }
    })
    setSearchResults(results)
  }, [data, typeFilter])

  const fetchFilterDataFromServer = () => {
    fetch(
      BACKEND_URL +
        '/businesses/1/reports/filters_by_branches?business_tenant_uid=' +
        cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setFilterData(data['result'])
      })
      .catch((e) => console.log(e))
  }

  const handleShowUserDetails = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open('/usuarios/' + id)
  }

  return (
    <UbiquaAppUserConsumer>
      {({ data, isFetching, filters }) => (
        <div>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">CÓDIGO</span>
                </th>
                <th style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">NOMBRE</span>
                </th>
                <th style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">ROL DE USUARIO</span>
                </th>
                <th style={{ verticalAlign: 'middle' }}>
                  <span className="th-header-text">CORREO ELECTRONICO</span>
                </th>{' '}
                <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <span className="th-header-text">ACTIVO</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((user, index) => (
                  <tr key={index}>
                    <td className="td-text-limited" id={user['id']} onClick={handleShowUserDetails}>
                      {user['route_db_ref']}
                    </td>
                    <td className="td-text-limited" id={user['id']} onClick={handleShowUserDetails}>
                      {user['full_name']}
                    </td>
                    <td className="td-text-limited" id={user['id']} onClick={handleShowUserDetails}>
                      {user['roles']?.map((e) => getRoleLabel(e.feature_role_key)).join(', ')}
                    </td>
                    <td className="td-text-limited" id={user['id']} onClick={handleShowUserDetails}>
                      {user['email']}
                    </td>
                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}  id={user['id']} onClick={handleShowUserDetails}>
                      {user['active'] ? 'Si' : 'No'}
                    </td>
                  </tr>
                ))}
              {!isFetching && (data?.length === 0 || !data) && filters?.length == 0 && (
                <tr>
                  <td className="td-text-limited" colSpan="5">
                    No hubo resultados
                  </td>
                </tr>
              )}
              {!isFetching && (data?.length === 0 || !data) && filters?.length != 0 && (
                <tr>
                  <td className="td-text-limited" colSpan="5">
                    No existen roles disponibles con los filtros aplicados. Te recomendamos ajustar
                    los filtros.
                  </td>
                </tr>
              )}
              {isFetching && (
                <tr>
                  <td className="td-text-limited" colSpan="5">
                    Obteniendo datos...
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
    </UbiquaAppUserConsumer>
  )
}

export default UsersIndexView

import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { formatDateNoHours } from '../../../helpers/utils'

const InvalidCoordinatesModal = ({ show, handleClose, startDate, username, lastActivityTime }) => (
  <Modal
    show={show}
    dialogClassName="modal-90w close-button-modal-maps"
    onHide={handleClose}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header closeButton>
      <Modal.Title id="example-modal-sizes-title-lg">
        <p>
          <b>Mapa de Geocercas</b>
        </p>
        <p className="text-title-modal-map">Usuario: {username}</p>
        <p className="text-title-modal-map">Fecha: {formatDateNoHours(startDate)}</p>
        <p className="text-title-modal-map">Hora de última actividad: {lastActivityTime}</p>
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="map-modal-error">
      <div className="map-error-label">
          <p>
            El usuario ha desactivado el envío de ubicación para todas las actividades. 
          </p>
          <p>No es posible mostrar el mapa de geocercas.</p>
        </div>
         </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
)

export default InvalidCoordinatesModal

import React from 'react'
import cookie from 'react-cookies'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ShowDeliverInvoicesView extends React.Component {
  constructor(props) {
    super(props)
    const id = window.location.href.split('id=')
    this.state = {
      isFetching: false,
      id: id[id.length - 1].split('&')[0],
      data: [],
      total_weight: 0,
      total_money: 0,
      sub_total_money: 0,
      tax_total_money: 0,
      total_duration: 0,
      invoicer_name: '',
      invoicer_db_ref: '',
      selected_date:
        window.location.href.split('date=')[window.location.href.split('date=').length - 1]
    }
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  getCurrentDate = () => new Date().getFullYear() + '-' + this.getMonth() + '-' + this.getDay()

  getMonth = () => {
    var m = new Date().getMonth() + 1
    if (m < 10) {
      return '0' + m
    } else {
      return m
    }
  }

  getDay = () => {
    var d = new Date().getDate()
    if (d < 10) {
      return '0' + d
    } else {
      return d
    }
  }

  showModel = (model, event) => {
    if (model['transaction_uuid']) {
      this.redirectToOrderDetailsTab(model['transaction_uuid'])
    } else {
      this.redirectToInvoiceApp(model['invoice_id'])
    }
  }

  redirectToOrderDetailsTab = (transaction_uuid) => {
    window.location = 'pedidos/' + transaction_uuid
  }

  redirectToInvoiceApp = (invoice_uuid) => {
    window.location = 'facturas/' + invoice_uuid
  }

  handleChange = (event) => {
    const obj = {}
    obj[event.target.name] = event.target.value
    this.setState(obj, this.redirectToNewDate)
  }

  redirectToNewDate = () => {
    window.location =
      '/facturas_por_entregador?id=' + this.state.id + '&date=' + this.state.selected_date
  }

  convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60)
    let m = Math.floor(mins % 60)
    h = h < 10 ? '0' + h : h
    m = m < 10 ? '0' + m : m
    return `${h}:${m}`
  }

  componentDidMount() {
    this.fetchDetailsFromServer()
  }

  fetchDetailsFromServer = () => {
    this.setState({ isFetching: true })
    fetch(
      BACKEND_URL +
        '/businesses/1/reports/delivery_effectiveness/invoicer/' +
        this.state.id +
        '?date=' +
        this.state.selected_date,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) =>
        this.setState({
          data: data['details'],
          invoicer_db_ref: data['invoicer_db_ref'],
          invoicer_name: data['invoicer_name'],
          total_duration: data['duration_in_mins'],
          total_weight: data['total_weight'],
          total_money: data['total'],
          sub_total_money: data['sub_total'],
          tax_total_money: data['tax_total'],
          isFetching: false
        })
      )
      .catch((e) => console.log(e))
  }

  render() {
    if (this.state.isFetching === false) {
      return (
        <div className="main-view">
          <div>
            <div className="show-title">
              <h2>
                Reporte: Efectividad de entregas - {this.state.invoicer_name} (
                {this.state.invoicer_db_ref})
              </h2>
              {/*<button className="download-report-button" onClick={this.download}>
                Descargar reporte<FontAwesomeIcon icon={faArrowCircleDown}/>
              </button>*/}
            </div>
            <div className="index-table">
              <div className="show-area">
                <div className="show-header">
                  <h2>Detalles de reporte:</h2>
                  <div className="show-buttons">
                    <input
                      defaultValue={this.state.selected_date}
                      onChange={this.handleChange}
                      max={this.getCurrentDate()}
                      className="select-date-report"
                      type="date"
                      name="selected_date"
                      required
                    />
                  </div>
                </div>
                <hr className="mt-70" />
                <div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>FACTURA</th>
                        <th>CLIENTE</th>
                        <th>CODIGO</th>
                        <th>TOTAL PESO</th>
                        <th>TOTAL VENTA</th>
                        <th>HORA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.length > 0 &&
                        this.state.data.map((model, index) => (
                          <tr className="cursor-pointer" key={index}>
                            <td
                              onClick={(e) => {
                                this.showModel(model, e)
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              onClick={(e) => {
                                this.showModel(model, e)
                              }}
                            >
                              {model['invoice_db_ref']}
                            </td>
                            <td
                              onClick={(e) => {
                                this.showModel(model, e)
                              }}
                            >
                              {model['client_name']}
                            </td>
                            <td
                              onClick={(e) => {
                                this.showModel(model, e)
                              }}
                            >
                              {model['client_db_ref']}
                            </td>
                            <td
                              onClick={(e) => {
                                this.showModel(model, e)
                              }}
                            >
                              {Number(model['total_weight']).toFixed(2)} KG
                            </td>
                            <td
                              onClick={(e) => {
                                this.showModel(model, e)
                              }}
                            >
                              $
                              {(
                                parseFloat(model['sub_total']) + parseFloat(model['tax_total'])
                              ).toFixed(2)}
                            </td>
                            <td
                              onClick={(e) => {
                                this.showModel(model, e)
                              }}
                            >
                              {this.getFormattedDate(model['created_date'])}
                            </td>
                          </tr>
                        ))}
                      {this.state.data.length > 0 && (
                        <tr>
                          <td colSpan="5" />
                          <td>
                            <b>DURACION TOTAL</b>
                          </td>
                          <td>{this.convertMinsToHrsMins(this.state.total_duration)}</td>
                        </tr>
                      )}
                      {this.state.data.length > 0 && (
                        <tr>
                          <td colSpan="5" />
                          <td>
                            <b>PESO TOTAL</b>
                          </td>
                          <td>{Number(this.state.total_weight).toFixed(2)} KG</td>
                        </tr>
                      )}
                      {this.state.data.length > 0 && (
                        <tr>
                          <td colSpan="5" />
                          <td>
                            <b>SUB-TOTAL</b>
                          </td>
                          <td>${Number(this.state.sub_total_money).toFixed(2)}</td>
                        </tr>
                      )}
                      {this.state.data.length > 0 && (
                        <tr>
                          <td colSpan="5" />
                          <td>
                            <b>Total ITBMS</b>
                          </td>
                          <td>${Number(this.state.tax_total_money).toFixed(2)}</td>
                        </tr>
                      )}
                      {this.state.data.length > 0 && (
                        <tr>
                          <td colSpan="5" />
                          <td className="fs-20">
                            <b>TOTAL</b>
                          </td>
                          <td className="fs-20">
                            <b>${Number(this.state.total_money).toFixed(2)}</b>
                          </td>
                        </tr>
                      )}
                      {this.state.data.length === 0 && (
                        <tr>
                          <td colSpan="7">No hubo resultados</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="main-view">
          <div className="show-title">
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    }
  }
}

import React from 'react'
import OrderDetailsTab from './tabs/OrderDetailsTab'
import OrderInvoiceDetailsTab from './tabs/OrderInvoiceDetailsTab'
import DeliveryDetailsTab from './tabs/DeliveryDetailsTab'
import InvoiceDetailsTab from '../order/InvoiceDetailsTab'
import TabWithDropdown from '../layout/TabWithDropdown'
import { DispatchConsumer, DispatchProvider } from './context/OrderContext'
import DispatchOrderTab from './tabs/DispatchOrderTab'
import CreateDispatchProvider from './context/CreateDispatchProvider'
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

export default class ShowOrderView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessfulMessage: false,
      alertState: 'alert alert-success',
      createText: 'Guardar',
      isPosting: false
    }
  }

  componentDidMount() {
    this.showSuccessfulMessageCreation()
  }

  showSuccessfulMessageCreation = () => {
    if (document.referrer.includes('nuevo_pedido')) {
      this.setState({ showSuccessfulMessage: true })
    } else {
      this.setState({ showSuccessfulMessage: false })
    }
  }

  hiddenAlert = () => {
    this.setState({
      alertState: 'alert alert-success hidden'
    })
  }

  disableButton = () => {
    this.setState({ createText: 'Guardando...', isPosting: true })
  }

  enableButton = () => {
    this.setState({ createText: 'Guardar', isPosting: false })
  }

  render() {
    return (
      <DispatchConsumer>
        {({
          transactionDetails,
          orderDetails,
          invoiceAppDetails,
          deliveryDetails,
          client90KDeliveries,
          invoiceDetails,
          client90KOrders,
          client90KInvoices,
          dispatchDetailState,
          handleTabClick,
          activeTab,
          isDispatchState,
          loading,
          componentState
        }) => (
          <div>
            {!loading && (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
        {this.state.showSuccessfulMessage && (
                  <div className="alert alert-success" role="alert">
                    <strong>Su pedido fue creado exitosamente!</strong>
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
                {componentState.state === 'create' && (
                  <div className={this.state.alertState} role="alert">
                    <button
                      type="button"
                      className="close"
                      onClick={this.hiddenAlert}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <strong>El despacho de su pedido ha sido creado exitosamente!</strong>
                  </div>
                )}
                <div className="main-show-header">
                  {!isDispatchState && dispatchDetailState && (
                    <h2>Pedido: {orderDetails?.order_db_ref}</h2>
                  )}
                  {isDispatchState && (
                    <h2>Crear Despacho de Pedido #{orderDetails.order_db_ref}</h2>
                  )}
                  {!dispatchDetailState && <h2>Actualizar Detalles de Despacho</h2>}
                </div>
                <div className="index-table">
                  <div className="show-area">
                    <div>
                      <div style={{float: 'right'}}>
                        <b className="order-status-b">
                          Estado:
                        </b>
                          {transactionDetails['status'] === 'received' && orderDetails['status'] === 'received'&&(
                            <span className="order-status received-style"> RECIBIDO</span>
                          )}
                          {orderDetails['status'] === 'sent'&&(
                            <span className="order-status sent-style"> ENVIADO</span>
                          )}
                          {transactionDetails['status'] === 'dispatched' && (
                            <span className="order-status dispatched-style"> DESPACHADO</span>
                          )}
                          {orderDetails['status'] === 'billed' && (
                            <span className="order-status billed-style"> FACTURADO</span>
                          )}
                          {orderDetails['status'] === 'cancelled' && (
                            <span className="order-status cancelled-style"> CANCELADO</span>
                          )}
                          {orderDetails['status'] === 'tobebilled' && (
                            <span className="order-status pending-bill-style"> POR FACTURAR</span>
                          )}
                          {orderDetails['status'] === 'ontheway' && (
                            <span className="order-status dispatched-style"> EN CAMINO</span>
                          )}
                          {orderDetails['status'] === 'partial' && (
                            <span className="order-status partial-style"> PARCIAL</span>
                          )}
                          {orderDetails['status'] === 'annulled' && (
                            <span className="order-status annulled-style"> ANULADO</span>
                          )}
                      </div>
                      <div>
                        <div>
                          {!isDispatchState && (
                              <ul onClick={handleTabClick} className="nav nav-tabs">
                                <TabWithDropdown
                                  name="Pedido"
                                  activeTab={activeTab}
                                  items={client90KOrders}
                                />
                                {invoiceAppDetails &&(
                                invoiceAppDetails.map((item, index) => (
                                  <TabWithDropdown
                                    name={"Factura - " + (index + 1)}
                                    activeTab={activeTab}
                                    items={client90KOrders}
                                  />
                                ))
                              )}
                              </ul>
                            )}
                          {isDispatchState && (
                            <ul onClick={handleTabClick} className="nav nav-tabs">
                              <TabWithDropdown
                                name="Pedido"
                                activeTab={activeTab}
                                items={client90KOrders}
                              />
                              <TabWithDropdown
                                name="Despacho"
                                activeTab={activeTab}
                                items={client90KOrders}
                              />
                              {invoiceAppDetails &&(
                                invoiceAppDetails.map((item, index) => (
                                  <TabWithDropdown
                                    name={"Factura - " + (index + 1)}
                                    activeTab={activeTab}
                                    items={client90KOrders}
                                  />
                                ))
                              )}
                            </ul>
                          )}
                          {transactionDetails['status'] === 'dispatched' && (
                            <ul onClick={handleTabClick} className="nav nav-tabs">
                              <TabWithDropdown
                                name="Pedido"
                                activeTab={activeTab}
                                items={client90KOrders}
                              />
                              <TabWithDropdown
                                name="Despacho"
                                activeTab={activeTab}
                                items={client90KDeliveries}
                              />
                              {invoiceAppDetails &&(
                                invoiceAppDetails.map((item, index) => (
                                  <TabWithDropdown
                                    name={"Factura - " + (index + 1)}
                                    activeTab={activeTab}
                                    items={client90KOrders}
                                  />
                                ))
                              )}
                            </ul>
                          )}

                          {transactionDetails['status'] === 'invoiced' &&
                            client90KInvoices.length > 0 && (
                              <ul onClick={handleTabClick} className="nav nav-tabs">
                                <TabWithDropdown
                                  name="Pedido"
                                  activeTab={activeTab}
                                  items={client90KOrders}
                                />
                                <TabWithDropdown
                                  name="Despacho"
                                  activeTab={activeTab}
                                  items={client90KDeliveries}
                                />
                                <TabWithDropdown
                                  name="Factura"
                                  activeTab={activeTab}
                                  items={client90KInvoices}
                                />
                              </ul>
                            )}
                        </div>

                        <div>
                          {activeTab === 'Pedido' && orderDetails && (
                            <OrderDetailsTab
                              orderDetails={orderDetails}
                              transactionUuid={this.props.details.uuid}
                            />
                          )}

                          {orderDetails && invoiceAppDetails && (
                            invoiceAppDetails.map((item, index) => (
                              activeTab === 'Factura - ' + (index + 1) && (
                                <OrderInvoiceDetailsTab
                                  orderDetails={orderDetails}
                                  invoiceAppDetails={invoiceAppDetails[index]}
                                  transactionUuid={this.props.details.uuid}
                                />
                              )
                            ))
                          )}

                          {activeTab === 'Despacho' && (
                            <span>
                              {isDispatchState ? (
                                <CreateDispatchProvider
                                  transactionUuid={this.props.position}
                                  disableButton={this.disableButton}
                                  orderDetails={orderDetails}
                                >
                                  <DispatchOrderTab
                                    isPosting={this.state.isPosting}
                                    createText={this.state.createText}
                                  />
                                </CreateDispatchProvider>
                              ) : (
                                <DeliveryDetailsTab
                                  position={this.props.position}
                                  deliveryDetails={deliveryDetails}
                                  orderDetails={orderDetails}
                                  transactionUuid={this.props.details.uuid}
                                />
                              )}
                            </span>
                          )}

                          {activeTab === 'Factura' && invoiceDetails && (
                            <InvoiceDetailsTab invoiceDetails={invoiceDetails} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </DispatchConsumer>
    )
  }
}

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './css/index.css'
import './css/styles.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import * as serviceWorker from './serviceWorker'
import FlagProvider from './global_context/FlagProvider'

ReactDOM.render(
  <FlagProvider>
    <App />
  </FlagProvider>,
  document.getElementById('app')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

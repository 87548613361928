import React from 'react'

const TextInput = (props) => {
  let formControl = props.inputstyle

  if (props.touched === 'true' && props.valid === 'false') {
    formControl = formControl + ' valid-control-error'
  }
  return (
    <div>
      <div className="form-group">
        <input
          type={props.type ? props.type : 'text'}
          maxLength={props.maxLength}
          className={formControl}
          required={props.required}
          {...props}
        />
      </div>
    </div>
  )
}

export default TextInput

import React, { createContext, useReducer } from 'react'
import { TYPES } from './state/Types'
import uuid from 'uuid/v4'

const initialState = {}

const PriceListCreateContext = createContext(initialState)

const PriceListCreateProvider = (props) => {
  const initialTasksState = {
    priceList: [{ price_list_id: '', price_list_name: '', price: 0, id: uuid() }]
  }

  const priceListReducer = (state, action) => {

    switch (action.type) {
      case TYPES.ADD_PRICE_LIST:
        return {
          ...state,
          priceList: [...state.priceList, action.newPriceList]
        }
      case TYPES.DELETE_PRICE_LIST:
        return {
          ...state,
          priceList: state.priceList.filter((t) => t.id !== action.id)
        }
      case TYPES.CHANGE_PRICE_LIST_NAME:
        return {
          ...state,
          priceList: state.priceList.map((item) =>
            item.id === action.pr.id
              ? {
                  ...item,
                  price_list_id: action.selectPriceList.id,
                  price_list_name: action.selectPriceList.description
                }
              : item
          )
        }

      case TYPES.CHANGE_PRICE:
        return {
          ...state,
          priceList: state.priceList.map((item) =>
            item.id === action.pr.id
              ? {
                  ...item,
                  price: action.quantityValue
                }
              : item
          )
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(priceListReducer, initialTasksState)

  return (
    <PriceListCreateContext.Provider
      value={{
        state,
        dispatch,
        priceListState: state.priceList
      }}
    >
      {props.children}
    </PriceListCreateContext.Provider>
  )
}
export { PriceListCreateContext, PriceListCreateProvider }

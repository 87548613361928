import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretUp,
  faCaretDown,
  faArrowCircleDown,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'
import cookie from 'react-cookies'
import { CSVLink } from 'react-csv'
import { Table } from 'react-bootstrap'
import moment from 'moment'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const VisitEffectivityIndexView = (props) => {

  const [headers] = useState([ 'Código', 'Vendedor', 'Grupo', 'Dia de primera visita', 'Hora de primera visita', 'Dia de ultima visita',
        'Hora de ultima visita', 'Meta de visitas', 'Clientes visitados', 'Visita/Meta', 'Clientes con pedidos',
        'Venta/Visita', 'Devolución Bruta', 'Venta Bruta']);

  const getStartDate = () => {
    if (window.location.href.indexOf('start_date') >= 0) {
      const startDay = window.location.href.split('start_date=')
      return startDay[startDay.length - 1].split('&')[0]
    } else {
      return getCurrentDate()
    }
  }

  const getEndDate = () => {
    if (window.location.href.indexOf('end_date') >= 0) {
      return window.location.href.split('end_date=')[
        window.location.href.split('end_date=').length - 1
      ]
    } else {
      return getCurrentDate()
    }
  }

  const getCurrentDate = () => new Date().getFullYear() + '-' + getMonth() + '-' + getDay()

  const getMonth = () => {
    const m = new Date().getMonth() + 1
    if (m < 10) {
      return '0' + m
    } else {
      return m
    }
  }

  const getDay = () => {
    const d = new Date().getDate()
    if (d < 10) {
      return '0' + d
    } else {
      return d
    }
  }

  const searchInput = React.createRef()
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [isActiveFilter, setFilterToggle] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [typeFilter, setTypeFilter] = useState({})
  const [startDate, setStartDate] = useState(getStartDate())
  const [endDate, setEndDate] = useState(getEndDate())
  const [invalidRangeLimit, setInvalidRangeLimit] = useState(false)
  const [invalidRangeFormat, setInvalidRangeFormat] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    async function fetchData() {
      await fetchDataFromServer()
    }

    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      await fetchFilterDataFromServer()
    }

    fetchData()
  }, [])

  useEffect(() => {
    let type_values = []
    let results = {}

    if (typeFilter) {
      type_values = Object.entries(typeFilter)
        .filter((value) => value[1] === true)
        .map((k, v) => k[0])
    }
    results = data.filter((item) => {
      if (type_values.length > 0) {
        const typeName = item.type_of_salesman || ''
        return type_values.map((k) => typeName === k).some((k) => k === true)
      } else {
        return data
      }
    })
    setSearchResults(results)
  }, [data, typeFilter])

  const fetchDataFromServer = () => {
    fetch(
      BACKEND_URL +
        '/businesses/1/reports/visit_effectiveness?start_date=' +
        startDate +
        '&end_date=' +
        endDate +
        '&q=' +
        query +
        '&business_tenant_uid=' +
        cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setData(data['result'])
        setSearchResults(data['result'])
        setIsFetching(false)
      })
      .catch((e) => console.log(e))
  }

  const fetchFilterDataFromServer = () => {
    fetch(
      BACKEND_URL +
        '/businesses/1/reports/filters_by_branches?business_tenant_uid=' +
        cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setFilterData(data['result'])
      })
      .catch((e) => console.log(e))
  }

  const showModel = (event, salesman_uuid) => {
    event.preventDefault()
    window.location =
      '/visitas_por_vendedor?salesman_uuid=' +
      salesman_uuid +
      '&start_date=' +
      startDate +
      '&end_date=' +
      endDate
  }

  const searchFromDates = (event) => {
    const start = document.getElementsByName('start_date')[0].value
    const end = document.getElementsByName('end_date')[0].value
    var start_date = new Date(start)
    var end_date = new Date(end)
    var diff_in_time = end_date.getTime() - start_date.getTime()
    // To calculate the no. of days between two dates
    var diff_in_days = diff_in_time / (1000 * 3600 * 24)
    // Validations
    if (start_date > end_date) {
      setInvalidRangeFormat(true)
      setInvalidRangeLimit(false)
    } else if (diff_in_days > 90) {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(true)
    } else {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      window.location.href = 'efectividad_de_visitas?start_date=' + start + '&end_date=' + end
    }
  }

  const handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      filterResults(event.target.value)
    }
  }

  const filterResults = (q) => {
    const results = data.filter((visit) => {
      const poetName = visit.salesman_db_ref.toLowerCase() + visit.salesman_name.toLowerCase()
      return poetName.indexOf(q.toLowerCase()) !== -1
    })
    setQuery(q)
    setSearchResults(results)
  }

  const deleteSearch = () => {
    filterResults('')
    searchInput.current.value = ''
  }

  const getTimestampFromDateTime = (date) => {
    const d = new Date(date)
    return d.toLocaleString()
  }

  const toggleFilters = () => {
    setFilterToggle(!isActiveFilter)
  }

  const getSelectedFilter = (e) => {
    const { name, checked } = e.target
    setTypeFilter({ ...typeFilter, [name]: checked })
  }

  const DisplayFilters = () => (
    <div className="row" style={{ marginLeft: '10px' }}>
      {filterData['type'].length > 0 && (
        <div
          style={{
            float: 'left',
            padding: '10px',
            width: '55%',
            color: '#27588D',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        >
          Tipos
          <div style={{ columnCount: 3 }}>
            {filterData['type'].map((model, index) => (
              <p
                key={index}
                style={{
                  color: '#5F5F5F',
                  fontSize: '12px',
                  fontWeight: 'normal'
                }}
              >
                <input
                  type="checkbox"
                  name={model['type_of_salesman']}
                  checked={typeFilter[model['type_of_salesman']]}
                  onChange={getSelectedFilter}
                />{' '}
                {model['type_of_salesman']}{' '}
              </p>
            ))}
          </div>
        </div>
      )}
      {filterData.length === 0 && <p style={{ marginTop: '9px' }}>No existen filtros.</p>}
    </div>
  )

  const buildData = () => {
    const array = searchResults.map((reportResult) => {
      const mapResult = {
        salesman_uuid: reportResult.salesman_db_ref,
        salesman_name: reportResult.salesman_name,
        type_of_salesman: reportResult.type_of_salesman,
        first_visit_date: reportResult.first_visit
          ? moment(reportResult.first_visit).format('YYYY-MM-DD')
          : '-',
        first_visit_hr: reportResult.first_visit
          ? moment(reportResult.first_visit).format('h:mm:ss a')
          : '-',
        last_visit_date: reportResult.last_visit
          ? moment(reportResult.last_visit).format('YYYY-MM-DD')
          : '-',
        last_visit_hr: reportResult.last_visit
          ? moment(reportResult.last_visit).format('h:mm:ss a')
          : '-',
        total_visit_client: reportResult.total_visit_client,
        no_visited_clients: reportResult.no_visited_clients,
        visit_effectiveness: reportResult.visit_effectiveness,
        no_clients_with_orders: reportResult.no_clients_with_orders,
        sales_effectiveness: reportResult.sales_effectiveness,
        refunds_subtotal: reportResult.refunds_subtotal,
        subtotal: reportResult.subtotal
      }
      return Object.values(mapResult)
    })

    array.splice(0, 0, headers)
    array.splice(0, 0, [])
    array.splice(0, 0, ['Fecha', startDate + ' - ' + endDate])
    array.splice(0, 0, ['Supervisor', cookie.load('user_name')])
    array.splice(0, 0, ['Reporte:', 'Efectividad de Visitas'])
    return array
  }

  if (isFetching) {
    return (
      <div className="main-view">
        <div className="index-header">
          <br />
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="main-view">
        {invalidRangeLimit && (
          <div className="alert alert-danger" role="alert">
            <strong>
              ERROR: Rango de fechas escogidas pasan de los 90 días. Por favor escoger otra fecha.
            </strong>
          </div>
        )}
        {invalidRangeFormat && (
          <div className="alert alert-danger" role="alert">
            <strong>ERROR: Fecha de inicio debe ser antes que la fecha de fin.</strong>
          </div>
        )}
        <div className="index-header">
          <h2 className="mb-20 float-unset">{props.title}</h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div className="range-dates">
              <span style={{ color: '#4D4845', fontSize: '18px', marginRight: '15px' }}>Desde</span>
              <input
                max={getCurrentDate()}
                defaultValue={startDate}
                className="select-date-report"
                type="date"
                name="start_date"
                required
              />
              <span style={{ color: '#4D4845', fontSize: '18px', marginRight: '15px' }}>
                {' '}
                - Hasta
              </span>
              <input
                max={getCurrentDate()}
                defaultValue={endDate}
                className="select-date-report"
                type="date"
                name="end_date"
                required
              />
              <button onClick={searchFromDates} className="search-range-dates-btn">
                Buscar
              </button>
              <CSVLink
                className="btn btn-default download-report-button"
                data={buildData()}
                filename={
                  'ReporteEfectividadVisita_' +
                  cookie.load('business_name') +
                  '_' +
                  getCurrentDate() +
                  '.csv'
                }
              >
                Descargar reporte
                <FontAwesomeIcon icon={faArrowCircleDown} />
              </CSVLink>
            </div>
            <h5>
              <FontAwesomeIcon icon={faExclamationCircle} />
              Nota: Solo puedes escoger un rango de fechas de un maximo de 90 dias.
            </h5>
            <div className="">
              <span
                style={{
                  color: '#27588D',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginRight: '7px',
                  marginLeft: '10px'
                }}
              >
                Filtros
                <FontAwesomeIcon
                  icon={isActiveFilter ? faCaretDown : faCaretUp}
                  onClick={toggleFilters}
                />
              </span>
            </div>
            {isActiveFilter && <DisplayFilters />}
            <hr />
            <div className="show-header">
              <div className="col-xs-6">
                <h2>Detalles de reporte de visitas diarias:</h2>
              </div>
              <div className="col-xs-6">
                <div className="input-group show-buttons">
                  <input
                    ref={searchInput}
                    autoComplete="off"
                    onKeyDown={handleSearch}
                    className="search-price-list-products"
                    placeholder="Buscar vendedor por código o nombre"
                  />
                  {query === '' && (
                    <span className="glyphicon glyphicon-search form-control-feedback" />
                  )}
                  {query !== '' && (
                    <span className="delete-search-icon" onClick={deleteSearch.bind(this)}>
                      &times;
                    </span>
                  )}
                </div>
              </div>
            </div>
            <hr className="mt-90" />
            <div>
              <Table responsive striped hover size="sm">
                <thead>
                  <tr>
                    <th>CODIGO</th>
                    <th>VENDEDOR</th>
                    <th>GRUPO</th>
                    <th>HORA DE ULTIMA VISITA</th>
                    <th>META DE VISITAS</th>
                    <th>CLIENTES VISITADOS</th>
                    <th>VISITA/META</th>
                    <th>CLIENTES CON PEDIDO</th>
                    <th>VENTA/VISITAS</th>
                    <th>DEVOLUCIÓN BRUTA</th>
                    <th>VENTA BRUTA</th>
                  </tr>
                  </thead>
                  <tbody>
                  { searchResults.length > 0 &&
                  searchResults.map((model, index) =>
                    <tr key={ index }>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>{ model['salesman_db_ref'] }</td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>{ model['salesman_name'] }</td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>{ model['type_of_salesman'] }</td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>{ (model['last_visit'] === null) ? '-' : (getTimestampFromDateTime(model['last_visit'])) }</td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>{ model['total_visit_client'] }</td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>{ model['no_visited_clients'] }</td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>
                        { (model['visit_effectiveness'].toFixed(2)) > 100.00 ? 100.00.toFixed(2) : (model['visit_effectiveness'].toFixed(2))} %
                      </td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>{ model['no_clients_with_orders'] }</td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>
                        { (model['sales_effectiveness'].toFixed(2)) } %
                      </td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>{ parseFloat(model['refunds_subtotal']).toFixed(2) == 0 ? '$' + parseFloat(model['refunds_total']).toFixed(2) :'$' + parseFloat(model['refunds_subtotal']).toFixed(2) }</td>
                      <td onClick={ (e) => {
                        showModel(e, model['salesman_uuid'])
                      } }>{ parseFloat(model['subtotal']).toFixed(2) == 0 ? '$' + parseFloat(model['total']).toFixed(2) :'$' + parseFloat(model['subtotal']).toFixed(2) }</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VisitEffectivityIndexView

export const handleUpdate = (event) => {
  if (
    event.target.value === 'Actualizar detalles' ||
    event.target.value === 'Actualizar configuraciones'
  ) {
    return true
  } else if (event.target.value === 'Cancelar') {
    return false
  }
}
export const handleTabClick = (event, isEditing) => {
  // if tab was clicked (tab is an <a> element)
  if (event.target.tagName === 'A') {
    if (isEditing === false) {
      return {
        activeTab: event.target.innerText,
        editing: false
      }
    } else {
      const val = window.confirm(
        'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
      )
      if (val === true) {
        return {
          activeTab: event.target.innerText,
          editing: false,
          confirm: val
        }
      }
    }
  }
}

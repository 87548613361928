import React from 'react'
import Lightbox from 'react-spring-lightbox'

export default function ImageModal({ imgSrc, showImage, setShowImage }) {
  const handleCloseImage = (e) => {
    setShowImage(false)
  }

  return (
    <div>
      <Lightbox
        isOpen={showImage}
        images={[{ src: imgSrc, alt: 'Imagen agregada', style: { maxWidth: '90%' } }]}
        currentIndex={0}
        onClose={handleCloseImage}
        className="lightbox-background"
        renderHeader={() => (
          <>
            <button onClick={handleCloseImage} className="lightbox-close-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: 40, height: 40, color: 'white' }}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            <div className="img-overlay-tooltip">
              Haga doble clic en la imagen para aumentar/disminuir el zoom
            </div>
          </>
        )}
      />
    </div>
  )
}

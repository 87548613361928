import React from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import CreatePromo from './pages/CreatePromo'
import DiscountPromoContextProvider from './context/DiscountPromoContext'
import BonusPromoContextProvider, { BonusPromoContext } from './context/BonusPromoContext'
import BasePromoContextProvider, { BasePromoContext } from './context/BasePromoContext'
import ScalePromoContextProvider from './context/ScalePromoContext'
import AssortedPromoContextProvider from './context/AssortedPromoContext'
import AssortedBonusPromoContextProvider from './context/AssortedBonusPromoContext'
import AssortedScalePromoContextProvider from './context/AssortedScalePromoContext'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class CreatePromoApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchPlaceholder: 'Buscar promociones por código, descripción, o productos base',
      collapsed: false, 
    }
  }

  componentDidMount() {
    this.isUserLoggedIn()
    const collapsed = localStorage.getItem('collapsedState') === 'true';
    this.setState({ collapsed });
  }

  isUserLoggedIn = () => {
    fetch(BACKEND_URL + '/users/current', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (Object.keys(data).length === 0) {
          window.location = '/'
        }
      })
      .catch((e) => console.log(e))
  }

  handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault()
      const query = event.target.value
      const val = window.confirm(
        '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
      )
      if (val) {
        if (query !== '') {
          // redirecting to index accroding to clicked
          window.location = '/promociones?query=' + query
        }
      }
      event.target.value = ''
    }
  }

  handleToggleCollapse = (collapsed) => {
    this.setState({ collapsed }, () => {
      localStorage.setItem('collapsedState', collapsed);
    });
  };
  
  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar searchPlaceholder={this.state.searchPlaceholder}
                      onToggleCollapse={this.handleToggleCollapse} 
                      collapsed={this.state.collapsed}  />
          </div>
          <div>
            <BasePromoContextProvider>
              <BonusPromoContextProvider>
                <ScalePromoContextProvider>
                  <DiscountPromoContextProvider>
                    <AssortedPromoContextProvider>
                      <AssortedBonusPromoContextProvider>
                        <AssortedScalePromoContextProvider>
                        <CreatePromo collapsed={this.state.collapsed} />
                        </AssortedScalePromoContextProvider>
                      </AssortedBonusPromoContextProvider>
                    </AssortedPromoContextProvider>
                  </DiscountPromoContextProvider>
                </ScalePromoContextProvider>
              </BonusPromoContextProvider>
            </BasePromoContextProvider>
          </div>
        </div>
        <div>
          <NavBar activeItem="Promociones" isEditing={true} collapsed={this.state.collapsed} />
        </div>
      </div>
    )
  }
}

import React from 'react'
import Pagination from '../layout/Pagination'
import SearchInput from '../layout/SearchInput'

export default class PriceListIndexView extends React.Component {
  showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open('/listas_de_precios/' + id)
  }

  render() {
    const data = this.props.data

    if (this.props.isFetching) {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header">
            <br />
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header detailed-container">
            <h2 className="mb-20">{this.props.title}</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div className="title-and-search-containter">
                <h4 className="">Listas de Precios:</h4>

                <div className="search-input-containter mb-20">
                  <SearchInput
                    query={this.props.query}
                    onSearch={this.props.onSearchUpdate}
                    placeholder="Buscar listas de precios por código o nombre"
                  />
                </div>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ verticalAlign: 'middle', width: '40%' }}>
                      <span className="th-header-text">CÓDIGO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle', width: '50%', textAlign: 'left' }}>
                      <span className="th-header-text">NOMBRE DE LISTA DE PRECIO</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 &&
                    data.map((model, index) => (
                      <tr className="cursor-pointer" key={index}>
                        <td
                          className="td-text-limited "
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                          id={model['id']}
                          onClick={this.showModel}
                        >
                          {model['db_ref']}
                        </td>
                        <td
                          className="td-text-limited"
                          id={model['id']}
                          onClick={this.showModel}
                          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          {model['name']}
                        </td>
                      </tr>
                    ))}
                  {data.length === 0 && (
                    <tr>
                      <td>No hay resultados</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <nav className="index-table">
            {this.props.total_pages > 1 && (
              <Pagination
                current_page={this.props.current_page}
                total_pages={this.props.total_pages}
              />
            )}
          </nav>
        </div>
      )
    }
  }
}

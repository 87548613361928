import React from 'react'
import { ClientEcommerceConsumer } from './context/ClientEcommerceContext'
import CreateEcommerceForm from './components/CreateEcommerceForm'
import IndexEcommerceView from './components/IndexEcommerceView'
import _ from 'lodash'
import { validateAccessRole } from '../helpers/userRole'
import cookie from 'react-cookies'

const IndexEcommerceApp = () => {
  const filterActiveUser = (users) =>
    _.filter(users, function (o) {
      return o.active
    })

  return (
    <ClientEcommerceConsumer>
      {({
        formSubmitHandler,
        handleEditCancelForm,
        handleCreateNewEcommerceClient,
        isFormCreateEditing,
        eCommerceUserData
      }) => (
        <div>
          <div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 mt-16">
                  <h4>Detalles de E-commerce del cliente</h4>
                </div>
                <div className="col-lg-6">
                  {!isFormCreateEditing && _.isEmpty(filterActiveUser(eCommerceUserData)) && (
                    <div className="ubiqua-button-header ubiqua-show-button">
                      {validateAccessRole(cookie.load('user_role')?.clients_module)?.can_write && (
                      <input
                        type="submit"
                        onClick={(e) => handleCreateNewEcommerceClient(e)}
                        value="Crear usuario"
                      />
                      )}
                    </div>
                  )}
                  {isFormCreateEditing && (
                    <div className="ubiqua-button-header ubiqua-show-button row">
                      <button onClick={(e) => handleEditCancelForm(e)} className="cancel-button">
                        Cancelar
                      </button>
                      <input type="submit" onClick={(e) => formSubmitHandler(e)} value="Guardar" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <hr />
              <div className="">
                {isFormCreateEditing && <CreateEcommerceForm />}
                <IndexEcommerceView />
              </div>
            </div>
          </div>
        </div>
      )}
    </ClientEcommerceConsumer>
  )
}

export default IndexEcommerceApp

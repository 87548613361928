import cookie from 'react-cookies'
import * as apiStatusCodes from '../../constants/apiStatusCodes'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + cookie.load('token')
}

const routes = {
  delivery_user: {
    list: '/businesses/1/deliveries_users',
    individual: '/deliveries_users',
    delivererClients: '/deliverer_client' // Note, NOT a standalone route.
  }
}
const sendApiRequest = (method, url, params) =>
  fetch(url, {
    method: method,
    headers: API_HEADERS,
    body: JSON.stringify(params)
  })

export const handleErrors = (response) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    if (response.status !== apiStatusCodes.OK && response.status !== apiStatusCodes.CREATED) {
      reject(response)
    } else {
      resolve(response)
    }
  })

export const getDelivererClientList = (salesmanId, query = '', page = 1, per_page = 25) => {
  const { individual, delivererClients } = routes.delivery_user
  const url = `${BACKEND_URL}${individual}/${salesmanId}${delivererClients}?q=${query}&page=${page}&per_page=${per_page}`
  return sendApiRequest('GET', url)
}

export const getUnassignedDelivererClientList = (
  delivererId,
  query = '',
  page = 1,
  per_page = 25
) => {
  const { list, delivererClients } = routes.delivery_user
  const url = `${BACKEND_URL}${list}/${delivererId}${delivererClients}/missing_clients/?q=${query}&page=${page}&per_page=${per_page}`
  return sendApiRequest('GET', url)
}

export const addClientToDelivererRoute = (params) => {
  const { delivererClients } = routes.delivery_user
  const url = `${BACKEND_URL}/${delivererClients}`
  return sendApiRequest('POST', url, params)
}

export const unassignClientFromDeliverer = (clientId, params) => {
  const { delivererClients } = routes.delivery_user
  const url = `${BACKEND_URL}/${delivererClients}/${clientId}`
  return sendApiRequest('PUT', url, params)
}
